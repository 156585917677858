import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { EUserType, IUser } from './shared/models';
import { Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { EErrorType, EExceptionTypes, IAppError } from 'shared/models';
import { DisplayErrorMessage } from 'app/store/actions';
import { VerifyAgent } from 'app/store/actions/auth.actions';
import { userSelectors } from 'app/store/selectors';
import { IAuthStates } from 'app/store/states/auth.states';

@Injectable({
  providedIn: 'root'
})
export class AppService implements OnInit, OnDestroy {
  destroyed$ = new Subject();
  loadingUserInfo: boolean;
  userInfo: IUser;
  applicantEmail: string = null;

  constructor(private store: Store<IAuthStates>) {}

  ngOnInit(): void {
    this.setLoadingText();
  }

  setLoadingText = (userType?: EUserType): string => {
    let loadingText: string;
    if (userType && userType === 'agent') {
      loadingText = 'Loading Application Information... Please Wait';
    } else if (userType && userType === 'applicant') {
      loadingText = 'Loading Agent Information... Please Wait';
    } else {
      loadingText = 'Loading User Information... Please Wait';
    }
    return loadingText;
  };

  selectUserInfo = () => {
    combineLatest(
      this.store.select(userSelectors.selectUserInfo),
      this.store.select(userSelectors.selectLoading)
    )
      .pipe(
        map(([userInfo, loadingUserInfo]) => {
          this.loadingUserInfo = loadingUserInfo;
          this.userInfo = userInfo;
          if (userInfo) {
            if (!userInfo.agentId) {
              // If the user is an applicant, use their login email as their email address
              this.applicantEmail = userInfo.emails[0];
            }
          }
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe();
  };

  watchUserError = () => {
    this.store
      .select(userSelectors.selectError)
      .pipe(
        tap(error => {
          if (error) {
            if (error.type === EErrorType.LOAD) {
              this.store.dispatch(
                new DisplayErrorMessage({
                  error: error,
                  callback: () => this.store.dispatch(new VerifyAgent()),
                  messageFactory: this.errorMessageFactory
                })
              );
            } else {
              this.store.dispatch(new DisplayErrorMessage({ error: error }));
            }
          }
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe();
  };

  errorMessageFactory = (error: IAppError) => {
    if (error) {
      switch (error.id) {
        case EExceptionTypes.EXPIRED_AGENT:
          return 'Looks like you are no longer a Seneca Agent, please sign out and try another account';
        case EExceptionTypes.INVALID_AGENT_LOGIN:
          return 'Invalid Agent login attempt, please sign in with your Agent account instead.';
        case EExceptionTypes.UNABLE_TO_VERIFY_AGENT:
          return 'Something went wrong and we cannot verify your identity, please try again.';
        default:
          return 'Something went wrong... Please try again';
      }
    }
  };

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }
}
