import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';

import { Action, Store } from '@ngrx/store';
import { ProgramListService } from 'shared/services/api/program-list.service';
import {
  EProgramListActions,
  LoadPrograms,
  LoadProgramsSuccess
} from '../actions';
import {
  AppLoadError,
  IProgram,
  IProgramListItem,
  IProgramListItemState,
  IProgramListReqParams
} from 'shared/models';
import { HttpErrorResponse } from '@angular/common/http';
import { IProgramListContainerStates } from '../states';
import { programListSelectors } from '../selectors';
import { campusNameConverter, campusUrlConverter } from 'app/utils';

@Injectable()
export class ProgramListEffects {
  constructor(
    private actions$: Actions,
    private service: ProgramListService,
    private store: Store<IProgramListContainerStates>
  ) {}

  @Effect()
  getProgramList$: Observable<Action> = this.actions$.pipe(
    ofType(EProgramListActions.LOAD_PROGRAMS),
    withLatestFrom(
      this.store.select(programListSelectors.selectSelectedProgramList)
    ),
    switchMap(([action, stored]: [LoadPrograms, IProgramListItemState]) => {
      const { year, month, id, visa, bundle } = action.payload;
      // disable checking of existing data in store and everytime make the API call
      // might flip this back, if we can force to fetch for bundle programs only
      // if (
      //   stored &&
      //   stored.list.length !== 0 &&
      //   stored.id === id &&
      //   stored.month === month &&
      //   stored.year === year &&
      //   stored.visa === visa
      // ) {
      //   return observableOf(new LoadProgramsSuccess(stored));
      // } else
      {
        const reqParams: IProgramListReqParams = {
          year,
          month,
          visa,
          bundle
        };
        return this.service.getProgramLists(reqParams).pipe(
          map((programs: IProgram[]) => {
            programs.forEach(program => {
              if(program.eligibility) {program.eligibility = program.eligibility.filter(element => element);}
              program.campus = campusNameConverter(program.campus);
              program.campusUrl = campusUrlConverter(program.campus);
            });
            const newProgramListItem: IProgramListItem = {
              id: id,
              list: programs,
              visa,
              year,
              month
            };
            return new LoadProgramsSuccess(newProgramListItem);
          }),
          catchError((err: HttpErrorResponse) => {
            const error = err instanceof HttpErrorResponse ? err.error : err;
            const displayError = error
              ? new AppLoadError(error.id, error.message)
              : new AppLoadError();

            return observableOf(displayError);
          })
        );
      }
    })
  );
}
