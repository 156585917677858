import { Action } from '@ngrx/store';
import { IAppError, IPreloadData, IPreloadDataResponse } from 'app/shared/models';
import {
  IPayment,
  IPaymentResponse,
  IPromoCodeSubmit,
  IPromoCodeSubmitResult
} from 'shared/models';

export enum EPaymentActions {
  GET_APPLICATION_FEE = '[Payment] Get Application Fee',
  GET_APPLICATION_FEE_SUCCESS = '[Payment] Get Application Fee Success',
  GET_APPLICATION_FEE_FAIL = '[Payment] Get Application Fee Fail',

  UPDATE_PAYMENT_RESPONSE = '[Payment] Update Payment Response',

  CHECK_PROMO_CODE = '[Payment] Check Promo Code',
  CHECK_PROMO_CODE_SUCCESS = '[Payment] Check Promo Code Success',
  CHECK_PROMO_CODE_FAIL = '[Payment] Check Promo Code Fail',
  INCORRECT_PROMO_CODE = '[Payment] Incorrect Promo Code',

  INITIALIZE_PAYMENT = '[Payment] Initialize Payment Response',
  INITIALIZE_PAYMENT_SUCCESS = '[Payment] Initialize Payment Response Success',
  GET_PAYMENT_RECEIPT = '[Payment] Get Payment Receipt',

  PAYMENT_SUCCESS = '[Payment] Payment Success'
}

export class GetApplicationFee implements Action {
  public readonly type = EPaymentActions.GET_APPLICATION_FEE;
  constructor(public payload: string) {}
}

export class GetApplicationFeeSuccess implements Action {
  public readonly type = EPaymentActions.GET_APPLICATION_FEE_SUCCESS;
  constructor(public payload: IPayment) {}
}

export class GetApplicationFeeFail implements Action {
  public readonly type = EPaymentActions.GET_APPLICATION_FEE_FAIL;
  constructor(public payload: IAppError) {}
}

export class UpdatePaymentResponse implements Action {
  public readonly type = EPaymentActions.UPDATE_PAYMENT_RESPONSE;
  constructor(public payload: IPaymentResponse) {}
}

export class CheckPromoCode implements Action {
  public readonly type = EPaymentActions.CHECK_PROMO_CODE;
  constructor(public payload: IPromoCodeSubmit) {}
}

export class CheckPromoCodeSuccess implements Action {
  public readonly type = EPaymentActions.CHECK_PROMO_CODE_SUCCESS;
  constructor(public payload: IPromoCodeSubmitResult) {}
}

export class CheckPromoCodeFail implements Action {
  public readonly type = EPaymentActions.CHECK_PROMO_CODE_FAIL;
  constructor(public payload: IAppError) {}
}

export class IncorrectPromoCode implements Action {
  public readonly type = EPaymentActions.INCORRECT_PROMO_CODE;
  constructor(public payload: IAppError) {}
}

export class InitializePayment implements Action {
  public readonly type = EPaymentActions.INITIALIZE_PAYMENT;
  constructor(public payload: IPreloadData) {}
}

export class InitializePaymentSuccess implements Action {
  public readonly type = EPaymentActions.INITIALIZE_PAYMENT_SUCCESS;
  constructor(public payload: IPreloadDataResponse) {}
}

export class GetPaymentReceipt implements Action {
  public readonly type = EPaymentActions.GET_PAYMENT_RECEIPT;
  constructor(public payload: string) {}
}

export class PaymentSuccess implements Action {
  public readonly type = EPaymentActions.PAYMENT_SUCCESS;
}

export type PaymentActions =
  | GetApplicationFee
  | GetApplicationFeeSuccess
  | GetApplicationFeeFail
  | UpdatePaymentResponse
  | CheckPromoCode
  | CheckPromoCodeSuccess
  | CheckPromoCodeFail
  | IncorrectPromoCode
  | InitializePayment
  | InitializePaymentSuccess
  | GetPaymentReceipt
  | PaymentSuccess;
