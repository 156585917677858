import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { IBaseEntityState, ILanguage } from 'app/shared/models';

export interface LanguageListStates extends IBaseEntityState<ILanguage> {}

export const languageListAdapter: EntityAdapter<
  ILanguage
> = createEntityAdapter<ILanguage>({
  selectId: (languageList: ILanguage) => languageList.code
});

export const languageListInitialState: LanguageListStates = languageListAdapter.getInitialState(
  {
    selectedInfoId: null,
    loading: false,
    error: null
  }
);
