import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AppLoadError } from 'app/shared/models';
import {
  ECountryListActions,
  LoadCountryListFail,
  LoadCountryListSuccess
} from 'app/store/actions';
import { Observable, of as observableOf, of, Subject } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  withLatestFrom,
  takeUntil
} from 'rxjs/operators';
import { countryListSelectors } from '../selectors';
import { CountryListStates } from '../states';
import { CountryListService } from 'shared/services/api/country-list.service';

@Injectable()
export class CountryListEffects implements OnDestroy {
  constructor(
    private actions$: Actions,
    private service: CountryListService,
    private store: Store<CountryListStates>
  ) {}

  destroyed$ = new Subject();

  @Effect()
  getCountryList$: Observable<Action> = this.actions$.pipe(
    ofType(ECountryListActions.LOAD_COUNTRY_LIST),
    withLatestFrom(this.store.select(countryListSelectors.selectAll)),
    switchMap(([action, stored]) => {
      return stored.length !== 0
        ? of(new LoadCountryListSuccess(stored))
        : this.service.getCountryList().pipe(
            map(CountryList => {
              return new LoadCountryListSuccess(CountryList);
            }),
            catchError(err => {
              const error = err instanceof HttpErrorResponse ? err.error : err;
              const displayError = error
                ? new AppLoadError(error.id, error.message)
                : new AppLoadError();

              return observableOf(new LoadCountryListFail(displayError));
            })
          );
    }),
    takeUntil(this.destroyed$)
  );

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }
}
