import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  switchMap,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of as observableOf, Subject } from 'rxjs';

import {
  EAcademicInfoActions,
  LoadAcademicInfoFail,
  LoadAcademicInfoSuccess,
  SubmitAcademicInfo,
  SubmitAcademicInfoFail,
  SubmitAcademicInfoSuccess
} from '../actions';
import { Action, Store } from '@ngrx/store';
import {
  AcademicInfo,
  AppLoadError,
  AppSubmitError,
  ISchoolInfo
} from 'shared/models';
import { HttpErrorResponse } from '@angular/common/http';
import { AppState } from '../reducers';
import { academicSelectors, applicantInfoSelectors } from '../selectors';
import { isAnyPropEmpty } from 'app/utils';
import { AcademicInfoService } from 'shared/services/api/academic-info.service';

@Injectable()
export class AcademicInfoEffects implements OnDestroy {
  constructor(
    private actions$: Actions,
    private service: AcademicInfoService,
    private store: Store<AppState>
  ) {}

  destroyed$ = new Subject();

  @Effect()
  getAcademicInfo$: Observable<Action> = this.actions$.pipe(
    ofType(EAcademicInfoActions.LOAD_ACADEMIC_INFO),
    withLatestFrom(
      this.store.select(academicSelectors.selectSelectedAcademicInfo)
    ),
    switchMap(([action, stored]) => {
      // get the data from the store, if the data is already there,
      // dispatch the success action immediately, otherwise call the API to get the data
      return stored
        ? observableOf(new LoadAcademicInfoSuccess(stored))
        : this.service.getAcademicInfo().pipe(
            withLatestFrom(
              this.store.select(applicantInfoSelectors.selectApplicationID)
            ),
            map(([academicInfo, appId]) => {
              if (!academicInfo || Object.entries(academicInfo).length === 0) {
                return new LoadAcademicInfoSuccess(new AcademicInfo(appId));
              } else {
                academicInfo.id = appId;
                return new LoadAcademicInfoSuccess(academicInfo);
              }
            }),
            catchError((err: HttpErrorResponse) => {
              const error = err instanceof HttpErrorResponse ? err.error : err;
              const displayError = error
                ? new AppLoadError(error.id, error.message)
                : new AppLoadError();

              return observableOf(new LoadAcademicInfoFail(displayError));
            })
          );
    }),
    takeUntil(this.destroyed$)
  );

  @Effect()
  postAcademicInfo$: Observable<Action> = this.actions$.pipe(
    ofType(EAcademicInfoActions.SUBMIT_ACADEMIC_INFO),
    switchMap((action: SubmitAcademicInfo) => {
      const { payload: newAcademicInfo } = action;
      newAcademicInfo.schoolsAttended = newAcademicInfo.schoolsAttended.reduce(
        (formatted: ISchoolInfo[], schoolInfo: ISchoolInfo) => {
          if (!isAnyPropEmpty(schoolInfo)) {
            formatted.push(schoolInfo);
          }
          return formatted;
        },
        []
      );

      return this.service.postAcademicInfo(newAcademicInfo).pipe(
        map(() => {
          return new SubmitAcademicInfoSuccess(newAcademicInfo);
        }),
        catchError((err: HttpErrorResponse) => {
          const error = err instanceof HttpErrorResponse ? err.error : err;

          const displayError = error
            ? new AppSubmitError(error.id, error.message)
            : new AppSubmitError();
          return observableOf(new SubmitAcademicInfoFail(displayError));
        }),
        takeUntil(this.destroyed$)
      );
    })
  );

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }
}
