import {
  EEnglishProficiencyActions,
  EnglishProficiencyActions
} from '../actions';
import {
  englishProficiencyAdapter,
  englishProficiencyInitialState,
  EnglishProficiencyStates
} from '../states';

export function englishProficiencyReducers(
  state: EnglishProficiencyStates = englishProficiencyInitialState,
  action: EnglishProficiencyActions
): EnglishProficiencyStates {
  switch (action.type) {
    case EEnglishProficiencyActions.LOAD_ENGLISH_PROFICIENCY: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case EEnglishProficiencyActions.LOAD_ENGLISH_PROFICIENCY_SUCCESS: {
      return englishProficiencyAdapter.addOne(action.payload, {
        ...state,
        loading: false,
        error: null,
        selectedId: action.payload.id
      });
    }
    case EEnglishProficiencyActions.LOAD_ENGLISH_PROFICIENCY_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }

    case EEnglishProficiencyActions.SUBMIT_ENGLISH_PROFICIENCY: {
      return {
        ...state,
        saving: true,
        error: null
      };
    }
    case EEnglishProficiencyActions.SUBMIT_ENGLISH_PROFICIENCY_SUCCESS: {
      return englishProficiencyAdapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          saving: false,
          error: null
        }
      );
    }
    case EEnglishProficiencyActions.SUBMIT_ENGLISH_PROFICIENCY_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }

    default:
      return state;
  }
}
