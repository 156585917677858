import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  EEliOption,
  IAppError,
  ICountry,
  IEnglishProficiency
} from 'app/shared/models';
import { englishProficiencyAdapter, EnglishProficiencyStates } from '../states';
import { Dictionary } from '@ngrx/entity';
import * as moment from 'moment';
import { selectAll as selectCountryList } from 'app/store/selectors/countryList.selectors';
import { getSafe } from 'app/utils';

const getError = (state: EnglishProficiencyStates): IAppError => state.error;

const getIsLoading = (state: EnglishProficiencyStates): boolean =>
  state.loading;

const getSelectedId = (state: EnglishProficiencyStates): string =>
  state.selectedId;

const getIsSaving = (state: EnglishProficiencyStates): boolean => state.saving;

const selectEnglishProficiency = createFeatureSelector<
  EnglishProficiencyStates
>('englishProficiency');

const { selectEntities, selectAll } = englishProficiencyAdapter.getSelectors(
  selectEnglishProficiency
);

export const selectEnglishProfData = createSelector(
  selectEntities,
  selectEnglishProficiency,
  (
    entities: Dictionary<IEnglishProficiency>,
    state: EnglishProficiencyStates
  ) => {
    const selectedId = getSelectedId(state);
    return selectedId ? entities[selectedId] : null;
  }
);

export const selectEnglishProfReview = createSelector(
  selectEntities,
  selectEnglishProficiency,
  selectCountryList,
  (
    entities: Dictionary<IEnglishProficiency>,
    state: EnglishProficiencyStates,
    countryList: ICountry[]
  ) => {
    const selectedId = getSelectedId(state);
    const data = selectedId ? entities[selectedId] : null;
    if (data && countryList.length > 0) {
      switch (data.eliOption) {
        case EEliOption.SUBMIT_SCORE:
          return {
            eliOption: data.eliOption,
            ...data.test,
            country: getSafe(
              () =>
                countryList.find(country => country.code === data.test.country)
                  .name,
              data.test.country
            ),
            date: formatDate(data.test.date)
          };
        case EEliOption.ATTEND_PARTNERSHIP_INSTITUTIONS:
          return {
            eliOption: data.eliOption,
            name: data.partner.name,
            dateCompletion: formatDate(data.partner.dateCompletion)
          };
        case EEliOption.ATTEND_ELI:
          return { eliOption: 'Attend ELI at Newnham' };
        case EEliOption.ATTEND_SLC:
          return { eliOption: 'Attend ELI at SIA' };
        case EEliOption.NOT_APPLICABLE:
          return { eliOption: 'Not Applicable/My primary language is English' };
        case EEliOption.ATTEND_DPP:
          return { eliOption: 'Attend Degree Prep Program at Newnham' };
        default:
          return data.eliOption;
      }
    }
    return null;
  }
);

const formatDate = date => moment.utc(date).format('LL');

export const selectError = createSelector(
  selectEnglishProficiency,
  getError
);

export const selectLoading = createSelector(
  selectEnglishProficiency,
  getIsLoading
);

export const selectSaving = createSelector(
  selectEnglishProficiency,
  getIsSaving
);
