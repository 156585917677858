import { agentAppAdapter, IAgentApplicationStates } from '../states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';
import { IAgentApplication } from 'shared/models/agentApplication.models';

const getError = (state: IAgentApplicationStates): any => state.error;

const getIsLoading = (state: IAgentApplicationStates): boolean => state.loading;

const getIsSaving = (state: IAgentApplicationStates): boolean => state.saving;

export const getSelectedId = (state: IAgentApplicationStates): string =>
  state.selectedId;

const selectAgentApplications = createFeatureSelector<IAgentApplicationStates>(
  'agentApplications'
);

const { selectEntities, selectAll } = agentAppAdapter.getSelectors(
  selectAgentApplications
);

export const selectAgentApps = selectAll;

export const selectError = createSelector(
  selectAgentApplications,
  getError
);

export const selectLoading = createSelector(
  selectAgentApplications,
  getIsLoading
);

export const selectSaving = createSelector(
  selectAgentApplications,
  getIsSaving
);
