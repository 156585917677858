import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAppError } from 'app/shared/models';
import { languageListAdapter, LanguageListStates } from 'app/store/states';

export const getError = (state: LanguageListStates): IAppError => state.error;

export const getIsLoading = (state: LanguageListStates): boolean =>
  state.loading;

export const selectLanguageList = createFeatureSelector<LanguageListStates>(
  'languageList'
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = languageListAdapter.getSelectors(selectLanguageList);

export const selectError = createSelector(
  selectLanguageList,
  getError
);

export const selectLoading = createSelector(
  selectLanguageList,
  getIsLoading
);
