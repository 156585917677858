import { Action } from '@ngrx/store';
import { IAppError, IApplicantInfo } from 'app/shared/models';

//  Enumeration defining the different actions
export enum EApplicantInfoActions {
  LOAD_APPLICANT_INFO = '[ApplicantInfo] Load Applicant Info',
  LOAD_APPLICANT_INFO_SUCCESS = '[ApplicantInfo] Load Applicant Info Success',
  LOAD_APPLICANT_INFO_FAIL = '[ApplicantInfo] Load Applicant Info Fail',
  RESET_APPLICANT_INFO = '[ApplicantInfo] Reset Application Info'
}

export class LoadApplicantInfo implements Action {
  public readonly type = EApplicantInfoActions.LOAD_APPLICANT_INFO;

  constructor(public payload: string = null) {}
}

export class LoadApplicantInfoSuccess implements Action {
  public readonly type = EApplicantInfoActions.LOAD_APPLICANT_INFO_SUCCESS;
  constructor(public payload: IApplicantInfo) {}
}

export class LoadApplicantInfoFail implements Action {
  public readonly type = EApplicantInfoActions.LOAD_APPLICANT_INFO_FAIL;
  constructor(public payload: IAppError) {}
}

export class ResetApplicantInfo implements Action {
  public readonly type = EApplicantInfoActions.RESET_APPLICANT_INFO;

  constructor(public payload: string = null) {}
}
//  Defining a union for all the actions in this component
export type ApplicantInfoActionsUnion =
  | LoadApplicantInfo
  | LoadApplicantInfoSuccess
  | LoadApplicantInfoFail
  | ResetApplicantInfo;
