<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport="true"
    position="start"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    opened="false"
  >
    <mat-toolbar color="primary" fxLayoutAlign="end center">
      <button
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>

    <mat-nav-list>
      <a href="#" mat-list-item (click)="onHomeClick()">
        <mat-icon>home</mat-icon>
        <span fxFlexOffset="1em">Home</span>
      </a>

      <a href="#" mat-list-item>
        <img ngxGravatar [email]="user.emails[0]" fallback="mm" size="24" />
        <span fxFlexOffset="1em">{{ user.name }}</span>
      </a>

      <a href="#" mat-list-item (click)="onSignOutClick()">
        <mat-icon>exit_to_app</mat-icon>
        <span fxFlexOffset="1em">Sign out</span>
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary" fxLayout="column">
      <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-icon-button (click)="drawer.toggle()" fxHide.gt-sm>
          <mat-icon>menu</mat-icon>
        </button>
        <div
          fxLayoutAlign="center center"
          fxLayout="row"
          fxFlex.lt-md="1 1 auto"
        >
          <img
            (click)="onHomeClick()"
            class="seneca-logo"
            fxFlexAlign.lt-sm="center"
            fxFlex.lt-sm="1 1 auto"
            src="../../assets/img/logo_white.svg"
          />
          <p class="header__text" fxHide.lt-lg>
            International Online Application
          </p>
        </div>
        <div
          fxLayout="row"
          fxFlex="1 1 auto"
          fxLayoutAlign="end center"
          fxHide.lt-md
        >
          <button mat-button (click)="onHomeClick()">
            <mat-icon>home</mat-icon>
            Home
          </button>
          <button mat-button>
            <img ngxGravatar [email]="user.emails[0]" fallback="mm" size="24" />
            {{ user.name }}
          </button>
          <button mat-button (click)="onSignOutClick()">
            <mat-icon>exit_to_app</mat-icon>
            Sign out
          </button>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
    <div
      fxLayout="row"
      fxLayout.lt-md="end"
      fxLayoutAlign="center"
      class="sub-header__container"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxFlex="85%"
        fxFlex.lt-md="95%"
        class="app-number-container"
      >
        <h2 class="sub-header__title">Welcome to Seneca Polytechnic</h2>        
        <div
          fxLayout="column"
          fxLayoutAlign="center end"
          fxLayoutGap=".5em"
          fxFlexAlign="end"
          *ngIf="!isAtHomeScreen"
        >
          <p *ngIf="applicationId">
            <span fxHide.lt-md>Application Number: </span>{{ applicationId }}
          </p>
          <p style="text-align: end" *ngIf="applicationStatus">
            <span fxHide.lt-md>Status: </span>{{ applicationStatus }}
          </p>
        </div>
      </div>      
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="center"
      class="sub-header__container"
    >
    <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxFlex="85%"
        fxFlex.lt-md="95%"
      >
      <!-- <div
          fxLayout="row"
          fxLayoutAlign="flex-start"
          fxLayoutGap=".5em"
          fxFlexAlign="start"
        >
        <p style="text-align: start">
          <span fxHide.lt-md>New! <a href='https://www.senecacollege.ca/international/seneca-international-academy.html' target='_blank'>Seneca International Academy</a> (SIA) is now accepting applications for academic programs commencing in September 2020.  </span>
          <br><br>
          <span fxHide.lt-md>Students who choose to study at SIA in September 2020 may be eligible for a $500 OneCard credit that can be used to purchase goods and services on campus. To learn more about eligibility, visit <a href='https://www.senecacollege.ca/SIA' target='_blank'>www.senecacollege.ca/SIA</a></span>
        </p>        
      </div> -->
      </div>
    </div>    
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
