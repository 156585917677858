import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ApplicantInfo, AppLoadError } from 'app/shared/models';
import { Observable, of as observableOf, Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import {
  EApplicantInfoActions,
  LoadApplicantInfo,
  LoadApplicantInfoFail,
  LoadApplicantInfoSuccess
} from '../actions';
import { ApplicantInfoService } from 'shared/services/api/applicant-info.service';

@Injectable()
export class ApplicantInfoEffects implements OnDestroy {
  constructor(
    private actions$: Actions,
    private service: ApplicantInfoService
  ) {}

  destroyed$ = new Subject();

  @Effect()
  getApplicantInfo$: Observable<Action> = this.actions$.pipe(
    takeUntil(this.destroyed$),
    ofType(EApplicantInfoActions.LOAD_APPLICANT_INFO),
    switchMap((action: LoadApplicantInfo) => {
      const { payload } = action;
      return this.service.getApplicantInfo(payload).pipe(
        map(applicantInfo => {
          if (!applicantInfo || Object.entries(applicantInfo).length === 0) {
            // creating an empty applicant info object for new applicants
            applicantInfo = new ApplicantInfo();
          }
          return new LoadApplicantInfoSuccess(applicantInfo);
        }),
        catchError((err: HttpErrorResponse) => {
          const error = err instanceof HttpErrorResponse ? err.error : err;
          const displayError = error
            ? new AppLoadError(error.id, error.message)
            : new AppLoadError();

          return observableOf(new LoadApplicantInfoFail(displayError));
        })
      );
    })
  );

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }
}
