import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var EnglishProficiencyService = /** @class */ (function () {
    function EnglishProficiencyService(http) {
        var _this = this;
        this.http = http;
        this.getEnglishProficiency = function () {
            return _this.http.get(environment.apiUrl + "/getEnglishProficiency");
        };
        this.postEnglishProficiency = function (englishProficiency) {
            return _this.http.post(environment.apiUrl + "/AddEnglishProficiency", englishProficiency);
        };
    }
    EnglishProficiencyService.ngInjectableDef = i0.defineInjectable({ factory: function EnglishProficiencyService_Factory() { return new EnglishProficiencyService(i0.inject(i1.HttpClient)); }, token: EnglishProficiencyService, providedIn: "root" });
    return EnglishProficiencyService;
}());
export { EnglishProficiencyService };
