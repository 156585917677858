import { createEntityAdapter } from '@ngrx/entity';
import { IBaseEntityState, IApplicantInfo } from 'shared/models';

export interface ApplicantInfoStates extends IBaseEntityState<IApplicantInfo> {}

export const applicantInfoAdapter = createEntityAdapter<IApplicantInfo>({
  selectId: (applicant: IApplicantInfo) => {
    // We need a temporary ID here beacause new users will not have an appId until they submit their personal information
    if (applicant.appId) {
      return applicant.appId;
    } else {
      return 'newApplication';
    }
  }
});

export const applicantInfoInitialState: ApplicantInfoStates = applicantInfoAdapter.getInitialState(
  {
    selectedId: null,
    loading: false,
    error: null
  }
);
