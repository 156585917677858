import {
  authAdapter,
  authInitialState,
  IAuthStates
} from '../states/auth.states';
import { AuthActions, EAuthActions } from '../actions/auth.actions';

export function authReducers(
  state: IAuthStates = authInitialState,
  action: AuthActions
): IAuthStates {
  switch (action.type) {
    case EAuthActions.ADD_USER:
      return {
        ...state,
        loading: true,
        error: null,
        selectedId: null
      };
    case EAuthActions.ADD_USER_SUCCESS:
      return authAdapter.addOne(action.payload, {
        ...state,
        loading: false,
        error: null,
        selectedId: action.payload.oid
      });
    case EAuthActions.ADD_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        selectedId: null
      };
    case EAuthActions.VERIFY_AGENT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case EAuthActions.VERIFY_AGENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case EAuthActions.VERIFY_AGENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default: {
      return state;
    }
  }
}
