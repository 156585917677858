import { Action } from '@ngrx/store';

export enum ESharedActions {
  CLEAR_STORE = '[STORE] CLEAR',
}

export class ClearStore implements Action {
  public readonly type = ESharedActions.CLEAR_STORE;

  constructor() {
  }
}


export type SharedActions = ClearStore;
