import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  EInfoReleaseActions,
  LoadInfoReleaseFail,
  LoadInfoReleaseSuccess,
  SubmitInfoRelease,
  SubmitInfoReleaseFail,
  SubmitInfoReleaseSuccess
} from '../actions';
import {
  AppLoadError,
  AppSubmitError,
  IInfo,
  InfoRelease
} from 'shared/models';
import { InfoReleaseService } from 'shared/services/api/info-release.service';
import { of as observableOf } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { applicantInfoSelectors, infoReleaseSelectors } from '../selectors';
import { AppState } from '../reducers';
import { Store } from '@ngrx/store';

@Injectable()
export class InfoReleaseEffects {
  constructor(
    private infoReleaseService: InfoReleaseService,
    private actions$: Actions,
    private store: Store<AppState>
  ) {}

  @Effect()
  getInfoRelease$ = this.actions$.pipe(
    ofType(EInfoReleaseActions.LOAD_INFO_RELEASE),
    withLatestFrom(
      this.store.select(infoReleaseSelectors.selectInfoReleaseData)
    ),
    switchMap(([action, stored]) =>
      stored
        ? observableOf(new LoadInfoReleaseSuccess(stored))
        : this.infoReleaseService.loadInfoRelease().pipe(
            withLatestFrom(
              this.store.select(applicantInfoSelectors.selectApplicationID)
            ),
            map(([info, appId]) => {
              if (!info || Object.entries(info).length === 0) {
                return new LoadInfoReleaseSuccess(new InfoRelease(appId));
              } else {
                const newInfoRelease = new InfoRelease(appId, info);
                return new LoadInfoReleaseSuccess(newInfoRelease);
              }
            }),
            catchError((err: HttpErrorResponse) => {
              const error = err instanceof HttpErrorResponse ? err.error : err;
              const displayError = error
                ? new AppLoadError(error.id, error.message)
                : new AppLoadError();

              return observableOf(new LoadInfoReleaseFail(displayError));
            })
          )
    )
  );

  @Effect()
  submitInfoRelease$ = this.actions$.pipe(
    ofType(EInfoReleaseActions.SUBMIT_INFO_RELEASE),
    switchMap((action: SubmitInfoRelease) => {
      const submitPayload: IInfo = {
        ...action.payload.info
      };
      return this.infoReleaseService.submitInfoRelease(submitPayload).pipe(
        map(() => {
          return new SubmitInfoReleaseSuccess(action.payload);
        }),
        catchError((err: HttpErrorResponse) => {
          const error = err instanceof HttpErrorResponse ? err.error : err;
          const displayError = error
            ? new AppSubmitError(error.id, error.message)
            : new AppSubmitError();

          return observableOf(new SubmitInfoReleaseFail(displayError));
        })
      );
    })
  );
}
