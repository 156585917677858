import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAcademicInfo } from 'shared/models';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AcademicInfoService {
  constructor(private http: HttpClient) {}

  getAcademicInfo = (): Observable<IAcademicInfo> => {
    return this.http.get(
      `${environment.apiUrl}/getAcademicInformation`
    ) as Observable<IAcademicInfo>;
  };

  postAcademicInfo = (
    academicInfo: IAcademicInfo
  ): Observable<IAcademicInfo> => {
    return this.http.post(
      `${environment.apiUrl}/AddAcademicInformation`,
      academicInfo
    ) as Observable<IAcademicInfo>;
  };

  checkIfApplicantProgramConfig (appId) {
    return this.http.get(`${environment.apiUrl}/checkIfApplicantProgramConfig/?appId=${appId}`);
  };

  checkIfAESubmitted (appId) {
    return this.http.get(`${environment.apiUrl}/checkIfAESubmitted/?appId=${appId}`);
  }

  setAutoEval (appId) {
    return this.http.post(`${environment.apiUrl}/setAutoEval`, {"Application_Id": appId});
  };


  getGuid(appId) {
    return this.http.get(`${environment.apiUrl}/getGuid?appId=${appId}`);
  }
}
