import { Component, OnDestroy, OnInit } from '@angular/core';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Store } from '@ngrx/store';
import { AuthService } from 'app/auth/auth.service';
import { commonEnv } from 'environments/environment.common';
import { IAuthStates } from './store/states/auth.states';
import { AddNewUser, VerifyAgent, AddNewUserSuccess } from './store/actions/auth.actions';
import { Router } from '@angular/router';
import { AppService } from './app.service';
import { Subscription } from 'rxjs';
import { EMsalItemType, EMSALStatus } from 'shared/models';
import { MSALError } from '@azure/msal-angular/dist/MSALError';
import { environment } from '../environments/environment';
import { DisplayErrorDialog } from 'app/store/actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'International Online Application';
  // holding displayed user name (declared while creating an account)
  displayedName: string;
  loadingText: string;
  loginFailure$: Subscription = null;
  acquireTokenFailure$: Subscription = null;
  isInvalidLoginAttempt = false;
  constructor(
    public appService: AppService,
    public msalService: MsalService,
    private authService: AuthService,
    private store: Store<IAuthStates>,
    private router: Router,
    private broadcastService: BroadcastService
  ) {
    this.loginFailure$ = this.broadcastService.subscribe(
      EMsalItemType.LOGIN_FAILURE,
      (payload: MSALError) => {
        this.isInvalidLoginAttempt = true;
        this.handleLoginFailure(payload);
      }
    );

    this.acquireTokenFailure$ = this.broadcastService.subscribe(
      EMsalItemType.ACQUIRE_TOKEN_FAILURE,
      (payload: MSALError) => {
        this.isInvalidLoginAttempt = true;
        this.handleAcquiredTokenFailure(payload);
      }
    );
  }

  ngOnInit(): void {
    this.loadingText = this.appService.setLoadingText();
    if (!this.msalService.loginInProgress()) {
      const userInfo = this.authService.getUserInfo();
      if (userInfo) {
        this.appService.selectUserInfo();
        this.appService.watchUserError();
        // setting the username for application header
        this.displayedName = userInfo.name;

        if (userInfo.tfp === commonEnv.applicantSignInPolicy) {
          this.store.dispatch(new AddNewUser());
        } else if (userInfo.tfp === commonEnv.agentSignInPolicy) {
          // Original: this.store.dispatch(new VerifyAgent());
          /* 
          We needed to by pass verifyagent for the new Role system in place
          Originally we dispatched a VerifyAgent Action but now we skip that by
          dispatching a new user instead.
          */
          this.store.dispatch(new AddNewUser(this.authService.getUserInfo().emails[0]));
        }
      }
    }
  }

  handleLoginFailure = (payload: MSALError) => {
    const { errorDesc } = payload;
    if (errorDesc && errorDesc.indexOf(EMSALStatus.FORGOT_PASSWORD) !== -1) {
      // console.log('FORGOT PASSWORD ERROR ENCOUNTERED');
      this.authService.setUserType('ERROR');
      this.msalService.authority = `${environment.azureInstance}/${environment.tenant}/${commonEnv.resetPasswordPolicy}`;
      this.msalService.loginRedirect(environment.scopes, 'LOGIN');
    } else if (
      errorDesc &&
      errorDesc.indexOf(EMSALStatus.CANCEL_RESET_PASSWORD) !== -1
    ) {
      this.msalService.authority = `${environment.azureInstance}/${environment.tenant}/${commonEnv.applicantSignInPolicy}`;
      this.authService.login();
    } else if (
      errorDesc &&
      errorDesc.indexOf(EMSALStatus.MULTIPLE_ACCOUNTS) !== -1
    ) {
      this.msalService.authority = `${environment.azureInstance}/${environment.tenant}/${commonEnv.applicantSignInPolicy}`;
      this.authService.login();
    } 
    // Handle the acquire token error due to no existing session
    // Based on whether the original request was for an agent or applicant
    // user will be redirected to the proper login
    else if (errorDesc && errorDesc.indexOf(EMSALStatus.NO_EXISTING_SESSION) !== -1) {
      // console.log('NO SESSION ERROR ENCOUNTERED');

      // if(this.authService.getUserType() === 'AGENT'){
      //   this.msalService.authority = `${environment.azureInstance}/${environment.tenant}/${commonEnv.agentSignInPolicy}`;
      // }
      // else{        
      //   this.msalService.authority = `${environment.azureInstance}/${environment.tenant}/${commonEnv.applicantSignInPolicy}`;
      // }

      // this.authService.loginAfterNoSession();
      
      this.msalService.authority = `${environment.azureInstance}/${environment.tenant}/${commonEnv.agentSignInPolicy}`;
      this.authService.setUserType('AGENT');      
      this.authService.loginAfterNoSession();
    }
    else {
      this.store.dispatch(
        new DisplayErrorDialog({
          message: errorDesc,
          action: this.authService.logout
        })
      );
    }
  };

  // TODO: handle specific error with a friendly error message
  handleAcquiredTokenFailure = (payload: MSALError) => {
    const { error } = payload;
    if (error && error.indexOf(EMSALStatus.SESSION_TIME_OUT) !== -1) {
      this.store.dispatch(
        new DisplayErrorDialog({
          message: 'Session time out, please login again..',
          action: this.authService.logout
        })
      );
    } else if (error && error.indexOf(EMSALStatus.MULTIPLE_ACCOUNTS) !== -1) {
      this.store.dispatch(
        new DisplayErrorDialog({
          message:
            'Multiple Microsoft accounts detected, make sure you logged out other accounts before logging in',
          action: this.authService.logout
        })
      );
    } 
    // Handle the acquire token error due to no existing session
    // Based on whether the original request was for an agent or applicant
    // user will be redirected to the proper login
    else if (error && error.indexOf(EMSALStatus.NO_EXISTING_SESSION) !== -1) {
      // this.msalService.authority = `${environment.azureInstance}/${environment.tenant}/${commonEnv.agentSignInPolicy}`;
      // this.authService.setUserType('AGENT');      
      // this.authService.loginAfterNoSession();
      if(this.authService.getUserType() === 'AGENT'){
        this.msalService.authority = `${environment.azureInstance}/${environment.tenant}/${commonEnv.agentSignInPolicy}`;
      }
      else{        
        this.msalService.authority = `${environment.azureInstance}/${environment.tenant}/${commonEnv.applicantSignInPolicy}`;
      }

      this.authService.loginAfterNoSession();
    }
    else {
      this.store.dispatch(
        new DisplayErrorDialog({
          message: payload.errorDesc,
          action: this.authService.logout
        })
      );
    }
  };

  ngOnDestroy(): void {
    this.broadcastService.getMSALSubject().next(1);
    if (this.loginFailure$) {
      this.loginFailure$.unsubscribe();
    }
    if (this.acquireTokenFailure$) {
      this.acquireTokenFailure$.unsubscribe();
    }
  }
}
