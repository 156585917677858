import { createEntityAdapter } from '@ngrx/entity';
import { IBaseEntityState, IfilesInStore } from 'app/shared/models';
import { getUnUploadedDocumentsFromStorage } from 'app/utils';

export interface UploadStates extends IBaseEntityState<IfilesInStore> {
  saveToStore: boolean;
  unUploadedForms: string[];
}

export const uploadAdapter = createEntityAdapter<IfilesInStore>({
  selectId: (files: IfilesInStore) => files.id
});

export const uploadInitialState: UploadStates = uploadAdapter.getInitialState({
  selectedId: null,
  loading: true,
  error: null,
  saveToStore: false,
  unUploadedForms: getUnUploadedDocumentsFromStorage()
});
