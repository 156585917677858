import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAppError, IProgramChoiceApp } from 'shared/models';
import { programChoiceAdapter, ProgramChoiceStates } from '../states';

export const getError = (state: ProgramChoiceStates): IAppError => state.error;

export const getIsLoading = (state: ProgramChoiceStates): boolean =>
  state.loading;

export const getIsSaving = (state: ProgramChoiceStates): boolean =>
  state.saving;

const selectProgramChoices = createFeatureSelector<ProgramChoiceStates>(
  'programChoice'
);

const { selectAll } = programChoiceAdapter.getSelectors(selectProgramChoices);

export const selectProgramChoice = createSelector(
  selectAll,
  (programChoiceApps: IProgramChoiceApp[]) => {
    if (programChoiceApps.length > 0) {
      return programChoiceApps[programChoiceApps.length - 1];
    }
    return null;
  }
);

export const selectProgramChoiceReview = createSelector(
  selectAll,
  (programChoiceApps: IProgramChoiceApp[]) => {
    if (
      programChoiceApps.length > 0 &&
      programChoiceApps[programChoiceApps.length - 1].choices.length > 0
    ) {
      return programChoiceApps[programChoiceApps.length - 1].choices.map(
        ({ startDate, campus, program, description, choiceNumber }, index) => ({
          index: index + 1,
          startDate: startDateToTermConverter(startDate),
          program,
          description,
          campus,
          choiceNumber
        })
      );
    }
    return null;
  }
);

const startDateToTermConverter = date => {
  let term;
  try {
    const month = date.getMonth();
    
    switch (true) {
      case month >= 0 && month < 4:
        term = 'Winter';
        break;
      case month >= 4 && month < 8:
        term = 'Summer';
        break;
      case month >= 8 && month < 12:
        term = 'Fall';
        break;
      default:
        term = 'Unidentified';
        break;
    }
    return `${term} ${date.getFullYear()}`;
  } catch (e) {
    throw Error('input is not in date format');
  }
};

export const selectError = createSelector(
  selectProgramChoices,
  getError
);

export const selectLoading = createSelector(
  selectProgramChoices,
  getIsLoading
);

export const selectSaving = createSelector(
  selectProgramChoices,
  getIsSaving
);
