import {
  CountryListActionsUnion,
  ECountryListActions
} from 'app/store/actions';
import {
  countryListAdapter,
  countryListInitialState,
  CountryListStates
} from 'app/store/states';

export function countryListReducers(
  state: CountryListStates = countryListInitialState,
  action: CountryListActionsUnion
): CountryListStates {
  switch (action.type) {
    case ECountryListActions.LOAD_COUNTRY_LIST: {
      return {
        ...state,
        loading: true
      };
    }
    case ECountryListActions.LOAD_COUNTRY_LIST_SUCCESS: {
      return countryListAdapter.addAll(action.payload, {
        ...state,
        loading: false,
        error: null
      });
    }

    case ECountryListActions.LOAD_COUNTRY_LIST_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
