import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { Router } from '@angular/router';
import { commonEnv } from 'environments/environment.common';
import { routeList } from 'app/shared/models';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  loading: boolean;
  // holding displayed user name (declared while creating an account)
  displayedName = null;
  // specifies the user type
  userType: string = null;
  // specifies the redirect button text
  buttonText: string = null;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.loading = true;
    this.getUserType();
  }

  getUserType = (): void => {
    const userInfo = this.authService.getUserInfo();
    if (userInfo && userInfo.tfp === commonEnv.agentSignInPolicy) {
      this.userType = 'agent';
      this.buttonText = 'Return to agent Dashboard';
      this.loading = false;
    } else if (userInfo && userInfo.tfp === commonEnv.applicantSignInPolicy) {
      this.userType = 'applicant';
      this.buttonText = 'Return to application';
      this.loading = false;
    } else {
      this.loading = false;
      // TODO: add error message here
    }
  };

  redirect = (): void => {
    if (this.userType === 'applicant') {
      this.router.navigateByUrl(`/${routeList.application.path}`);
    } else if (this.userType === 'agent') {
      this.router.navigateByUrl(`/${routeList.agent.path}`);
    }
  };
}
