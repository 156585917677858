import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IAPIResponseFile, IUploadResponse } from 'shared/models';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  constructor(private http: HttpClient) {}

  getFiles = (): Observable<IAPIResponseFile[]> => {
    return this.http.get(
      `${environment.apiUrl}/getUploadedFiles`
    ) as Observable<IAPIResponseFile[]>;
  };

  uploadFiles = (formData): Observable<IUploadResponse> => {
    return this.http.post(
      `${environment.apiUrl}/uploadIwaFiles`,
      formData
    ) as Observable<IUploadResponse>;
  };
}
