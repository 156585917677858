import * as tslib_1 from "tslib";
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EEliOption } from 'app/shared/models';
import { englishProficiencyAdapter } from '../states';
import * as moment from 'moment';
import { selectAll as selectCountryList } from 'app/store/selectors/countryList.selectors';
import { getSafe } from 'app/utils';
var getError = function (state) { return state.error; };
var ɵ0 = getError;
var getIsLoading = function (state) {
    return state.loading;
};
var ɵ1 = getIsLoading;
var getSelectedId = function (state) {
    return state.selectedId;
};
var ɵ2 = getSelectedId;
var getIsSaving = function (state) { return state.saving; };
var ɵ3 = getIsSaving;
var selectEnglishProficiency = createFeatureSelector('englishProficiency');
var _a = englishProficiencyAdapter.getSelectors(selectEnglishProficiency), selectEntities = _a.selectEntities, selectAll = _a.selectAll;
export var selectEnglishProfData = createSelector(selectEntities, selectEnglishProficiency, function (entities, state) {
    var selectedId = getSelectedId(state);
    return selectedId ? entities[selectedId] : null;
});
export var selectEnglishProfReview = createSelector(selectEntities, selectEnglishProficiency, selectCountryList, function (entities, state, countryList) {
    var selectedId = getSelectedId(state);
    var data = selectedId ? entities[selectedId] : null;
    if (data && countryList.length > 0) {
        switch (data.eliOption) {
            case EEliOption.SUBMIT_SCORE:
                return tslib_1.__assign({ eliOption: data.eliOption }, data.test, { country: getSafe(function () {
                        return countryList.find(function (country) { return country.code === data.test.country; })
                            .name;
                    }, data.test.country), date: formatDate(data.test.date) });
            case EEliOption.ATTEND_PARTNERSHIP_INSTITUTIONS:
                return {
                    eliOption: data.eliOption,
                    name: data.partner.name,
                    dateCompletion: formatDate(data.partner.dateCompletion)
                };
            case EEliOption.ATTEND_ELI:
                return { eliOption: 'Attend ELI at Newnham' };
            case EEliOption.ATTEND_SLC:
                return { eliOption: 'Attend ELI at SIA' };
            case EEliOption.NOT_APPLICABLE:
                return { eliOption: 'Not Applicable/My primary language is English' };
            case EEliOption.ATTEND_DPP:
                return { eliOption: 'Attend Degree Prep Program at Newnham' };
            default:
                return data.eliOption;
        }
    }
    return null;
});
var formatDate = function (date) { return moment.utc(date).format('LL'); };
var ɵ4 = formatDate;
export var selectError = createSelector(selectEnglishProficiency, getError);
export var selectLoading = createSelector(selectEnglishProficiency, getIsLoading);
export var selectSaving = createSelector(selectEnglishProficiency, getIsSaving);
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
