import { createEntityAdapter } from '@ngrx/entity';
import { IBaseEntityState } from 'shared/models';
import { IAgentApplication } from 'shared/models/agentApplication.models';

export interface IAgentApplicationStates
  extends IBaseEntityState<IAgentApplication> {
  saving: boolean;
}

export const agentAppAdapter = createEntityAdapter<IAgentApplication>({
  selectId: (agentApp: IAgentApplication) => agentApp.appId
});

export const agentApplicationInitialState: IAgentApplicationStates = agentAppAdapter.getInitialState(
  {
    selectedId: null,
    loading: false,
    error: null,
    saving: false
  }
);
