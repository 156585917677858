import { Action } from '@ngrx/store';
import { IAppError, IInfoRelease } from 'app/shared/models';

export enum EInfoReleaseActions {
  LOAD_INFO_RELEASE = '[InfoRelease] Load Info Release',
  LOAD_INFO_RELEASE_SUCCESS = '[InfoRelease] Load Info Release Success',
  LOAD_INFO_RELEASE_FAIL = '[InfoRelease] Load Info Release Fail',

  SUBMIT_INFO_RELEASE = '[InfoRelease] Submit Info Release',
  SUBMIT_INFO_RELEASE_SUCCESS = '[InfoRelease] Submit Info Release Success',
  SUBMIT_INFO_RELEASE_FAIL = '[InfoRelease] Submit Info Release Fail'
}

export class LoadInfoRelease implements Action {
  public readonly type = EInfoReleaseActions.LOAD_INFO_RELEASE;
}

export class LoadInfoReleaseSuccess implements Action {
  public readonly type = EInfoReleaseActions.LOAD_INFO_RELEASE_SUCCESS;
  constructor(public payload: IInfoRelease) {}
}

export class LoadInfoReleaseFail implements Action {
  public readonly type = EInfoReleaseActions.LOAD_INFO_RELEASE_FAIL;
  constructor(public payload: IAppError) {}
}

export class SubmitInfoRelease implements Action {
  public readonly type = EInfoReleaseActions.SUBMIT_INFO_RELEASE;
  constructor(public payload: IInfoRelease) {}
}

export class SubmitInfoReleaseSuccess implements Action {
  public readonly type = EInfoReleaseActions.SUBMIT_INFO_RELEASE_SUCCESS;
  constructor(public payload: IInfoRelease) {}
}

export class SubmitInfoReleaseFail implements Action {
  public readonly type = EInfoReleaseActions.SUBMIT_INFO_RELEASE_FAIL;
  constructor(public payload: IAppError) {}
}

export type InfoReleaseActions =
  | LoadInfoRelease
  | LoadInfoReleaseSuccess
  | LoadInfoReleaseFail
  | SubmitInfoRelease
  | SubmitInfoReleaseSuccess
  | SubmitInfoReleaseFail;
