import { EInfoReleaseActions, InfoReleaseActions } from '../actions';
import {
  infoReleaseAdapter,
  infoReleaseInitialState,
  InfoReleaseStates
} from '../states';

export function infoReleaseReducer(
  state: InfoReleaseStates = infoReleaseInitialState,
  action: InfoReleaseActions
): InfoReleaseStates {
  switch (action.type) {
    case EInfoReleaseActions.LOAD_INFO_RELEASE: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case EInfoReleaseActions.LOAD_INFO_RELEASE_SUCCESS: {
      return infoReleaseAdapter.addOne(action.payload, {
        ...state,
        loading: false,
        error: null,
        selectedId: action.payload.id
      });
    }
    case EInfoReleaseActions.LOAD_INFO_RELEASE_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }

    case EInfoReleaseActions.SUBMIT_INFO_RELEASE: {
      return {
        ...state,
        saving: true,
        error: null
      };
    }
    case EInfoReleaseActions.SUBMIT_INFO_RELEASE_SUCCESS: {
      return infoReleaseAdapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          saving: false,
          error: null
        }
      );
    }
    case EInfoReleaseActions.SUBMIT_INFO_RELEASE_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }

    default:
      return state;
  }
}
