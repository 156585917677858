import { createEntityAdapter } from '@ngrx/entity';
import {
  IAppError,
  IBaseEntityState,
  IPayment,
  IPaymentResponse
} from 'app/shared/models';

export interface PaymentStates extends IBaseEntityState<IPayment> {
  paid: boolean; //True if Payment has been accepted
  promoValid: boolean; //Indicates if Promo Code is valid
  promoError: IAppError; //Error validating Promo Code
  initializingPayment: boolean;
  transactionTicket: string;
  promoFormError: IAppError; //Incorrect Promo Code
  checkingPromoCode: boolean; //True while validating Promo Code
  paymentResponse: IPaymentResponse | null; //Response for Payment
}

export const paymentAdapter = createEntityAdapter<IPayment>({
  selectId: (payment: IPayment) => payment.id
});

export const paymentInitialState: PaymentStates = paymentAdapter.getInitialState(
  {
    selectedId: null,
    loading: false,
    initializingPayment: false,
    transactionTicket: null,
    error: null,
    paid: false,
    promoValid: false,
    promoError: null,
    promoFormError: null,
    checkingPromoCode: false,
    paymentResponse: null
  }
);
