import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { LoadApplicantInfo } from 'app/store/actions';
import { routeList } from 'app/shared/models';
import { IAuthStates } from 'app/store/states/auth.states';
import { AuthService } from 'app/auth/auth.service';
import { commonEnv } from '../../environments/environment.common';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService implements OnDestroy {
  // default is set by the application component
  lastStep: number;
  destroyed$ = new Subject();
  /**
   * sets the loading error(s) of the application component
   */
  loading: boolean;
  isLastStep: boolean;

  status: string = null;

  getLastStep(): Observable<number> {
    return Observable.of(this.lastStep);
  }

  constructor(
    private store: Store<IAuthStates>,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  toggleComplete(completedStepName: string): void {
    let foundCurrentRoute = false;
    let completedStep = 0;

    const targetUrl = Object.values(routeList.application.children).find(
      (route, index) => {
        if (foundCurrentRoute) {
          return true;
        } else if (route.match(completedStepName)) {
          if (
            completedStepName.match(routeList.application.children.personalInfo)
          ) {
            // only for the first time when the application is being created,
            // we need to reload the applicant info by calling the API to get the application ID created in the backend
            if (
              this.authService.getUserInfo().tfp ===
              commonEnv.applicantSignInPolicy
            ) {
              this.store.dispatch(new LoadApplicantInfo());
            }
          }
          completedStep = index + 1;
          foundCurrentRoute = true;
        }
        return false;
      }
    );
    // setting the current last step completed whenever a form page success action is called in the shared.effects.ts
    // This condition is very important because we don't wean't to change the last step again if the user is updating a page
    if (completedStep > 0) {
      if (completedStep > this.lastStep) {
        this.lastStep = completedStep;
      }
      if (completedStep === 9) {
        this.router.navigateByUrl(`/${routeList.complete}`);
      } else {
        this.router.navigateByUrl(
          `/${routeList.application.path}/${targetUrl}`
        );
      }
    }
  }

  /**
   * get the route for the last step the user has completed
   */
  getLastStepRoute(): string {
    return (
      '/' +
      routeList.application.path +
      '/' +
      Object.values(routeList.application.children).find((route, index) => {
        return index === this.lastStep;
      })
    );
  }

  /**
   * set the landing page based on the state of the form
   */
  setLandingRoute(): void {
    const urlTree = this.router.parseUrl(this.router.url);

    const landingUrl = urlTree.root.children['primary'].segments
      .map(segments => segments.path)
      .join('/');

    // getting the target page by matching url segments with the application children routes model
    const targetPage = urlTree.root.children['primary'].segments
      .map(segments => {
        return Object.values(routeList.application.children).find(route => {
          return !!segments.path.match(route);
        });
      })
      .find(pathArray => !!pathArray);

    const validTargetPage = Object.values(routeList.application.children).find(
      (route, index) => {
        return route.match(targetPage) && this.lastStep >= index + 1;
      }
    );

    // handle the payment redirection from the third party payment page that sends back parameters
    // in this case we do NOT want to redirect the url
    if (
      (landingUrl.match(`/${routeList.application.children.payment}?`) &&
        this.lastStep >= 7) ||
      landingUrl.match(`/${routeList.complete}?`)
    ) {
      // return from payment
      return;
    } else if (!validTargetPage) {
      this.router.navigate([this.getLastStepRoute()]);
    }
    // only redirect the logged in users when they are NOT refreshing their current form page
    else if (localStorage.getItem('firstLogInDone')) {
      if (
        landingUrl.match(`^/${routeList.application.path}$`) ||
        landingUrl.match(`^${routeList.application.path}$`)
      ) {
        this.router.navigate([this.getLastStepRoute()]);
      }
    } else {
      // Set a key in local storage to see if this is the first time the user has logged in
      localStorage.setItem('firstLogInDone', 'true');
      // Move the user to the last Step they have completed using the API that keeps track of the steps
      // We do not want this behavior to happen after they have logged in and try to refresh the page
      this.router.navigate([this.getLastStepRoute()]);
    }
  }

  ngOnDestroy(): void {}
}
