import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import * as i0 from "@angular/core";
var ServerErrorInterceptor = /** @class */ (function () {
    function ServerErrorInterceptor() {
    }
    ServerErrorInterceptor.prototype.intercept = function (req, next) {
        return next.handle(req).pipe(retry(1), catchError(function (error) {
            return throwError(error);
        }));
    };
    ServerErrorInterceptor.ngInjectableDef = i0.defineInjectable({ factory: function ServerErrorInterceptor_Factory() { return new ServerErrorInterceptor(); }, token: ServerErrorInterceptor, providedIn: "root" });
    return ServerErrorInterceptor;
}());
export { ServerErrorInterceptor };
