import { createFeatureSelector, createSelector } from '@ngrx/store';
import { programChoiceAdapter } from '../states';
export var getError = function (state) { return state.error; };
export var getIsLoading = function (state) {
    return state.loading;
};
export var getIsSaving = function (state) {
    return state.saving;
};
var selectProgramChoices = createFeatureSelector('programChoice');
var selectAll = programChoiceAdapter.getSelectors(selectProgramChoices).selectAll;
export var selectProgramChoice = createSelector(selectAll, function (programChoiceApps) {
    if (programChoiceApps.length > 0) {
        return programChoiceApps[programChoiceApps.length - 1];
    }
    return null;
});
export var selectProgramChoiceReview = createSelector(selectAll, function (programChoiceApps) {
    if (programChoiceApps.length > 0 &&
        programChoiceApps[programChoiceApps.length - 1].choices.length > 0) {
        return programChoiceApps[programChoiceApps.length - 1].choices.map(function (_a, index) {
            var startDate = _a.startDate, campus = _a.campus, program = _a.program, description = _a.description, choiceNumber = _a.choiceNumber;
            return ({
                index: index + 1,
                startDate: startDateToTermConverter(startDate),
                program: program,
                description: description,
                campus: campus,
                choiceNumber: choiceNumber
            });
        });
    }
    return null;
});
var startDateToTermConverter = function (date) {
    var term;
    try {
        var month = date.getMonth();
        switch (true) {
            case month >= 0 && month < 4:
                term = 'Winter';
                break;
            case month >= 4 && month < 8:
                term = 'Summer';
                break;
            case month >= 8 && month < 12:
                term = 'Fall';
                break;
            default:
                term = 'Unidentified';
                break;
        }
        return term + " " + date.getFullYear();
    }
    catch (e) {
        throw Error('input is not in date format');
    }
};
var ɵ0 = startDateToTermConverter;
export var selectError = createSelector(selectProgramChoices, getError);
export var selectLoading = createSelector(selectProgramChoices, getIsLoading);
export var selectSaving = createSelector(selectProgramChoices, getIsSaving);
export { ɵ0 };
