import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of as observableOf, Subject } from 'rxjs';
import { Action } from '@ngrx/store';
import {
  AppLoadError,
  AppSubmitError,
  ExpiredAgentException,
  IAgentVerificationResult,
  InvalidAgentLoginException,
  InvalidUserInfoException,
  IUser
} from 'shared/models';
import { AuthService } from 'app/auth/auth.service';
import {
  AddNewUser,
  AddNewUserFail,
  AddNewUserSuccess,
  EAuthActions,
  VerifyAgentFail,
  VerifyAgentSuccess
} from '../actions/auth.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { commonEnv } from '../../../environments/environment.common';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthEffects implements OnDestroy {
  constructor(private actions$: Actions, private service: AuthService) {}

  destroyed$ = new Subject();

  @Effect()
  addUser$: Observable<Action> = this.actions$.pipe(
    ofType(EAuthActions.ADD_USER),
    map((action: AddNewUser) => {
      const { payload: agentId } = action;
      const msalUser = this.service.getUserInfo();
      if (!msalUser) throw new InvalidUserInfoException();
      if (msalUser.tfp === commonEnv.agentSignInPolicy && !agentId) {
        throw new InvalidAgentLoginException();
      }

      if (
        msalUser.tfp === commonEnv.applicantSignInPolicy &&
        localStorage.getItem('msal.login.request') ===
          `${environment.appUrl}/${commonEnv.azureAgentCallbackUrl}`
      )
        throw new InvalidAgentLoginException();
      const userInfo: IUser = {
        ...msalUser,
        agentId
      };
      return new AddNewUserSuccess(userInfo);
    }),
    catchError(err => {
      return observableOf(
        new AddNewUserFail(new AppLoadError(err.id, err.message))
      );
    })
  );

  @Effect()
  verifyAgent$: Observable<Action> = this.actions$.pipe(
    ofType(EAuthActions.VERIFY_AGENT),
    switchMap(() => {
      return this.service.verifyAgent().pipe(
        switchMap((verifyResult: IAgentVerificationResult) => {
          if (!verifyResult || Object.entries(verifyResult).length === 0) {
            throw new ExpiredAgentException();
          } else {
            return [
              new VerifyAgentSuccess(verifyResult),
              new AddNewUser(verifyResult.agentId)
            ];
          }
        }),
        catchError((err: HttpErrorResponse) => {
          const error = err instanceof HttpErrorResponse ? err.error : err;
          const displayError = error
            ? new AppLoadError(error.id, error.message)
            : new AppLoadError();
          return observableOf(new VerifyAgentFail(displayError));
        }),
        takeUntil(this.destroyed$)
      );
    })
  );

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }
}
