import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AgentApplicationService = /** @class */ (function () {
    function AgentApplicationService(http) {
        var _this = this;
        this.http = http;
        this.loadAgentApplications = function () {
            return _this.http.get(environment.apiUrl + "/getAgentApplications");
        };
    }
    AgentApplicationService.ngInjectableDef = i0.defineInjectable({ factory: function AgentApplicationService_Factory() { return new AgentApplicationService(i0.inject(i1.HttpClient)); }, token: AgentApplicationService, providedIn: "root" });
    return AgentApplicationService;
}());
export { AgentApplicationService };
