import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'app/auth/auth.service';
import { first, switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { IAuthStates } from 'app/store/states/auth.states';
import { applicantInfoSelectors, userSelectors } from 'app/store/selectors';
import { environment } from 'environments/environment';
import { fromPromise } from 'rxjs-compat/observable/fromPromise';

@Injectable({
  providedIn: 'root'
})
export class IapHttpInterceptorService implements HttpInterceptor {
  appId: string = null;

  constructor(
    private authService: AuthService,
    private store: Store<IAuthStates>
  ) {}

  private async handleAccess(
    request: HttpRequest<any>,
    next: HttpHandler,
    agentId: string,
    appId: string
  ): Promise<HttpEvent<any>> {
    const headerSettings: { [name: string]: string | string[] } = {};
    let token: string;
    // try to get the cache token before actually use the iFrame to request a new token
    // check for token expiry
    if (
      this.authService.getCacheAccessToken() &&
      Number(this.authService.getCacheAccessToken().expiresIn) >
        Math.floor(Date.now() / 1000)
    ) {
      token = this.authService.getCacheAccessToken().accessToken;
    } else {
      token = await this.authService.acquireToken();
    }
    // assign token to Authorization header
    if (token) {
      headerSettings['Authorization'] = 'Bearer ' + token;
    }

    // NOTE: this is not used anywhere, should be removed?
    if (environment.apiSubscriptionEnabled) {
      headerSettings['Ocp-Apim-Subscription-Key'] =
        environment.apiSubscriptionKey;
    }

    // some API need appId in the header to work properly
    if (appId) {
      headerSettings['appId'] = appId;
    }

    // some API need agentId in the header to work properly
    if (agentId) {
      headerSettings['agentId'] = this.authService.getUserInfo().emails[0];
    }

    return next
      .handle(
        request.clone({
          setHeaders: headerSettings
        })
      )
      .toPromise();
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // try to get specific data from the store and send them in the request header
    return this.store.select(userSelectors.selectAgentId).pipe(
      withLatestFrom(
        this.store.select(applicantInfoSelectors.selectApplicationID)
      ),
      first(),
      switchMap(([agentId, appId]) => {
        return fromPromise(this.handleAccess(req, next, agentId, appId));
      })
    );
  }
}
