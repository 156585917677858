import { createEntityAdapter } from '@ngrx/entity';
import { IBaseEntityState, IInfoRelease } from 'app/shared/models';

export interface InfoReleaseStates extends IBaseEntityState<IInfoRelease> {
  saving: boolean;
}

export const infoReleaseAdapter = createEntityAdapter<IInfoRelease>({
  selectId: (infoRelease: IInfoRelease) => infoRelease.id
});

export const infoReleaseInitialState: InfoReleaseStates = infoReleaseAdapter.getInitialState(
  {
    selectedId: null,
    loading: false,
    error: null,
    saving: false
  }
);
