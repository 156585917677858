import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  IAddress,
  IAppError,
  ICountry,
  ILanguage,
  IPersonalInfo,
  IPhone
} from 'app/shared/models';
import { personalInfoAdapter, PersonalInfoStates } from 'app/store/states';
import { selectAll as selectCountryList } from 'app/store/selectors/countryList.selectors';
import { selectAll as selectLanguageList } from 'app/store/selectors/languageList.selectors';
import { IPersonalInfoReview } from 'shared/models/reviewSubmit.models';
import { getSafe, isAnyPropEmpty } from 'app/utils';
import * as moment from 'moment';

const getError = (state: PersonalInfoStates): IAppError => state.error;

const getIsLoading = (state: PersonalInfoStates): boolean => state.loading;

const getIsSaving = (state: PersonalInfoStates): boolean => state.saving;

export const selectPersonalInfo = createFeatureSelector<PersonalInfoStates>(
  'personalInfo'
);

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = personalInfoAdapter.getSelectors(selectPersonalInfo);

export const selectById = (id: string) => {
  createSelector(
    selectAll,
    (data: IPersonalInfo[]) => {
      if (data.length > 0) {
        return data.find(info => info.appId === id);
      }
      return null;
    }
  );
};

export const selectCountryOfApplication = createSelector(
  selectAll,
  (data: IPersonalInfo[]) => {
    if (data.length > 0) {
      const result = data[data.length - 1];
      const CountryOfApplication = result.countryOA;
      return CountryOfApplication;
    }
  }
);

// Custom selector to format the 'applicant bio data' API data based on the personal-info form
export const selectFormatted = createSelector(
  selectAll,
  (list: IPersonalInfo[]) => {
    // variable holding the formatted data
    let formattedData = null;

    if (list.length > 0) {
      const data = list[list.length - 1];
      if (data) {
        formattedData = data;
      }
    }
    return formattedData;
  }
);

export const selectPersonalInfoReview = createSelector(
  selectAll,
  selectCountryList,
  selectLanguageList,
  (
    list: IPersonalInfo[],
    countryList: ICountry[],
    languageList: ILanguage[]
  ) => {
    if (list.length > 0 && countryList.length > 0 && languageList.length > 0) {
      const data = list[list.length - 1];
      return constructPersonalInfoReview(data, countryList, languageList);
    }
    return null;
  }
);

export const selectError = createSelector(
  selectPersonalInfo,
  getError
);

export const selectLoading = createSelector(
  selectPersonalInfo,
  getIsLoading
);

export const selectSaving = createSelector(
  selectPersonalInfo,
  getIsSaving
);

const constructAddress = (address: IAddress) => {
  if (!address) return null;
  const { stAddress, stAddress2, city, province, country, zipCode } = address;

  return [stAddress, stAddress2, city, province, country, zipCode]
    .filter(item => item)
    .join(', ');
};

const constructPhoneNumber = (phone: IPhone) => {
  if (!phone || isAnyPropEmpty(phone)) return `Not provided`;

  const { countryCode, number } = phone;

  return `(${countryCode}) ${number}`;
};

const constructGenderName = (gender: string) => {
  const genders = {
    m: 'Male',
    f: 'Female',
    x: 'Other gender',
    u: 'Unknown',
    default: 'Unknown'
  };
  return genders[gender] || genders['default'];
};

const constructStatus = (status: string) => {
  const statuses = {
    1: 'I do not require a study permit according to IRCC',
    2: 'I have not applied for my study permit',
    3: 'I applied for my study permit',
    4: 'I received my Letter of Introduction (upload your letter)',
    5: 'I received my Study Permit (upload your permit)'
  };
  return statuses[status] || statuses['default'];
}

/*
Shorthand text for review page
 */
const constructPal = (status: string) => {
  const statuses = {
    1: 'Study Permit Holder/Applicant',
    2: 'Work Permit Holder',
    3: 'Temporary Resident: Visiting/Exchange Student at DLI',
    4: 'Temporary Resident: Completed Prerequisite Program at DLI',
    5: 'Temporary Resident Permit Valid for at Least 6 Months',
    6: 'Protected Person',
    7: 'Eligible for Permanent Residence Confirmed by IRCC',
    8: 'Family Member of Foreign National in Canada',
    9: 'Unused Valid Provincial Attestation Letter Holder',
    10: 'PAL Required'
  };
  return statuses[status] || statuses['default'];
}

// clear the UCI number if the user selects the first two options since there is no UCI number
const constructUCI =(status:string, uci_number: string) => { 
  if (status =='1' || status=='2') {
    return null;
  }
  return uci_number;
};

const constructDate = (date: Date) =>{
  if (date != null) {
      return moment.utc(date).format('LL');
  } else {
    return null;
  }
}

const constructPersonalInfoReview = (
  info: IPersonalInfo,
  countryList: ICountry[],
  languageList: ILanguage[]
): IPersonalInfoReview => {
  const language = getSafe(
    () => languageList.find(lang => lang.code === info.primaryLang).name
  );
  const countryOA = getSafe(
    () => countryList.find(country => country.code === info.countryOA).name
  );
  const countryOB = getSafe(
    () => countryList.find(country => country.code === info.countryOB).name
  );
  const countryOC = getSafe(
    () => countryList.find(country => country.code === info.countryOC).name
  );

  const primaryAddressCountry = countryList.find(
    country => country.code === info.permanentAddress.country
  );
  const primaryAddressProvince =
    info.permanentAddress.province !== 'none' && primaryAddressCountry
      ? primaryAddressCountry.provinceList.find(
          province => province.code === info.permanentAddress.province
        ).name
      : 'none';
  const primaryAddress: IAddress = {
    ...info.permanentAddress,
    country: primaryAddressCountry.name,
    province: primaryAddressProvince as string
  };

  let secondaryAddress: IAddress = null;

  if (!info.currAddrSameAsPerm) {
    const secondaryAddressCountry = countryList.find(
      country => country.code === info.mailingAddress.country
    );

    const secondaryAddressProvince =
      info.permanentAddress.province !== 'none' && secondaryAddressCountry
        ? secondaryAddressCountry.provinceList.find(
            province => province.code === info.mailingAddress.province
          ).name
        : 'none';

    secondaryAddress = {
      ...info.mailingAddress,
      country: secondaryAddressCountry.name,
      province: secondaryAddressProvince as string
    };
  } else {
    secondaryAddress = {
      ...primaryAddress
    };
  }

  var fName = info.firstName;
  if ( info.firstName == null) {
    fName = '';
  }

  return {
    fullName: `${info.title}. ${fName} ${info.surName}`,
    gender: constructGenderName(info.gender),
    countryOfCitizenship: countryOC,
    countryOfApplication: countryOA,
    countryOfBirth: countryOB,
    primaryLanguage: language,
    permanentAddress: constructAddress(primaryAddress),
    phone: constructPhoneNumber(info.permanentAddress.phone),
    cell: constructPhoneNumber(info.permanentAddress.cell),
    email: info.email,
    currentAddress: constructAddress(secondaryAddress),
    status: constructStatus(info.status),
    uci_number: constructUCI(info.status, info.uci_number),   
    issue_date: constructDate(info.issue_date),   
    expiry_date: constructDate(info.expiry_date),
    status_pal: constructPal(info.status_pal),
    status_value: info.status // this data is junk, keep it last from showing on the screen
  };
};
