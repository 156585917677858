import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PersonalInfoService = /** @class */ (function () {
    function PersonalInfoService(http) {
        var _this = this;
        this.http = http;
        // function 'get's the applicant personal information from the API
        this.getPersonalInfo = function () {
            return _this.http.get(environment.apiUrl + "/getApplicantBioData", {});
        };
        // function 'post's the applicant personal information to the API
        this.postPersonalInfo = function (personalInfo) {
            return _this.http.post(environment.apiUrl + "/storeApplicantBioData", personalInfo);
        };
    }
    PersonalInfoService.ngInjectableDef = i0.defineInjectable({ factory: function PersonalInfoService_Factory() { return new PersonalInfoService(i0.inject(i1.HttpClient)); }, token: PersonalInfoService, providedIn: "root" });
    return PersonalInfoService;
}());
export { PersonalInfoService };
