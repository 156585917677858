import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

import { IAgentApplication } from 'shared/models/agentApplication.models';

@Injectable({
  providedIn: 'root'
})
export class AgentApplicationService {
  constructor(private http: HttpClient) {}

  loadAgentApplications = (): Observable<IAgentApplication[]> => {
    return this.http.get(
      `${environment.apiUrl}/getAgentApplications`
    ) as Observable<IAgentApplication[]>;
  };
}
