import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAppError } from 'app/shared/models';
import { countryListAdapter, CountryListStates } from 'app/store/states';

export const getError = (state: CountryListStates): IAppError => state.error;

export const getIsLoading = (state: CountryListStates): boolean =>
  state.loading;

export const selectCountryList = createFeatureSelector<CountryListStates>(
  'countryList'
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = countryListAdapter.getSelectors(selectCountryList);

export const selectError = createSelector(
  selectCountryList,
  getError
);

export const selectLoading = createSelector(
  selectCountryList,
  getIsLoading
);
