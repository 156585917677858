import { createEntityAdapter } from '@ngrx/entity';
import { IBaseEntityState, IUser } from 'shared/models';

export interface IAuthStates extends IBaseEntityState<IUser> {}

export const authAdapter = createEntityAdapter<IUser>({
  selectId: (user: IUser) => user.oid
});

export const authInitialState: IAuthStates = authAdapter.getInitialState({
  selectedId: null,
  loading: false,
  error: null
});
