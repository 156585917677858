import { IProgram } from 'shared/models/programList.models';

export interface IErrorSnackBar {
  error: IAppError;
  messageFactory?: Function;
  callback?: Function;
  actionName?: ESnackbarAction;
}

export interface IErrorBase {
  id: string;
  message: string;
}

export interface IAppError extends IErrorBase {
  type: string;
}

export enum EErrorType {
  LOAD = 'load',
  SUBMIT = 'submit'
}

export enum ESnackbarAction {
  REFRESH = 'Refresh',
  DISMISS = 'Dismiss',
  NAVIGATE = 'Go'
}

export class OpenSubmitErrorBar {
  constructor(
    public message: string = 'There was an issue submitting the form. Please try again',
    public callback: Function = null,
    readonly action = ESnackbarAction.DISMISS
  ) {}
}

export class OpenLoadErrorBar {
  constructor(
    public message: string = 'There was an issue loading the form. Please try again',
    public callback: Function = null,
    readonly action = ESnackbarAction.REFRESH
  ) {}
}

export class AppSubmitError implements IAppError {
  constructor(
    public id: string = 'AA32',
    public message: string = 'something went wrong, please try again later..',
    public readonly type = EErrorType.SUBMIT
  ) {}
}

export class AppLoadError implements IAppError {
  constructor(
    public id: string = 'AA32',
    public message: string = 'something went wrong, please try again later..',
    public readonly type = EErrorType.LOAD
  ) {}
}

export class InfoMissingError implements IErrorBase {
  constructor(
    public id: string = '1',
    public message: string = 'critical info is missing, please try again.'
  ) {}
}

export interface IPhone {
  countryCode: string;
  number: string;
}

export class PhoneNumber implements IPhone {
  constructor(public countryCode = '', public number = '') {}
}

export const englishLanguageInstitute: IProgram = {
  programCode: 'ELI',
  programDesc: 'English Language Institute',
  campus: 'NH',
  acadPlan: 'ELI',
  acadCareer: 'ESD',
  campusUrl: 'https://www.senecapolytechnic.ca/campuses/newnhamlocation.html',
  bundleEligible: 'N'
};

export const senecaLanguageCentre: IProgram = {
  programCode: 'ELI',
  campus: 'SIA',
  programDesc: 'English Language Institute',
  acadPlan: 'ELI',
  acadCareer: 'ESD',
  campusUrl:
    'https://www.senecapolytechnic.ca/campuses/seneca-international-academy.html',
  bundleEligible: 'N'
};

export const degreePrepProgram: IProgram = {
  programCode: 'DPP',
  programDesc: 'Degree Prep Program',
  campus: 'NH',
  acadPlan: 'DPP',
  acadCareer: 'ESD',
  campusUrl: 'https://www.senecapolytechnic.ca/campuses/newnhamlocation.html',
  bundleEligible: 'N'

}

export interface IDialogError {
  message: string;
  header?: string;
  actionName?: string;
  action?: Function;
}

// TODO: use this whenever a route is used instead of hardcoding routes
export const routeList = {
  application: {
    path: 'application',
    children: {
      personalInfo: 'personal-info',
      programList: 'program-availability',
      englishProf: 'english-proficiency',
      programChoice: 'program-choice',
      academicInfo: 'academic-info',
      documentUpload: 'file-up',
      infoRelease: 'info-release',
      review: 'review-submit',
      payment: 'payment'
    }
  },
  agent: {
    path: 'agent',
    children: {
      applicationList: 'application-list'
    }
  },
  complete: 'complete'
};

export const ApplicationStatusObject = {
  new: {
    value: 'new',
    text: 'Not Submitted'
  },
  paid: {
    value: 'paid',
    text: 'Submitted - Under Review'
  },
  complete: {
    value: 'complete',
    text: 'Completed'
  },
  unknown: {
    value: null,
    text: 'Submitted - Under Review'
  }
};
