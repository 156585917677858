import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IProgram, IProgramListReqParams } from 'shared/models';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProgramListService {
  constructor(private http: HttpClient) {}

  getProgramLists = (params: IProgramListReqParams): Observable<IProgram[]> => {
    if (params.year !== 0 && params.month !== 0) {
      return this.http.post(
        `${environment.apiUrl}/getAvailablePrograms`,
        params
      ) as Observable<IProgram[]>;
    } else {
      return this.http.get(
        `${environment.apiUrl}/getAllPrograms`
      ) as Observable<IProgram[]>;
    }
  };
}
