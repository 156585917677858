import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  IAppError,
  IPayment,
  IPaymentResponse,
  routeList
} from 'app/shared/models';
import { paymentAdapter, PaymentStates } from '../states';

export const getIsLoading = (state: PaymentStates): boolean => state.loading;

export const getError = (state: PaymentStates): IAppError | null => state.error;

export const getPaid = (state: PaymentStates): boolean => state.paid;

export const getPaymentResponse = (
  state: PaymentStates
): IPaymentResponse | null => state.paymentResponse;

export const getPromoError = (state: PaymentStates): IAppError | null =>
  state.promoError;

export const getPromoFormError = (state: PaymentStates): IAppError | null =>
  state.promoFormError;

export const getPromoValid = (state: PaymentStates): boolean =>
  state.promoValid;

export const getInitializePayment = (state: PaymentStates): boolean => 
  state.initializingPayment;

export const getTransactionTicket = (state: PaymentStates): string => 
  state.transactionTicket;

export const getCheckingPromoCode = (state: PaymentStates): boolean =>
  state.checkingPromoCode;

export const selectPayment = createFeatureSelector<PaymentStates>('payment');

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = paymentAdapter.getSelectors(selectPayment);

export const selectLatest = createSelector(
  selectAll,
  (list: IPayment[]) => {
    if (list.length > 0) {
      const data = list[list.length - 1];
      const { id, applicationFee } = data;
      return {
        id,
        applicationFee
      };
    }
    return null;
  }
);

export const selectError = createSelector(
  selectPayment,
  getError
);

export const selectLoading = createSelector(
  selectPayment,
  getIsLoading
);

export const selectPaid = createSelector(
  selectPayment,
  getPaid
);

export const selectPaymentResponse = createSelector(
  selectPayment,
  getPaymentResponse
);

export const selectPromoValid = createSelector(
  selectPayment,
  getPromoValid
);


export const selectInitalizePayment = createSelector(
  selectPayment,
  getInitializePayment
);

export const selectTransactionTicket = createSelector(
  selectPayment,
  getTransactionTicket
);

export const selectPromoError = createSelector(
  selectPayment,
  getPromoError
);

export const selectPromoFormError = createSelector(
  selectPayment,
  getPromoFormError
);

export const selectCheckingPromoCode = createSelector(
  selectPayment,
  getCheckingPromoCode
);
