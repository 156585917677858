import { Action } from '@ngrx/store';

export enum EReviewSubmitActions {
  VIEW_REVIEW_SUBMIT_SUCCESS = '[ReviewSubmit] VIEW REVIEW SUBMIT SUCCESS'
}

export class ViewReviewSubmitSuccess implements Action {
  public readonly type = EReviewSubmitActions.VIEW_REVIEW_SUBMIT_SUCCESS;

  constructor() {}
}

export type ReviewSubmitActions = ViewReviewSubmitSuccess;
