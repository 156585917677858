import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAppError, IfilesInStore } from 'app/shared/models';
import { uploadAdapter, UploadStates } from '../states';

export const getIsLoading = (state: UploadStates): boolean => state.loading;

export const getError = (state: UploadStates): IAppError | null => state.error;

export const getSaveToStore = (state: UploadStates): boolean =>
  state.saveToStore;

const getUnUploadedForms = (state: UploadStates): string[] =>
  state.unUploadedForms;

export const selectUpload = createFeatureSelector<UploadStates>('upload');

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = uploadAdapter.getSelectors(selectUpload);

export const selectLatest = createSelector(
  selectAll,
  (list: IfilesInStore[]) => {
    if (list.length > 0) {
      const data = list[list.length - 1];
      const { id, files } = data;
      return {
        id,
        files
      };
    }
    return null;
  }
);

export const selectUploadFilesReview = createSelector(
  selectAll,
  (list: IfilesInStore[]) => {
    if (list.length > 0) {
      return list[list.length - 1];
    }
    return [];
  }
);

export const selectUploadReview = createSelector(
  selectAll,
  (list: IfilesInStore[]) => {
    if (list.length > 0) {
      return list[0].files.reverse();
    }
    return null;
  }
);

export const selectUnUploadedForms = createSelector(
  selectUpload,
  getUnUploadedForms
);

export const selectLoading = createSelector(
  selectUpload,
  getIsLoading
);

export const selectError = createSelector(
  selectUpload,
  getError
);

export const selectSaveToStore = createSelector(
  selectUpload,
  getSaveToStore
);
