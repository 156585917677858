import { EUploadActions, UploadActions } from '../actions';
import { uploadAdapter, uploadInitialState, UploadStates } from '../states';

export function uploadReducer(
  state: UploadStates = uploadInitialState,
  action: UploadActions
): UploadStates {
  switch (action.type) {
    case EUploadActions.SAVE_TO_STORE:
      return uploadAdapter.upsertOne(action.payload, {
        ...state,
        loading: false,
        error: null,
        saveToStore: false
      });
    case EUploadActions.GET_FILES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case EUploadActions.GET_FILES_SUCCESS:
      return uploadAdapter.upsertOne(action.payload, {
        ...state,
        loading: false,
        error: null
      });
    case EUploadActions.GET_FILES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case EUploadActions.SUBMIT_FILES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case EUploadActions.SUBMIT_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        saveToStore: true
      };
    case EUploadActions.SUBMIT_FILES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case EUploadActions.SET_UNUPLOADED_FORM:
      return {
        ...state,
        //loading: true,
        error: null,
        unUploadedForms: [...state.unUploadedForms, action.payload]
      };
    case EUploadActions.RESET_UNUPLOADED_FORM:
      return {
        ...state,
        loading: false,
        error: null,
        unUploadedForms: uploadInitialState.unUploadedForms
      };

    default:
      return state;
  }
}
