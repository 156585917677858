import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var LanguageListService = /** @class */ (function () {
    function LanguageListService(http) {
        var _this = this;
        this.http = http;
        // function 'get's a list of International Languages from the API
        this.getLanguageList = function () {
            return _this.http
                .get(environment.apiUrl + "/getLanguageCodes")
                // Getting the array of languages from the QasData Container
                .pipe(map(function (response) { return response['QasData']; }));
        };
    }
    LanguageListService.ngInjectableDef = i0.defineInjectable({ factory: function LanguageListService_Factory() { return new LanguageListService(i0.inject(i1.HttpClient)); }, token: LanguageListService, providedIn: "root" });
    return LanguageListService;
}());
export { LanguageListService };
