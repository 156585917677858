import { AcademicInfoActions, EAcademicInfoActions } from '../actions';
import { academicInfoAdapter, academicInfoInitialState, IAcademicInfoStates } from '../states';

export function academicInfoReducers(
  state: IAcademicInfoStates = academicInfoInitialState,
  action: AcademicInfoActions
): IAcademicInfoStates {
  switch (action.type) {
    case EAcademicInfoActions.LOAD_ACADEMIC_INFO:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EAcademicInfoActions.LOAD_ACADEMIC_INFO_SUCCESS:
      return academicInfoAdapter.addOne(action.payload, {
        ...state,
        loading: false,
        error: null,
        selectedId: action.payload.id
      });
    case EAcademicInfoActions.LOAD_ACADEMIC_INFO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        selectedId: null
      };
    case EAcademicInfoActions.SUBMIT_ACADEMIC_INFO:
      return {
        ...state,
        saving: true,
        error: null
      };
    case EAcademicInfoActions.SUBMIT_ACADEMIC_INFO_SUCCESS:
      return academicInfoAdapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          saving: false,
          error: null
        }
      );

    case EAcademicInfoActions.SUBMIT_ACADEMIC_INFO_FAIL:
      return {
        ...state,
        saving: false,
        error: action.payload
      };
    default: {
      return state;
    }
  }
}
