import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ApplicantInfoService = /** @class */ (function () {
    function ApplicantInfoService(http) {
        var _this = this;
        this.http = http;
        // function 'get's the Applicant Info including appId and LastStep completed from the API
        this.getApplicantInfo = function (appId) {
            if (appId) {
                return _this.http.post(environment.apiUrl + "/getApplicationInfoAgent", appId !== '00000000000' ? { appId: appId } : { appId: null });
            }
            else {
                return _this.http.get(environment.apiUrl + "/getApplicationInfo");
            }
        };
    }
    ApplicantInfoService.prototype.checkIfApplicantProgramConfig = function (appId) {
        if (appId) {
            return this.http.get(environment.apiUrl + "/checkIfApplicantProgramConfig/?appId=" + appId);
        }
    };
    ApplicantInfoService.prototype.checkIfAESubmitted = function (appId) {
        if (appId) {
            return this.http.get(environment.apiUrl + "/checkIfAESubmitted/?appId=" + appId);
        }
    };
    ApplicantInfoService.prototype.setAutoEval = function (appId) {
        if (appId) {
            return this.http.post(environment.apiUrl + "/setAutoEval", { "Application_Id": appId });
        }
    };
    ApplicantInfoService.prototype.getGuid = function (appId) {
        if (appId) {
            return this.http.get(environment.apiUrl + "/getGuid?appId=" + appId);
        }
    };
    ApplicantInfoService.ngInjectableDef = i0.defineInjectable({ factory: function ApplicantInfoService_Factory() { return new ApplicantInfoService(i0.inject(i1.HttpClient)); }, token: ApplicantInfoService, providedIn: "root" });
    return ApplicantInfoService;
}());
export { ApplicantInfoService };
