import { Action } from '@ngrx/store';
import {
  IAppError,
  IProgramListItem,
  IProgramListReqParamPayload
} from 'shared/models';

export enum EProgramListActions {
  VIEW_PROGRAM_LIST_SUCCESS = '[ProgramList] VIEW PROGRAM LIST SUCCESS',
  LOAD_PROGRAMS = '[ProgramList] LOAD',
  LOAD_PROGRAM_SUCCESS = '[ProgramList] LOAD SUCCESS',
  LOAD_PROGRAM_FAIL = '[ProgramList] LOAD FAIL',
  RESET_PROGRAM_LIST = '[ProgramList] RESET'
}

export class LoadPrograms implements Action {
  public readonly type = EProgramListActions.LOAD_PROGRAMS;

  constructor(public payload: IProgramListReqParamPayload) {}
}

export class LoadProgramsSuccess implements Action {
  public readonly type = EProgramListActions.LOAD_PROGRAM_SUCCESS;

  constructor(public payload: IProgramListItem) {}
}

export class LoadProgramsFail implements Action {
  public readonly type = EProgramListActions.LOAD_PROGRAM_FAIL;

  constructor(public payload: IAppError) {}
}

export class ResetProgramList implements Action {
  public readonly type = EProgramListActions.RESET_PROGRAM_LIST;

  constructor() {}
}

export class ViewProgramListSuccess implements Action {
  public readonly type = EProgramListActions.VIEW_PROGRAM_LIST_SUCCESS;

  constructor() {}
}

export type ProgramListActions =
  | ViewProgramListSuccess
  | LoadPrograms
  | LoadProgramsSuccess
  | LoadProgramsFail
  | ResetProgramList;
