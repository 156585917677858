import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AppLoadError, AppSubmitError, IPayment, IPreloadData, IPreloadDataResponse } from 'app/shared/models';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PaymentService } from 'shared/services/api/payment.service';
import {
  CheckPromoCode,
  CheckPromoCodeSuccess,
  EPaymentActions,
  GetApplicationFee,
  GetApplicationFeeSuccess,
  GetPaymentReceipt,
  IncorrectPromoCode,
  InitializePayment,
  InitializePaymentSuccess
} from '../actions';

@Injectable()
export class PaymentEffects {
  constructor(
    private paymentService: PaymentService,
    private actions$: Actions
  ) {}
  @Effect()
  getApplicationFee$ = this.actions$.pipe(
    ofType(EPaymentActions.GET_APPLICATION_FEE),
    switchMap((action: GetApplicationFee) =>
      this.paymentService.getApplicationFee().pipe(
        map(applicationFeeData => {
          const newApplicationFee: IPayment = {
            applicationFee: applicationFeeData.applicationFee,
            id: action.payload
          };
          return new GetApplicationFeeSuccess(newApplicationFee);
        }),
        catchError((err: HttpErrorResponse) => {
          const error = err instanceof HttpErrorResponse ? err.error : err;
          const displayError = error
            ? new AppLoadError(error.id, error.message)
            : new AppLoadError();

          return observableOf(displayError);
        })
      )
    )
  );

  @Effect()
  initPaymentResp$ = this.actions$.pipe(
    ofType(EPaymentActions.INITIALIZE_PAYMENT),
    switchMap((actions: InitializePayment) => 
      this.paymentService.getPreloadData(actions.payload.studentNum, actions.payload.fee.toFixed(2)).pipe(
        map(data => {
          const response = {
            response: {
              success: data.response.success,
              ticket: data.response.ticket
            }
          }
          return new InitializePaymentSuccess(response);
        }),
        catchError((err: HttpErrorResponse) => {
          const error = err instanceof HttpErrorResponse ? err.error : err;
          const displayError = error 
          ? new AppLoadError(error.id, error.message)
          : new AppLoadError();

          return observableOf(displayError);
        })
      )
    )
  );

  @Effect()
  getPaymentReceipt$ = this.actions$.pipe(
    ofType(EPaymentActions.GET_PAYMENT_RECEIPT),
    switchMap((action: GetPaymentReceipt) => 
      this.paymentService.getPaymentReceipt(action.payload).pipe(
        map(data => {

        }),
        catchError((err: HttpErrorResponse) => {
          const error = err instanceof HttpErrorResponse ? err.error : err;
          const displayError = error 
          ? new AppLoadError(error.id, error.message)
          : new AppLoadError();

          return observableOf(displayError);
        })
      )

    )
  )

  @Effect()
  checkPromoCode$ = this.actions$.pipe(
    ofType(EPaymentActions.CHECK_PROMO_CODE),
    switchMap((action: CheckPromoCode) =>
      this.paymentService.checkPromoCode(action.payload).pipe(
        map(payment => {
          if (payment.message === 'success') {
            return new CheckPromoCodeSuccess(payment);
          } else {
            payment.message = 'Promo code is expired or limit has been reached.';
            return new IncorrectPromoCode(
              new AppSubmitError(null, payment.message)
            );
          }
        }),
        catchError((err: HttpErrorResponse) => {
          const error = err instanceof HttpErrorResponse ? err.error : err;
          const displayError = error
            ? new AppSubmitError(error.id, error.message)
            : new AppSubmitError();

          return observableOf(new IncorrectPromoCode(displayError));
        })
      )
    )
  );
}
