/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./msal.component";
import * as i2 from "@azure/msal-angular/dist/msal.service";
var styles_MsalComponent = [];
var RenderType_MsalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MsalComponent, data: {} });
export { RenderType_MsalComponent as RenderType_MsalComponent };
export function View_MsalComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_MsalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_MsalComponent_0, RenderType_MsalComponent)), i0.ɵdid(1, 49152, null, 0, i1.MsalComponent, [i2.MsalService], null, null)], null, null); }
var MsalComponentNgFactory = i0.ɵccf("app-root", i1.MsalComponent, View_MsalComponent_Host_0, {}, {}, []);
export { MsalComponentNgFactory as MsalComponentNgFactory };
