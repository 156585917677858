import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { commonEnv } from 'environments/environment.common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { ApplicationStatusObject, IUser, routeList } from 'shared/models';
import { Store } from '@ngrx/store';
import { applicantInfoSelectors } from 'app/store/selectors';
import { ApplicantInfoStates } from 'app/store/states';
import { ApplicationService } from 'app/application/application.service';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit, OnDestroy {
  constructor(
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<ApplicantInfoStates>,
    private applicationService: ApplicationService
  ) {
    this.user = this.authService.getUserInfo();
  }

  user: IUser;
  destroyed$ = new Subject();
  applicationId: string = null;
  applicationStatus: string;
  applicationStatusObject = ApplicationStatusObject;
  isAtHomeScreen = false;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      takeUntil(this.destroyed$)
    );

  ngOnInit() {
    this.store
      .select(applicantInfoSelectors.selectCurrentAppInfo)
      .pipe(
        takeUntil(this.destroyed$),
        tap(currentAppInfo => {
          if (currentAppInfo) {
            this.applicationId = currentAppInfo.appId;
            if (currentAppInfo.status) {
              Object.entries(this.applicationStatusObject).forEach(object => {
                if (object[1].value === currentAppInfo.status) {
                  this.applicationStatus = object[1].text;
                }
              });
            } else {
              this.applicationStatus = this.applicationStatusObject.new.text;
            }
          }
        })
      )
      .subscribe();

    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.isAtHomeScreen =
          val.urlAfterRedirects ===
          `/${routeList.agent.path}/${routeList.agent.children.applicationList}`;
      }
    });
  }

  onHomeClick = () => {
    const user = this.authService.getUserInfo();
    if (user) {
      user.tfp === commonEnv.agentSignInPolicy
        ? this.router.navigateByUrl(`/${routeList.agent.path}`)
        : this.router.navigateByUrl(
            `/${routeList.application.path}/${routeList.application.children.personalInfo}`
          );
    }
  };

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }

  onSignOutClick() {
    this.authService.logout();
  }
}
