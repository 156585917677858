import { ESharedActions } from '../actions';
import { ActionReducer } from '@ngrx/store';
import {
  academicInfoInitialState,
  applicantInfoInitialState,
  englishProficiencyInitialState,
  infoReleaseInitialState,
  paymentInitialState,
  personalInfoInitialState,
  programChoiceInitialState,
  uploadInitialState
} from '../states';

export function clearStore(reducer: ActionReducer<any>): ActionReducer<any> {
  return function(state, action) {
    if (action.type === ESharedActions.CLEAR_STORE) {
      return reducer(
        {
          ...state,
          user: state.user,
          applicantInfo: applicantInfoInitialState,
          personalInfo: personalInfoInitialState,
          englishProficiency: englishProficiencyInitialState,
          programChoice: programChoiceInitialState,
          academicInfo: academicInfoInitialState,
          upload: uploadInitialState,
          infoRelease: infoReleaseInitialState,
          payment: paymentInitialState
        },
        action
      );
    }

    return reducer(state, action);
  };
}
