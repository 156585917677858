import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { IApplicationFeeResponse, IPromoCodeSubmit, IPromoCodeSubmitResult, IPreloadDataResponse } from 'shared/models';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PaymentService = /** @class */ (function () {
    function PaymentService(http) {
        var _this = this;
        this.http = http;
        this.paymentUrl = environment.monerisServiceUrl;
        this.appName = environment.monerisAppName;
        this.getApplicationFee = function () {
            return _this.http.get(environment.apiUrl + "/getApplicationFee");
        };
        this.checkPromoCode = function (data) {
            return _this.http.post(environment.apiUrl + "/validatePromoCode", data);
        };
        this.checkAppCompleteBeforePayment = function () {
            return _this.http.get(environment.apiUrl + "/checkAppCompleteBeforePayment");
        };
    }
    // Send client url to moneris proxy
    PaymentService.prototype.sendClientInfo = function (clientURL) {
        return this.http.post(this.paymentUrl + "/moneris-checkout-web/sendClientInfo", clientURL);
    };
    // Moneris API created preload data to create payment request
    PaymentService.prototype.getPreloadData = function (studentId, fee) {
        return this.http.get(this.paymentUrl + ("/api/moneris_checkout_service/preloadCheckoutData/" + this.appName + "/" + studentId + "/" + fee.toFixed(2)));
    };
    // Gets the payment recipt from moneris API
    PaymentService.prototype.getPaymentReceipt = function (transTicket) {
        return this.http.get(this.paymentUrl + ("/api/moneris_checkout_service/getTransactionReceipt/" + this.appName + "/" + transTicket));
    };
    PaymentService.prototype.sendIwaPayment = function (data) {
        return this.http.post(environment.apiUrl + "/iwaPaymentResult", data);
    };
    PaymentService.ngInjectableDef = i0.defineInjectable({ factory: function PaymentService_Factory() { return new PaymentService(i0.inject(i1.HttpClient)); }, token: PaymentService, providedIn: "root" });
    return PaymentService;
}());
export { PaymentService };
