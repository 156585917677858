import { authAdapter, IAuthStates } from '../states/auth.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';
import { IUser, IAppError } from 'shared/models';

const getError = (state: IAuthStates): any => state.error;

const getIsLoading = (state: IAuthStates): boolean => state.loading;

const getSelectedId = (state: IAuthStates): string => {
  return state.selectedId;
};

const selectUser = createFeatureSelector<IAuthStates>('user');

const { selectAll, selectEntities } = authAdapter.getSelectors(selectUser);

export const selectUserInfo = createSelector(
  selectEntities,
  selectUser,
  (entities: Dictionary<IUser>, state: IAuthStates) => {
    const selectedId = getSelectedId(state);
    return selectedId === null ? null : entities[selectedId];
  }
);

export const selectAgentId = createSelector(
  selectEntities,
  selectUser,
  (entities: Dictionary<IUser>, state: IAuthStates) => {
    const selectedId = getSelectedId(state);
    return selectedId === null ? null : entities[selectedId].agentId;
  }
);

export const selectError = createSelector(
  selectUser,
  getError
);

export const selectLoading = createSelector(
  selectUser,
  getIsLoading
);
