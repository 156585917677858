import * as tslib_1 from "tslib";
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { personalInfoAdapter } from 'app/store/states';
import { selectAll as selectCountryList } from 'app/store/selectors/countryList.selectors';
import { selectAll as selectLanguageList } from 'app/store/selectors/languageList.selectors';
import { getSafe, isAnyPropEmpty } from 'app/utils';
import * as moment from 'moment';
var getError = function (state) { return state.error; };
var ɵ0 = getError;
var getIsLoading = function (state) { return state.loading; };
var ɵ1 = getIsLoading;
var getIsSaving = function (state) { return state.saving; };
var ɵ2 = getIsSaving;
export var selectPersonalInfo = createFeatureSelector('personalInfo');
var _a = personalInfoAdapter.getSelectors(selectPersonalInfo), selectIds = _a.selectIds, selectEntities = _a.selectEntities, selectAll = _a.selectAll, selectTotal = _a.selectTotal;
export var selectById = function (id) {
    createSelector(selectAll, function (data) {
        if (data.length > 0) {
            return data.find(function (info) { return info.appId === id; });
        }
        return null;
    });
};
export var selectCountryOfApplication = createSelector(selectAll, function (data) {
    if (data.length > 0) {
        var result = data[data.length - 1];
        var CountryOfApplication = result.countryOA;
        return CountryOfApplication;
    }
});
// Custom selector to format the 'applicant bio data' API data based on the personal-info form
export var selectFormatted = createSelector(selectAll, function (list) {
    // variable holding the formatted data
    var formattedData = null;
    if (list.length > 0) {
        var data = list[list.length - 1];
        if (data) {
            formattedData = data;
        }
    }
    return formattedData;
});
export var selectPersonalInfoReview = createSelector(selectAll, selectCountryList, selectLanguageList, function (list, countryList, languageList) {
    if (list.length > 0 && countryList.length > 0 && languageList.length > 0) {
        var data = list[list.length - 1];
        return constructPersonalInfoReview(data, countryList, languageList);
    }
    return null;
});
export var selectError = createSelector(selectPersonalInfo, getError);
export var selectLoading = createSelector(selectPersonalInfo, getIsLoading);
export var selectSaving = createSelector(selectPersonalInfo, getIsSaving);
var constructAddress = function (address) {
    if (!address)
        return null;
    var stAddress = address.stAddress, stAddress2 = address.stAddress2, city = address.city, province = address.province, country = address.country, zipCode = address.zipCode;
    return [stAddress, stAddress2, city, province, country, zipCode]
        .filter(function (item) { return item; })
        .join(', ');
};
var ɵ3 = constructAddress;
var constructPhoneNumber = function (phone) {
    if (!phone || isAnyPropEmpty(phone))
        return "Not provided";
    var countryCode = phone.countryCode, number = phone.number;
    return "(" + countryCode + ") " + number;
};
var ɵ4 = constructPhoneNumber;
var constructGenderName = function (gender) {
    var genders = {
        m: 'Male',
        f: 'Female',
        x: 'Other gender',
        u: 'Unknown',
        default: 'Unknown'
    };
    return genders[gender] || genders['default'];
};
var ɵ5 = constructGenderName;
var constructStatus = function (status) {
    var statuses = {
        1: 'I do not require a study permit according to IRCC',
        2: 'I have not applied for my study permit',
        3: 'I applied for my study permit',
        4: 'I received my Letter of Introduction (upload your letter)',
        5: 'I received my Study Permit (upload your permit)'
    };
    return statuses[status] || statuses['default'];
};
var ɵ6 = constructStatus;
/*
Shorthand text for review page
 */
var constructPal = function (status) {
    var statuses = {
        1: 'Study Permit Holder/Applicant',
        2: 'Work Permit Holder',
        3: 'Temporary Resident: Visiting/Exchange Student at DLI',
        4: 'Temporary Resident: Completed Prerequisite Program at DLI',
        5: 'Temporary Resident Permit Valid for at Least 6 Months',
        6: 'Protected Person',
        7: 'Eligible for Permanent Residence Confirmed by IRCC',
        8: 'Family Member of Foreign National in Canada',
        9: 'Unused Valid Provincial Attestation Letter Holder',
        10: 'PAL Required'
    };
    return statuses[status] || statuses['default'];
};
var ɵ7 = constructPal;
// clear the UCI number if the user selects the first two options since there is no UCI number
var constructUCI = function (status, uci_number) {
    if (status == '1' || status == '2') {
        return null;
    }
    return uci_number;
};
var ɵ8 = constructUCI;
var constructDate = function (date) {
    if (date != null) {
        return moment.utc(date).format('LL');
    }
    else {
        return null;
    }
};
var ɵ9 = constructDate;
var constructPersonalInfoReview = function (info, countryList, languageList) {
    var language = getSafe(function () { return languageList.find(function (lang) { return lang.code === info.primaryLang; }).name; });
    var countryOA = getSafe(function () { return countryList.find(function (country) { return country.code === info.countryOA; }).name; });
    var countryOB = getSafe(function () { return countryList.find(function (country) { return country.code === info.countryOB; }).name; });
    var countryOC = getSafe(function () { return countryList.find(function (country) { return country.code === info.countryOC; }).name; });
    var primaryAddressCountry = countryList.find(function (country) { return country.code === info.permanentAddress.country; });
    var primaryAddressProvince = info.permanentAddress.province !== 'none' && primaryAddressCountry
        ? primaryAddressCountry.provinceList.find(function (province) { return province.code === info.permanentAddress.province; }).name
        : 'none';
    var primaryAddress = tslib_1.__assign({}, info.permanentAddress, { country: primaryAddressCountry.name, province: primaryAddressProvince });
    var secondaryAddress = null;
    if (!info.currAddrSameAsPerm) {
        var secondaryAddressCountry = countryList.find(function (country) { return country.code === info.mailingAddress.country; });
        var secondaryAddressProvince = info.permanentAddress.province !== 'none' && secondaryAddressCountry
            ? secondaryAddressCountry.provinceList.find(function (province) { return province.code === info.mailingAddress.province; }).name
            : 'none';
        secondaryAddress = tslib_1.__assign({}, info.mailingAddress, { country: secondaryAddressCountry.name, province: secondaryAddressProvince });
    }
    else {
        secondaryAddress = tslib_1.__assign({}, primaryAddress);
    }
    var fName = info.firstName;
    if (info.firstName == null) {
        fName = '';
    }
    return {
        fullName: info.title + ". " + fName + " " + info.surName,
        gender: constructGenderName(info.gender),
        countryOfCitizenship: countryOC,
        countryOfApplication: countryOA,
        countryOfBirth: countryOB,
        primaryLanguage: language,
        permanentAddress: constructAddress(primaryAddress),
        phone: constructPhoneNumber(info.permanentAddress.phone),
        cell: constructPhoneNumber(info.permanentAddress.cell),
        email: info.email,
        currentAddress: constructAddress(secondaryAddress),
        status: constructStatus(info.status),
        uci_number: constructUCI(info.status, info.uci_number),
        issue_date: constructDate(info.issue_date),
        expiry_date: constructDate(info.expiry_date),
        status_pal: constructPal(info.status_pal),
        status_value: info.status // this data is junk, keep it last from showing on the screen
    };
};
var ɵ10 = constructPersonalInfoReview;
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10 };
