import { Injectable, OnDestroy } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf, Subject } from 'rxjs';
import {
  EAgentApplicationActions,
  LoadAgentApplicationsFail,
  LoadAgentApplicationsSuccess
} from '../actions';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { IAgentApplication } from 'shared/models/agentApplication.models';
import { HttpErrorResponse } from '@angular/common/http';
import { AppLoadError } from 'shared/models';
import { AgentApplicationService } from 'shared/services/api/agent-application.service';

@Injectable()
export class AgentApplicationEffects implements OnDestroy {
  constructor(
    private actions$: Actions,
    private service: AgentApplicationService
  ) {}

  destroyed$ = new Subject();

  @Effect()
  getAgentApplications$: Observable<Action> = this.actions$.pipe(
    ofType(EAgentApplicationActions.LOAD_AGENT_APPLICATIONS),
    switchMap(() => {
      return this.service.loadAgentApplications().pipe(
        map((agentApplications: IAgentApplication[]) => {
          return new LoadAgentApplicationsSuccess(agentApplications);
        }),
        catchError((err: HttpErrorResponse) => {
          const error = err instanceof HttpErrorResponse ? err.error : err;
          const displayError = error
            ? new AppLoadError(error.id, error.message)
            : new AppLoadError();
          return observableOf(new LoadAgentApplicationsFail(displayError));
        }),
        takeUntil(this.destroyed$)
      );
    })
  );

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }
}
