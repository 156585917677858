import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ProgramChoiceService = /** @class */ (function () {
    function ProgramChoiceService(http) {
        var _this = this;
        this.http = http;
        this.getProgramChoices = function () {
            return _this.http.get(environment.apiUrl + "/getProgramChoice");
        };
        this.postProgramChoices = function (programChoices) {
            return _this.http.post(environment.apiUrl + "/AddProgramChoice", programChoices);
        };
    }
    ProgramChoiceService.ngInjectableDef = i0.defineInjectable({ factory: function ProgramChoiceService_Factory() { return new ProgramChoiceService(i0.inject(i1.HttpClient)); }, token: ProgramChoiceService, providedIn: "root" });
    return ProgramChoiceService;
}());
export { ProgramChoiceService };
