import * as tslib_1 from "tslib";
import { MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@azure/msal-angular/dist/msal.service";
import * as i2 from "@angular/common/http";
var AuthService = /** @class */ (function () {
    function AuthService(msalService, http) {
        var _this = this;
        this.msalService = msalService;
        this.http = http;
        this.setUserType = function (user_type) {
            _this.userType = user_type;
        };
        this.getUserType = function () {
            return _this.userType;
        };
        this.acquireToken = function () {
            return _this.msalService.acquireTokenSilent(environment.scopes);
        };
        this.isAuthenticated = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, !!this.getUserInfo()];
            });
        }); };
        this.login = function () {
            // console.log('INSIDE authService.login()');
            // console.log('user type is: ' + this.userType);
            // console.log('login(): is Login in process', this.msalService.loginInProgress());
            if (!_this.msalService.loginInProgress()) {
                return _this.msalService.loginRedirect(environment.scopes, _this.userType + ';LOGIN');
            }
            else if (_this.msalService.loginInProgress() && _this.getUserType().includes('AGENT')) {
                _this.loginAfterNoSession();
            }
        };
        this.loginAfterNoSession = function () {
            // return this.msalService.loginRedirect(environment.scopes);
            // console.log('INSIDE authService.loginAfterNoSession()');
            // console.log('user type is: ' + this.userType);
            return _this.msalService.loginRedirect(environment.scopes, _this.userType + ';ERROR');
        };
        this.logout = function () {
            localStorage.removeItem('firstLogInDone');
            return _this.msalService.logout();
        };
        this.getUserInfo = function () {
            var userData = _this.msalService.getUser();
            // console.log('get user info');
            // console.log(userData);
            if (userData) {
                var user_1 = userData.idToken;
                if (environment.ssoEnabled) {
                    var rolesOnly_1 = [];
                    var permissionsOnly_1 = [];
                    if (user_1.roles) {
                        if (user_1.roles.length > 0) {
                            user_1.roles.split(", ").forEach(function (role) {
                                var roleSplitArray = role.split("-");
                                if (roleSplitArray[0]) {
                                    var roleOnly = roleSplitArray[0].toString();
                                    if (!rolesOnly_1.includes(roleOnly)) {
                                        rolesOnly_1.push(roleOnly);
                                    }
                                    user_1.rolesOnly = rolesOnly_1;
                                }
                                if (roleSplitArray[1]) {
                                    var permissionOnly = roleSplitArray[1].toString();
                                    if (!permissionsOnly_1.includes(permissionOnly)) {
                                        permissionsOnly_1.push(permissionOnly);
                                    }
                                }
                                user_1.permissionsOnly = permissionsOnly_1;
                            });
                        }
                    }
                }
            }
            return userData ? userData.idToken : null;
        };
        this.hasRole = function () {
            var isAllowed = false;
            var userInfo = _this.getUserInfo();
            userInfo.roles.split(", ").forEach(function (role) {
                var roleSplitArray = role.split("-");
                if (roleSplitArray.length > 0) {
                    for (var i = 0; i < roleSplitArray.length; i++) {
                        if (roleSplitArray[i] === "Agent" || roleSplitArray[i] == "Agency_Admin") {
                            isAllowed = true;
                        }
                    }
                }
            });
            return isAllowed;
        };
        this.verifyAgent = function () {
            return _this.http.get(environment.apiUrl + "/verifyAgent");
        };
        // TODO: the current get cache access token implemented by MS does not work, remove this func in the future
        this.getCacheAccessToken = function () {
            var key = {
                authority: _this.msalService.authority,
                clientId: environment.clientId,
                scopes: environment.scopes[0],
                userIdentifier: _this.msalService.getUser().userIdentifier
            };
            var storageValue = localStorage.getItem(JSON.stringify(key));
            return storageValue ? JSON.parse(storageValue) : null;
        };
    }
    AuthService.prototype.getRolesTestCase = function (testCase) {
        var roles = "";
        switch (testCase) {
            case 1:
                //Intl_Admin - ReadWrite access
                roles = environment.roles_case_1;
                break;
            case 2:
                //Intl_User - ReadOnly access
                roles = environment.roles_case_2;
                break;
            // case 3:
            //   //IA_User - Access denied
            //   roles = environment.roles_case_3;
            //   break;
        }
        return roles;
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.MsalService), i0.inject(i2.HttpClient)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
