import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CountryListService = /** @class */ (function () {
    function CountryListService(http) {
        var _this = this;
        this.http = http;
        // function 'get's a list of International Countries from the API
        this.getCountryList = function () {
            return _this.http
                .get(environment.apiUrl + "/getProvincesStates")
                // Getting the array of countries from the CountriesList Container
                .pipe(map(function (response) { return response['countriesList']; }));
        };
    }
    CountryListService.ngInjectableDef = i0.defineInjectable({ factory: function CountryListService_Factory() { return new CountryListService(i0.inject(i1.HttpClient)); }, token: CountryListService, providedIn: "root" });
    return CountryListService;
}());
export { CountryListService };
