import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ILanguage } from 'shared/models';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class LanguageListService {
  constructor(private http: HttpClient) {}

  // function 'get's a list of International Languages from the API
  getLanguageList = (): Observable<ILanguage[]> => {
    return this.http
      .get(`${environment.apiUrl}/getLanguageCodes`)
      // Getting the array of languages from the QasData Container
      .pipe(map(response => response['QasData'])) as Observable<ILanguage[]>;
  };
}
