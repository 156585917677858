import { Action } from '@ngrx/store';
import { IAcademicInfo, IAppError } from 'shared/models';

export enum EAcademicInfoActions {
  LOAD_ACADEMIC_INFO = '[AcademicInfo] LOAD',
  LOAD_ACADEMIC_INFO_SUCCESS = '[AcademicInfo] LOAD SUCCESS',
  LOAD_ACADEMIC_INFO_FAIL = '[AcademicInfo] LOAD FAIL',
  SUBMIT_ACADEMIC_INFO = '[AcademicInfo] SUBMIT',
  SUBMIT_ACADEMIC_INFO_SUCCESS = '[AcademicInfo] SUBMIT SUCCESS',
  SUBMIT_ACADEMIC_INFO_FAIL = '[AcademicInfo] SUBMIT FAIL'
}

export class LoadAcademicInfo implements Action {
  public readonly type = EAcademicInfoActions.LOAD_ACADEMIC_INFO;

  constructor() {}
}

export class LoadAcademicInfoSuccess implements Action {
  public readonly type = EAcademicInfoActions.LOAD_ACADEMIC_INFO_SUCCESS;

  constructor(public payload: IAcademicInfo) {}
}

export class LoadAcademicInfoFail implements Action {
  public readonly type = EAcademicInfoActions.LOAD_ACADEMIC_INFO_FAIL;

  constructor(public payload: IAppError) {}
}

export class SubmitAcademicInfo implements Action {
  public readonly type = EAcademicInfoActions.SUBMIT_ACADEMIC_INFO;

  constructor(public payload: IAcademicInfo) {}
}

export class SubmitAcademicInfoSuccess implements Action {
  public readonly type = EAcademicInfoActions.SUBMIT_ACADEMIC_INFO_SUCCESS;

  constructor(public payload: IAcademicInfo) {}
}

export class SubmitAcademicInfoFail implements Action {
  public readonly type = EAcademicInfoActions.SUBMIT_ACADEMIC_INFO_FAIL;

  constructor(public payload: IAppError) {}
}

export type AcademicInfoActions =
  | LoadAcademicInfo
  | LoadAcademicInfoSuccess
  | LoadAcademicInfoFail
  | SubmitAcademicInfo
  | SubmitAcademicInfoSuccess
  | SubmitAcademicInfoFail;
