<div>
  <h2 mat-dialog-title>Need help? Contact us!</h2>
  <mat-divider></mat-divider>
  <mat-dialog-content>
    <mat-list>
      <mat-list-item>
        <mat-icon mat-list-icon>meeting_room</mat-icon>
        <h4 mat-line>International Admissions Office</h4>
      </mat-list-item>
      <mat-divider></mat-divider>

      <mat-list-item>
        <mat-icon mat-list-icon>phone</mat-icon>
        <h4 mat-line>+1 (416) 764-9900</h4>
      </mat-list-item>
      <mat-divider></mat-divider>

      <mat-list-item>
        <mat-icon mat-list-icon>local_printshop</mat-icon>
        <h4 mat-line>+1 (416) 492-9243</h4>
      </mat-list-item>
      <mat-divider></mat-divider>

      <mat-list-item>
        <mat-icon mat-list-icon>email</mat-icon>
        <h4 mat-line>theservicehub@senecapolytechnic.ca</h4>
      </mat-list-item>
      <mat-divider></mat-divider>

      <mat-list-item>
        <mat-icon mat-list-icon>warning</mat-icon>
        <h4 mat-line>
          <b>
          Please provide application confirmation number <br/> when
          contacting us.
          </b>
        </h4>
      </mat-list-item>
    </mat-list>
  </mat-dialog-content>
</div>
