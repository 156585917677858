import { createEntityAdapter } from '@ngrx/entity';
import { IAcademicInfo, IBaseEntityState } from 'shared/models';

export interface IAcademicInfoStates extends IBaseEntityState<IAcademicInfo> {
  saving: boolean;
}

export const academicInfoAdapter = createEntityAdapter<IAcademicInfo>({
  selectId: (academicInfo: IAcademicInfo) => academicInfo.id
});

export const academicInfoInitialState: IAcademicInfoStates = academicInfoAdapter.getInitialState(
  {
    selectedId: null,
    loading: false,
    error: null,
    saving: false,
    isValid: false
  }
);
