import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { PersonalInfoService } from 'shared/services/api/personal-info.service';
import {
  EPersonalInfoActions,
  LoadApplicantInfo,
  LoadPersonalInfoSuccess,
  SubmitPersonalInfo,
  SubmitPersonalInfoFail,
  SubmitPersonalInfoSuccess,
  LoadPersonalInfoFail
} from 'app/store/actions';
import { Observable, of as observableOf, Subject } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';
import { AppLoadError, AppSubmitError, PersonalInfo } from 'shared/models';
import { commonEnv } from 'environments/environment.common';
import { AuthService } from 'app/auth/auth.service';
import { AppState } from 'app/store/reducers';
import { applicantInfoSelectors, personalInfoSelectors } from '../selectors';

@Injectable()
export class PersonalInfoEffects implements OnDestroy {
  constructor(
    private actions$: Actions,
    private service: PersonalInfoService,
    private store: Store<AppState>,
    private authService: AuthService
  ) {}

  destroyed$ = new Subject();

  @Effect()
  getPersonalInfo$: Observable<Action> = this.actions$.pipe(
    ofType(EPersonalInfoActions.LOAD_PERSONAL_INFO),
    withLatestFrom(this.store.select(personalInfoSelectors.selectFormatted)),
    switchMap(([action, stored]) => {
      return stored
        ? observableOf(new LoadPersonalInfoSuccess(stored))
        : this.service.getPersonalInfo().pipe(
            withLatestFrom(
              this.store.select(applicantInfoSelectors.selectApplicationID)
            ),
            map(([personalInfo, appId]) => {
              if (!personalInfo || Object.entries(personalInfo).length === 0) {
                // creating an empty personal info object for new applicants
                personalInfo = new PersonalInfo();
              }
              if (appId) {
                personalInfo.appId = appId;
              }
              return new LoadPersonalInfoSuccess(personalInfo);
            }),
            catchError((err: HttpErrorResponse) => {
              const error = err instanceof HttpErrorResponse ? err.error : err;
              const displayError = error
                ? new AppLoadError(error.id, error.message)
                : new AppLoadError();
              return observableOf(new LoadPersonalInfoFail(displayError));
            })
          );
    }),
    takeUntil(this.destroyed$)
  );

  @Effect()
  postPersonalInfo$: Observable<Action> = this.actions$.pipe(
    ofType(EPersonalInfoActions.SUBMIT_PERSONAL_INFO),
    switchMap((action: SubmitPersonalInfo) =>
      this.service.postPersonalInfo(action.payload).pipe(
        takeUntil(this.destroyed$),
        map(applicantInfo => {
          const appId = applicantInfo.appId;
          if (
            this.authService.getUserInfo().tfp === commonEnv.agentSignInPolicy
          ) {
            this.store.dispatch(new LoadApplicantInfo(appId));
          } else {
            this.store.dispatch(new LoadApplicantInfo());
          }
          // TODO: catch an error if the post API doesn't return anything aka no payload here
          // returning applicantInfo in the personal info submit success response from the back-end
          return new SubmitPersonalInfoSuccess(action.payload);
        }),
        catchError((err: HttpErrorResponse) => {
          const error = err instanceof HttpErrorResponse ? err.error : err;
          const displayError = error
            ? new AppSubmitError(error.id, error.message)
            : new AppSubmitError();
          return observableOf(new SubmitPersonalInfoFail(displayError));
        })
      )
    )
  );

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }
}
