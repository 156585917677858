import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { IPersonalInfo } from 'shared/models';
@Injectable({
  providedIn: 'root'
})
export class PersonalInfoService {
  constructor(private http: HttpClient) {}

  // function 'get's the applicant personal information from the API
  getPersonalInfo = (): Observable<IPersonalInfo> => {
    return this.http.get(
      `${environment.apiUrl}/getApplicantBioData`,
      {}
    ) as Observable<IPersonalInfo>;
  };
  // function 'post's the applicant personal information to the API
  postPersonalInfo = (
    personalInfo: IPersonalInfo
  ): Observable<IPersonalInfo> => {
    return this.http.post(
      `${environment.apiUrl}/storeApplicantBioData`,
      personalInfo
    ) as Observable<IPersonalInfo>;
  };
}
