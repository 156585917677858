import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAppError, IApplicantInfo } from 'app/shared/models';
import { applicantInfoAdapter, ApplicantInfoStates } from '../states';

export const getError = (state: ApplicantInfoStates): IAppError => state.error;

export const getIsLoading = (state: ApplicantInfoStates): boolean =>
  state.loading;

export const selectApplicantInfo = createFeatureSelector<ApplicantInfoStates>(
  'applicantInfo'
);

const { selectAll } = applicantInfoAdapter.getSelectors(selectApplicantInfo);

// Custom selector for the application ID
export const selectApplicationID = createSelector(
  selectAll,
  (data: IApplicantInfo[]) => {
    if (data.length > 0) {
      const result = data[data.length - 1];
      const applicationId = result.appId;
      return applicationId;
    }
  }
);

// Custom selector for the last step completed by the user
export const selectlastStep = createSelector(
  selectAll,
  (data: IApplicantInfo[]) => {
    if (data.length > 0) {
      const result = data[data.length - 1];
      const lastStep = result.lastStep;
      return lastStep;
    }
  }
);

export const selectCurrentAppInfo = createSelector(
  selectAll,
  (data: IApplicantInfo[]) => {
    if (data.length > 0) {
      return data[data.length - 1];
    }
  }
);

export const selectError = createSelector(
  selectApplicantInfo,
  getError
);

export const selectLoading = createSelector(
  selectApplicantInfo,
  getIsLoading
);
