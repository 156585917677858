import { Injectable, NgZone, OnDestroy } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MatSnackBar,
  MatSnackBarRef,
  SimpleSnackBar
} from '@angular/material';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, Effect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ApplicationService } from 'app/application/application.service';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { EErrorType, ESnackbarAction, routeList } from 'shared/models';
import {
  CloseErrorMessageOnRouteChange,
  DisplayErrorDialog,
  DisplayErrorMessage,
  EAcademicInfoActions,
  EEnglishProficiencyActions,
  EInfoReleaseActions,
  EMessageActions,
  EPaymentActions,
  EPersonalInfoActions,
  EProgramChoiceActions,
  EProgramListActions,
  EReviewSubmitActions,
  EUploadActions,
  ResetUnUploadedForm
} from '../actions';
import { ErrorDialogComponent } from 'shared/components/error-dialog/error-dialog.component';

@Injectable()
export class SharedEffects implements OnDestroy, OnInitEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private snackBar: MatSnackBar,
    private applicationService: ApplicationService,
    private ngZone: NgZone,
    public dialog: MatDialog
  ) {}
  snackbarRef: MatSnackBarRef<SimpleSnackBar> = null;
  destroyed$ = new Subject();
  dialogRef: MatDialogRef<ErrorDialogComponent>;
  @Effect({ dispatch: false })
  navigateAfterSubmit$: Observable<Action> = this.actions$.pipe(
    takeUntil(this.destroyed$),
    ofType(
      EPaymentActions.PAYMENT_SUCCESS,
      EReviewSubmitActions.VIEW_REVIEW_SUBMIT_SUCCESS,
      EInfoReleaseActions.SUBMIT_INFO_RELEASE_SUCCESS,
      EUploadActions.SAVE_TO_STORE,
      EAcademicInfoActions.SUBMIT_ACADEMIC_INFO_SUCCESS,
      EProgramChoiceActions.SUBMIT_PROGRAM_CHOICES_SUCCESS,
      EEnglishProficiencyActions.SUBMIT_ENGLISH_PROFICIENCY_SUCCESS,
      EProgramListActions.VIEW_PROGRAM_LIST_SUCCESS,
      EPersonalInfoActions.SUBMIT_PERSONAL_INFO_SUCCESS,
      EPaymentActions.CHECK_PROMO_CODE_SUCCESS
    ),
    tap((action: Action) => {
      let completedStepName: string;
      switch (action.type) {
        case EPersonalInfoActions.SUBMIT_PERSONAL_INFO_SUCCESS:
          completedStepName = `${routeList.application.children.personalInfo}`;
          break;

        case EProgramListActions.VIEW_PROGRAM_LIST_SUCCESS:
          completedStepName = `${routeList.application.children.programList}`;
          break;

        case EEnglishProficiencyActions.SUBMIT_ENGLISH_PROFICIENCY_SUCCESS:
          completedStepName = `${routeList.application.children.englishProf}`;
          break;

        case EProgramChoiceActions.SUBMIT_PROGRAM_CHOICES_SUCCESS:
          completedStepName = `${routeList.application.children.programChoice}`;
          break;

        case EAcademicInfoActions.SUBMIT_ACADEMIC_INFO_SUCCESS:
          completedStepName = `${routeList.application.children.academicInfo}`;
          break;

        case EUploadActions.SAVE_TO_STORE:
          completedStepName = `${routeList.application.children.documentUpload}`;
          break;

        case EInfoReleaseActions.SUBMIT_INFO_RELEASE_SUCCESS:
          completedStepName = `${routeList.application.children.infoRelease}`;
          break;

        case EReviewSubmitActions.VIEW_REVIEW_SUBMIT_SUCCESS:
          completedStepName = `${routeList.application.children.review}`;
          break;

        case EPaymentActions.PAYMENT_SUCCESS:
          completedStepName = `${routeList.application.children.payment}`;
          break;

        case EPaymentActions.CHECK_PROMO_CODE_SUCCESS:
          completedStepName = `${routeList.application.children.payment}`;
          break;

        default:
          break;
      }
      this.applicationService.toggleComplete(completedStepName);
      if (
        action.type !== EReviewSubmitActions.VIEW_REVIEW_SUBMIT_SUCCESS &&
        action.type !== EPaymentActions.PAYMENT_SUCCESS &&
        this.applicationService.lastStep >= 8
      ) {
        this.applicationService.lastStep = 7;
      }
    })
  );

  @Effect({ dispatch: false })
  openSnackbarError$: Observable<Action> = this.actions$.pipe(
    ofType(EMessageActions.DISPLAY_ERROR_MESSAGE),
    tap((messageAction: DisplayErrorMessage) => {
      const {
        error,
        callback,
        messageFactory,
        actionName
      } = messageAction.payload;
      const { type } = error;
      const message = messageFactory ? messageFactory(error) : error.message;
      let action: string;
      action = actionName
        ? actionName
        : type === EErrorType.LOAD
        ? ESnackbarAction.REFRESH
        : ESnackbarAction.DISMISS;
      this.ngZone.run(() => {
        this.snackbarRef = this.snackBar.open(message, action, {
          duration: action === ESnackbarAction.REFRESH ? null : 15000,
          panelClass: 'error-snackbar'
        });
        // this action will be fired whenever the action button is clicked
        this.snackbarRef.onAction().subscribe(() => {
          // close the snackbar and do whatever callback is passed
          if (callback) {
            callback();
          }
        });
      });
    }),
    takeUntil(this.destroyed$)
  );

  @Effect({ dispatch: false })
  closeSnackbarOnRouteChange$: Observable<Action> = this.actions$.pipe(
    ofType(EMessageActions.CLOSE_ERROR_MESSAGE_ON_ROUTE_CHANGE),
    tap(() => {
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          takeUntil(this.destroyed$)
        )
        .subscribe((event: NavigationEnd) => {
          this.snackBar.dismiss();
        });
    }),
    takeUntil(this.destroyed$)
  );

  @Effect({ dispatch: false })
  openErrorDialog: Observable<Action> = this.actions$.pipe(
    ofType(EMessageActions.DISPLAY_ERROR_DIALOG),
    tap((action: DisplayErrorDialog) => {
      this.ngZone.run(() => {
        this.dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: action.payload,
          closeOnNavigation: false,
          disableClose: true
        });
      });
    }),
    takeUntil(this.destroyed$)
  );

  @Effect()
  resetUnUploadedFiles: Observable<Action> = this.actions$.pipe(
    ofType(EUploadActions.SUBMIT_FILES_SUCCESS),
    map(action => new ResetUnUploadedForm())
  );

  ngrxOnInitEffects(): Action {
    return new CloseErrorMessageOnRouteChange();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }
}
