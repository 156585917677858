import { academicInfoAdapter, IAcademicInfoStates } from '../states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ESchoolLevel,
  IAcademicInfo,
  ISchoolInfo,
  SchoolInfo
} from 'shared/models';
import { Dictionary } from '@ngrx/entity';

const getError = (state: IAcademicInfoStates): any => state.error;

const getIsLoading = (state: IAcademicInfoStates): boolean => state.loading;

const getIsSaving = (state: IAcademicInfoStates): boolean => state.saving;

const getSelectedId = (state: IAcademicInfoStates): string => state.selectedId;

const selectAcademicInfo = createFeatureSelector<IAcademicInfoStates>(
  'academicInfo'
);

const { selectEntities, selectAll } = academicInfoAdapter.getSelectors(
  selectAcademicInfo
);

export const selectLatest = createSelector(
  selectEntities,
  selectAcademicInfo,
  (list: Dictionary<IAcademicInfo>, state: IAcademicInfoStates) => {
    const selectedId = getSelectedId(state);
    if (selectedId) {
      const data = list[selectedId];
      const { id, schoolsAttended = [] } = data;
      let highSchool: ISchoolInfo;
      let postSecondaryEdus: ISchoolInfo[];
      if (schoolsAttended === []) {
        highSchool = new SchoolInfo();
        postSecondaryEdus = [];
      } else {
        highSchool =
          schoolsAttended.find(edu => edu.level === ESchoolLevel.HIGH_SCHOOL) ||
          new SchoolInfo();
        postSecondaryEdus = schoolsAttended.filter(
          edu => edu.level !== ESchoolLevel.HIGH_SCHOOL
        );
      }
      return {
        id,
        highSchool,
        postSecondaryEdus
      };
    }
    return null;
  }
);

export const selectSelectedAcademicInfo = createSelector(
  selectEntities,
  selectAcademicInfo,
  (list: Dictionary<IAcademicInfo>, state: IAcademicInfoStates) => {
    const selectedId = getSelectedId(state);
    return selectedId ? list[selectedId] : null;
  }
);

export const selectSelectedAcademicInfoReview = createSelector(
  selectEntities,
  selectAcademicInfo,
  (list: Dictionary<IAcademicInfo>, state: IAcademicInfoStates) => {
    const selectedId = getSelectedId(state);
    return selectedId
      ? list[selectedId].schoolsAttended.map(school => ({
          level: school.level,
          name: school.name
        }))
      : null;
  }
);

export const selectError = createSelector(
  selectAcademicInfo,
  getError
);

export const selectLoading = createSelector(
  selectAcademicInfo,
  getIsLoading
);

export const selectSaving = createSelector(
  selectAcademicInfo,
  getIsSaving
);
