import * as academicSelectors from './academicInfo.selectors';
import * as programChoiceSelectors from './programChoice.selectors';
import * as programListSelectors from './programList.selectors';
import * as paymentSelectors from './payment.selectors';
import * as personalInfoSelectors from './personalInfo.selectors';
import * as languageListSelectors from './languageList.selectors';
import * as countryListSelectors from './countryList.selectors';
import * as englishProficiencySelectors from './englishProficiency.selectors';
import * as userSelectors from './auth.selectors';
import * as uploadSelectors from './upload.selectors';
import * as applicantInfoSelectors from './applicantInfo.selectors';
import * as infoReleaseSelectors from './infoRelease.selectors';
import * as agentApplicationSelectors from './agentApplication.selectors';

export {
  infoReleaseSelectors,
  academicSelectors,
  programChoiceSelectors,
  programListSelectors,
  englishProficiencySelectors,
  personalInfoSelectors,
  languageListSelectors,
  countryListSelectors,
  paymentSelectors,
  userSelectors,
  uploadSelectors,
  applicantInfoSelectors,
  agentApplicationSelectors
};
