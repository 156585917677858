import { Component, OnInit } from '@angular/core';
import { commonEnv } from 'environments/environment.common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { EUserType, IApplicantInfo, routeList, ApplicationStatusObject } from 'app/shared/models';
import { Store } from '@ngrx/store';
import { IAuthStates } from 'app/store/states/auth.states';
import { LoadApplicantInfo } from 'app/store/actions';
import { AcademicInfoService } from 'app/shared/services/api/academic-info.service';
import { HttpClient } from '@angular/common/http';
import { applicantInfoSelectors } from 'app/store/selectors';
import { map, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import {environment} from 'environments/environment';
// import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.scss']
})
export class CompleteComponent implements OnInit {
  public userType: EUserType;
  public userTypeEnum = EUserType;
  appId: IApplicantInfo['appId'];
  public showAutoEvalButton = false;
  applicationId: string = null;
  applicationStatusObject = ApplicationStatusObject;
  destroyed$ = new Subject();
  public aeSubmitted = false;
  guid : string = null;
  autoEvalUrl: string = null;
  isDisabled: boolean = false;
  public userPressedAutoEval = false;
  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<IAuthStates>,
    private service: AcademicInfoService,
    private http: HttpClient
    // private gtmService: GoogleTagManagerService
  ) {
    this.setUserInfo();
    // this.router.events.forEach(item => {
    //   if(item instanceof NavigationEnd) {
    //     const gtmTag = {
    //       event: 'page',
    //       pageName: item.url
    //     };

    //     this.gtmService.pushTag(gtmTag);
    //   }
    // })
    this.autoEvalUrl = environment.autoEvalUrl;

  }


  ngOnInit() {
    this.autoEvalUrl = environment.autoEvalUrl;
    this.store
    .select(applicantInfoSelectors.selectCurrentAppInfo)
    .pipe(
      takeUntil(this.destroyed$),
      tap(currentAppInfo => {
        if (currentAppInfo) {
          this.applicationId = currentAppInfo.appId;
        }
      })
    )
    .subscribe();
    this.service.checkIfApplicantProgramConfig(this.applicationId).subscribe((data: Object) => {
    if (data) {
      this.showAutoEvalButton = true;
    }
      
    })

    this.service.checkIfAESubmitted(this.applicationId).subscribe((data: Object) => {
      if (data) {
        this.aeSubmitted = true;
      } 
    });
  }

/*
Use clicked checkbox and wants to do autoEval
 */
  userWantsAE() {
     this.service.setAutoEval(this.applicationId).subscribe((data: { message: string }) => {
       if (data) {
         this.guid= data.message;
          
         //show URL after clicking
         this.userPressedAutoEval = true;
         this.isDisabled  =true;
         }
     })
  }


  setUserInfo = () => {
    if (
      this.authService.getUserInfo().tfp === commonEnv.applicantSignInPolicy
    ) {
      this.userType = EUserType.Applicant;
    } else if (
      this.authService.getUserInfo().tfp === commonEnv.agentSignInPolicy
    ) {
      this.userType = EUserType.Agent;
    }

    this.appId = this.activatedRoute.snapshot.queryParams.appId;
  };

  isApplicant = () =>
    this.authService.getUserInfo().tfp === commonEnv.applicantSignInPolicy;

  onClose() {
    this.authService.logout();
  }

  onBack() {
    if (this.userType === EUserType.Applicant) {
      // the LoadApplicantInfo action will force to pull latest app status from database
      this.store.dispatch(new LoadApplicantInfo());
      this.router.navigate([
        `/${routeList.application.path}/${
          routeList.application.children.review
        }`
      ]);
    } else if (this.userType === EUserType.Agent) {
      this.router.navigate([
        `/${routeList.agent.path}/${routeList.agent.children.applicationList}`
      ]);
    }
  }
}
