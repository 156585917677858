import {
  programChoiceAdapter,
  programChoiceInitialState,
  ProgramChoiceStates
} from '../states';
import { EProgramChoiceActions, ProgramChoiceActions } from '../actions';

export function programChoiceReducers(
  state: ProgramChoiceStates = programChoiceInitialState,
  action: ProgramChoiceActions
): ProgramChoiceStates {
  switch (action.type) {
    case EProgramChoiceActions.LOAD_PROGRAM_CHOICES:
      return {
        ...state,
        loading: true,
        error: null,
        selectedId: null
      };
    case EProgramChoiceActions.LOAD_PROGRAM_CHOICES_SUCCESS:
      return programChoiceAdapter.addOne(action.payload, {
        ...state,
        loading: false,
        error: null,
        selectedId: action.payload.applicationId
      });
    case EProgramChoiceActions.LOAD_PROGRAM_CHOICES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        selectedId: null
      };
    case EProgramChoiceActions.SUBMIT_PROGRAM_CHOICES:
      return {
        ...state,
        saving: true,
        error: null
      };
    case EProgramChoiceActions.SUBMIT_PROGRAM_CHOICES_SUCCESS:
      return programChoiceAdapter.updateOne(
        {
          id: action.payload.applicationId,
          changes: action.payload
        },
        {
          ...state,
          saving: false,
          error: null
        }
      );

    case EProgramChoiceActions.SUBMIT_PROGRAM_CHOICES_FAIL:
      return {
        ...state,
        saving: false,
        error: action.payload
      };
    default: {
      return state;
    }
  }
}
