import {
  PersonalInfoActionsUnion,
  EPersonalInfoActions
} from '../actions/personalInfo.actions';
import {
  PersonalInfoStates,
  personalInfoInitialState,
  personalInfoAdapter
} from '../states';

export function personalInfoReducers(
  state: PersonalInfoStates = personalInfoInitialState,
  action: PersonalInfoActionsUnion
): PersonalInfoStates {
  switch (action.type) {
    case EPersonalInfoActions.LOAD_PERSONAL_INFO:
      return {
        ...state,
        loading: true,
        error: null,
        selectedId: null
      };
    case EPersonalInfoActions.LOAD_PERSONAL_INFO_SUCCESS:
      return personalInfoAdapter.upsertOne(action.payload, {
        ...state,
        loading: false,
        error: null,
        selectedId: action.payload.appId
      });
    case EPersonalInfoActions.LOAD_PERSONAL_INFO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        selectedId: null
      };
    case EPersonalInfoActions.SUBMIT_PERSONAL_INFO:
      return {
        ...state,
        saving: true,
        error: null
      };
    case EPersonalInfoActions.SUBMIT_PERSONAL_INFO_SUCCESS:
      return personalInfoAdapter.upsertOne(action.payload, {
        ...state,
        saving: false,
        error: null
      });
    case EPersonalInfoActions.SUBMIT_PERSONAL_INFO_FAIL:
      return {
        ...state,
        saving: false,
        error: action.payload
      };
    default:
      return state;
  }
}
