import {
  ELanguageListActions,
  LanguageListActionsUnion
} from 'app/store/actions';
import {
  languageListAdapter,
  languageListInitialState,
  LanguageListStates
} from 'app/store/states';

export function languageListReducers(
  state: LanguageListStates = languageListInitialState,
  action: LanguageListActionsUnion
): LanguageListStates {
  switch (action.type) {
    case ELanguageListActions.LOAD_LANGUAGE_LIST: {
      return {
        ...state,
        loading: true
      };
    }
    case ELanguageListActions.LOAD_LANGUAGE_LIST_SUCCESS: {
      return languageListAdapter.addAll(action.payload, {
        ...state,
        loading: false,
        error: null
      });
    }

    case ELanguageListActions.LOAD_LANGUAGE_LIST_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
