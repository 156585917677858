import {
  IProgramListContainerStates,
  programListContainerAdapter,
  programListContainerInitialState
} from '../states';
import { EProgramListActions, ProgramListActions } from '../actions';
import { IProgramListItemState } from 'shared/models';
import { Update } from '@ngrx/entity';

export function programListReducers(
  state: IProgramListContainerStates = programListContainerInitialState,
  action: ProgramListActions
): IProgramListContainerStates {
  switch (action.type) {
    case EProgramListActions.LOAD_PROGRAMS:
      const { id } = action.payload;
      return programListContainerAdapter.updateOne(
        {
          id: id,
          changes: {
            loading: true,
            error: null
          }
        },
        {
          ...state,
          selectedId: id
        }
      );
    case EProgramListActions.LOAD_PROGRAM_SUCCESS:
      const { id: updatedId, list, month, visa, year } = action.payload;
      return programListContainerAdapter.updateOne(
        {
          id: updatedId,
          changes: {
            list,
            month,
            visa,
            year,
            loading: false,
            error: null
          }
        },
        {
          ...state,
          selectedId: updatedId
        }
      );
    case EProgramListActions.LOAD_PROGRAM_FAIL:
      const update: Update<IProgramListItemState> = {
        id: state.selectedId,
        changes: {
          loading: false,
          error: action.payload
        }
      };
      return programListContainerAdapter.updateOne(update, {
        ...state
      });
    case EProgramListActions.RESET_PROGRAM_LIST:
      return programListContainerInitialState;
    default: {
      return state;
    }
  }
}
