<h1 mat-dialog-title>{{ header }}</h1>
<div mat-dialog-content>
  <p>{{ data.message }}</p>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="flex-end">
  <button
    mat-raised-button
    color="primary"
    (click)="onActionClick()"
    mat-button
  >
    {{ actionName.toUpperCase() }}
  </button>
</div>
