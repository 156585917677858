import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { IBaseEntityState, ICountry } from 'app/shared/models';

export interface CountryListStates extends IBaseEntityState<ICountry> {}

export const countryListAdapter: EntityAdapter<ICountry> = createEntityAdapter<
  ICountry
>({
  selectId: (countryList: ICountry) => countryList.code
});

export const countryListInitialState: CountryListStates = countryListAdapter.getInitialState(
  {
    selectedInfoId: null,
    loading: false,
    error: null
  }
);
