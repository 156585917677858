import { createEntityAdapter } from '@ngrx/entity';
import { IBaseEntityState, IPersonalInfo } from 'app/shared/models';

export interface PersonalInfoStates extends IBaseEntityState<IPersonalInfo> {
  saving: boolean;
}

export const personalInfoAdapter = createEntityAdapter<IPersonalInfo>({
  selectId: (personalInfo: IPersonalInfo) => {
    // Logic for new applicants is being handled in the personal info effects
    if (personalInfo.appId) {
      return personalInfo.appId;
    } else {
      return 'newApplication';
    }
  }
});

export const personalInfoInitialState: PersonalInfoStates = personalInfoAdapter.getInitialState(
  {
    selectedId: null,
    loading: false,
    error: null,
    saving: false
  }
);
