import * as tslib_1 from "tslib";
import { AuthService } from 'app/auth/auth.service';
import { first, switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { applicantInfoSelectors, userSelectors } from 'app/store/selectors';
import { environment } from 'environments/environment';
import { fromPromise } from 'rxjs-compat/observable/fromPromise';
import * as i0 from "@angular/core";
import * as i1 from "../../auth/auth.service";
import * as i2 from "@ngrx/store";
var IapHttpInterceptorService = /** @class */ (function () {
    function IapHttpInterceptorService(authService, store) {
        this.authService = authService;
        this.store = store;
        this.appId = null;
    }
    IapHttpInterceptorService.prototype.handleAccess = function (request, next, agentId, appId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var headerSettings, token;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        headerSettings = {};
                        if (!(this.authService.getCacheAccessToken() &&
                            Number(this.authService.getCacheAccessToken().expiresIn) >
                                Math.floor(Date.now() / 1000))) return [3 /*break*/, 1];
                        token = this.authService.getCacheAccessToken().accessToken;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.authService.acquireToken()];
                    case 2:
                        token = _a.sent();
                        _a.label = 3;
                    case 3:
                        // assign token to Authorization header
                        if (token) {
                            headerSettings['Authorization'] = 'Bearer ' + token;
                        }
                        // NOTE: this is not used anywhere, should be removed?
                        if (environment.apiSubscriptionEnabled) {
                            headerSettings['Ocp-Apim-Subscription-Key'] =
                                environment.apiSubscriptionKey;
                        }
                        // some API need appId in the header to work properly
                        if (appId) {
                            headerSettings['appId'] = appId;
                        }
                        // some API need agentId in the header to work properly
                        if (agentId) {
                            headerSettings['agentId'] = this.authService.getUserInfo().emails[0];
                        }
                        return [2 /*return*/, next
                                .handle(request.clone({
                                setHeaders: headerSettings
                            }))
                                .toPromise()];
                }
            });
        });
    };
    IapHttpInterceptorService.prototype.intercept = function (req, next) {
        var _this = this;
        // try to get specific data from the store and send them in the request header
        return this.store.select(userSelectors.selectAgentId).pipe(withLatestFrom(this.store.select(applicantInfoSelectors.selectApplicationID)), first(), switchMap(function (_a) {
            var agentId = _a[0], appId = _a[1];
            return fromPromise(_this.handleAccess(req, next, agentId, appId));
        }));
    };
    IapHttpInterceptorService.ngInjectableDef = i0.defineInjectable({ factory: function IapHttpInterceptorService_Factory() { return new IapHttpInterceptorService(i0.inject(i1.AuthService), i0.inject(i2.Store)); }, token: IapHttpInterceptorService, providedIn: "root" });
    return IapHttpInterceptorService;
}());
export { IapHttpInterceptorService };
