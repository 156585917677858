import {
  agentAppAdapter,
  agentApplicationInitialState,
  IAgentApplicationStates
} from '../states';
import { AgentApplicationActions, EAgentApplicationActions } from '../actions';

export function agentApplicationReducer(
  state: IAgentApplicationStates = agentApplicationInitialState,
  action: AgentApplicationActions
): IAgentApplicationStates {
  switch (action.type) {
    case EAgentApplicationActions.LOAD_AGENT_APPLICATIONS: {
      return {
        ...state,
        loading: true
      };
    }
    case EAgentApplicationActions.LOAD_AGENT_APPLICATIONS_SUCCESS: {
      return agentAppAdapter.addMany(action.payload, {
        ...state,
        loading: false,
        error: null
      });
    }

    case EAgentApplicationActions.LOAD_AGENT_APPLICATIONS_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
