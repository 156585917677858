import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import {
  IAgentVerificationResult,
  IMsalStoredToken,
  IUser
} from 'shared/models';
import { UserAgentApplication } from 'msal';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { MSAL_CONFIG } from '@azure/msal-angular/dist/msal.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userAgent: UserAgentApplication;
  constructor(private msalService: MsalService, private http: HttpClient) {
  }

  userType: string;
  setUserType = (user_type: string) => {
    this.userType = user_type;
  };

  getUserType = () => {
    return this.userType;
  };

  acquireToken = () => {
    return this.msalService.acquireTokenSilent(environment.scopes);
  };

  isAuthenticated = async (): Promise<boolean> => {
    return !!this.getUserInfo();
  };

  login = () => {
    // console.log('INSIDE authService.login()');
    // console.log('user type is: ' + this.userType);
    // console.log('login(): is Login in process', this.msalService.loginInProgress());
    if(!this.msalService.loginInProgress()){
      return this.msalService.loginRedirect(environment.scopes, this.userType + ';LOGIN');
    }
    else if (this.msalService.loginInProgress() && this.getUserType().includes('AGENT')){
      this.loginAfterNoSession();
    }
  };

  loginAfterNoSession = () => {
    // return this.msalService.loginRedirect(environment.scopes);
    // console.log('INSIDE authService.loginAfterNoSession()');
    // console.log('user type is: ' + this.userType);
    return this.msalService.loginRedirect(environment.scopes, this.userType + ';ERROR');
  };

  logout = () => {
    localStorage.removeItem('firstLogInDone');
    return this.msalService.logout();
  };

  getRolesTestCase(testCase: number) {
    let roles: string = "";
    switch (testCase) {
      case 1:
        //Intl_Admin - ReadWrite access
        roles = environment.roles_case_1;
        break;
      case 2:
        //Intl_User - ReadOnly access
        roles = environment.roles_case_2;
        break;
      // case 3:
      //   //IA_User - Access denied
      //   roles = environment.roles_case_3;
      //   break;
    }

    return roles;
  }

  getUserInfo = (): IUser => {
    const userData = this.msalService.getUser();
    // console.log('get user info');
    // console.log(userData);
    if(userData){
      let user: IUser = userData.idToken as IUser;

      if (environment.ssoEnabled) {

        let rolesOnly: any[] = [];
        let permissionsOnly: any[] = [];

        if(user.roles){
          if (user.roles.length > 0) {
            user.roles.split(", ").forEach(role => {
              let roleSplitArray = role.split("-");
  
              if (roleSplitArray[0]) {
                let roleOnly = roleSplitArray[0].toString();
  
                if (!rolesOnly.includes(roleOnly)) {
                  rolesOnly.push(roleOnly);
                }
  
                user.rolesOnly = rolesOnly;
              }
  
              if (roleSplitArray[1]) {
                let permissionOnly = roleSplitArray[1].toString();
  
                if (!permissionsOnly.includes(permissionOnly)) {
                  permissionsOnly.push(permissionOnly);
                }
              }
  
              user.permissionsOnly = permissionsOnly;
            });
            
          }
        }
      }
  }
    return userData ? (userData.idToken as IUser) : null;
  };

  hasRole = (): boolean => {
    let isAllowed:boolean = false;
    let userInfo = this.getUserInfo() as IUser;
    userInfo.roles.split(", ").forEach(role => {
      let roleSplitArray = role.split("-");
      if(roleSplitArray.length > 0) {
        for(let i = 0; i < roleSplitArray.length; i++){
          if(roleSplitArray[i] === "Agent" || roleSplitArray[i] == "Agency_Admin"){
            isAllowed = true;
          }
        }
      }
    });
    return isAllowed;
  }

  verifyAgent = (): Observable<IAgentVerificationResult> => {
    return this.http.get(`${environment.apiUrl}/verifyAgent`) as Observable<
      any
    >;
  };

  // TODO: the current get cache access token implemented by MS does not work, remove this func in the future
  getCacheAccessToken = (): IMsalStoredToken => {
    const key = {
      authority: this.msalService.authority,
      clientId: environment.clientId,
      scopes: environment.scopes[0],
      userIdentifier: this.msalService.getUser().userIdentifier
    };

    const storageValue = localStorage.getItem(JSON.stringify(key));

    return storageValue ? JSON.parse(storageValue) : null;
  };
}
