import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  EEnglishProficiencyActions,
  LoadEnglishProficiencyFail,
  LoadEnglishProficiencySuccess,
  SubmitEnglishProficiency,
  SubmitEnglishProficiencyFail,
  SubmitEnglishProficiencySuccess
} from '../actions';
import {
  AppLoadError,
  EEliOption,
  EnglishProficiency,
  IEnglishProficiencyAPI
} from 'shared/models';
import { EnglishProficiencyService } from 'shared/services/api/english-proficiency.service';
import { of as observableOf } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {
  applicantInfoSelectors,
  englishProficiencySelectors
} from '../selectors';
import { AppState } from '../reducers';
import { Store } from '@ngrx/store';

@Injectable()
export class EnglishProficiencyEffects {
  constructor(
    private service: EnglishProficiencyService,
    private actions$: Actions,
    private store: Store<AppState>
  ) {}

  @Effect()
  loadEnglishProficiency$ = this.actions$.pipe(
    ofType(EEnglishProficiencyActions.LOAD_ENGLISH_PROFICIENCY),
    withLatestFrom(
      this.store.select(englishProficiencySelectors.selectEnglishProfData)
    ),
    switchMap(([action, stored]) => {
      return stored
        ? observableOf(new LoadEnglishProficiencySuccess(stored))
        : this.service.getEnglishProficiency().pipe(
            withLatestFrom(
              this.store.select(applicantInfoSelectors.selectApplicationID)
            ),
            map(([englishProf, appId]) => {
              if (!englishProf || Object.entries(englishProf).length === 0) {
                return new LoadEnglishProficiencySuccess(
                  new EnglishProficiency(appId)
                );
              } else {
                return new LoadEnglishProficiencySuccess(
                  new EnglishProficiency(
                    appId,
                    englishProf.EliOption as EEliOption,
                    {
                      name: englishProf.TestName,
                      score: englishProf.TestScore,
                      date: englishProf.TestDate,
                      country: englishProf.TestCountry
                    },
                    {
                      name: englishProf.PartnerName,
                      dateCompletion: englishProf.PartnerDateCompletion
                    },
                    englishProf.uploadDocument
                  )
                );
              }
            }),
            catchError((err: HttpErrorResponse) => {
              const error = err instanceof HttpErrorResponse ? err.error : err;
              const displayError = error
                ? new AppLoadError(error.id, error.message)
                : new AppLoadError();

              return observableOf(new LoadEnglishProficiencyFail(displayError));
            })
          );
    })
  );

  @Effect()
  submitEnglishProficiency$ = this.actions$.pipe(
    ofType(EEnglishProficiencyActions.SUBMIT_ENGLISH_PROFICIENCY),
    switchMap((action: SubmitEnglishProficiency) => {
      const { eliOption, partner, test, uploadDocument } = action.payload;
      const formatted: IEnglishProficiencyAPI = {
        EliOption: eliOption,
        PartnerDateCompletion: partner ? partner.dateCompletion : null,
        PartnerName: partner ? partner.name : null,
        TestCountry: test ? test.country : null,
        TestDate: test ? test.date : null,
        TestName: test ? test.name : null,
        TestScore: test ? test.score : null,
        uploadDocument: uploadDocument
      };
      return this.service.postEnglishProficiency(formatted).pipe(
        map(() => {
          return new SubmitEnglishProficiencySuccess(action.payload);
        }),
        catchError((err: HttpErrorResponse) => {
          const error = err instanceof HttpErrorResponse ? err.error : err;
          const displayError = error
            ? new AppLoadError(error.id, error.message)
            : new AppLoadError();

          return observableOf(new SubmitEnglishProficiencyFail(displayError));
        })
      );
    })
  );
}
