import { Action } from '@ngrx/store';
import { IAppError } from 'app/shared/models';
import {
  IfilesInStore,
  IUploadResponse
} from 'shared/models/fileUpload.models';

export enum EUploadActions {
  SAVE_TO_STORE = '[Upload] Save To Store',

  GET_FILES = '[Upload] Get Files',
  GET_FILES_SUCCESS = '[Upload] Get Files Success',
  GET_FILES_FAIL = '[Upload] Get Files Fail',

  SUBMIT_FILES = '[Upload] Submit Files',
  SUBMIT_FILES_SUCCESS = '[Upload] Submit Files Success',
  SUBMIT_FILES_FAIL = '[Upload] Submit Files Fail',

  LOAD_UPLOADED_FILES = '[Upload] Load Uploaded Files',
  LOAD_UPLOADED_FILES_SUCCESS = '[Upload] Load Uploaded Files Success',
  LOAD_UPLOADED_FILES_FAIL = '[Upload] Load Uploaded Files Fail',
  SET_UNUPLOADED_FORM = '[Upload] Set UnUploaded Form',
  RESET_UNUPLOADED_FORM = '[Upload] Reset UnUploaded Form'
}

export class SaveToStore implements Action {
  readonly type = EUploadActions.SAVE_TO_STORE;

  constructor(public payload: IfilesInStore) {}
}

export class GetFiles implements Action {
  readonly type = EUploadActions.GET_FILES;
}

export class GetFilesSuccess implements Action {
  readonly type = EUploadActions.GET_FILES_SUCCESS;

  constructor(public payload: IfilesInStore) {}
}

export class GetFilesFail implements Action {
  readonly type = EUploadActions.GET_FILES_FAIL;

  constructor(public payload: IAppError) {}
}

export class SubmitFiles implements Action {
  readonly type = EUploadActions.SUBMIT_FILES;

  constructor(public payload: FormData) {}
}

export class SubmitFilesSuccess implements Action {
  readonly type = EUploadActions.SUBMIT_FILES_SUCCESS;

  constructor(public payload: IUploadResponse) {}
}

export class SubmitFilesFail implements Action {
  readonly type = EUploadActions.SUBMIT_FILES_FAIL;

  constructor(public payload: IAppError) {}
}

export class LoadUploadedFiles implements Action {
  readonly type = EUploadActions.LOAD_UPLOADED_FILES;

  constructor(public payload: IfilesInStore) {}
}

export class LoadUploadedFilesSuccess implements Action {
  readonly type = EUploadActions.LOAD_UPLOADED_FILES_SUCCESS;

  constructor() {}
}

export class LoadUploadedFilesFail implements Action {
  readonly type = EUploadActions.LOAD_UPLOADED_FILES_FAIL;

  constructor(public payload: IAppError) {}
}

export class SetUnUploadedForm implements Action {
  readonly type = EUploadActions.SET_UNUPLOADED_FORM;

  constructor(public payload: string) {}
}

export class ResetUnUploadedForm implements Action {
  readonly type = EUploadActions.RESET_UNUPLOADED_FORM;

  constructor() {}
}

export type UploadActions =
  | SaveToStore
  | GetFiles
  | GetFilesSuccess
  | GetFilesFail
  | SubmitFiles
  | SubmitFilesSuccess
  | SubmitFilesFail
  | LoadUploadedFiles
  | LoadUploadedFilesSuccess
  | LoadUploadedFilesFail
  | SetUnUploadedForm
  | ResetUnUploadedForm;
