import { OnDestroy } from '@angular/core';
import { MsalGuard, MsalService } from '@azure/msal-angular';
import { commonEnv } from 'environments/environment.common';
import { AuthService } from './auth.service';
import { environment } from 'environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@azure/msal-angular/dist/msal-guard.service";
import * as i3 from "@azure/msal-angular/dist/msal.service";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(authService, msalGuard, msalService) {
        this.authService = authService;
        this.msalGuard = msalGuard;
        this.msalService = msalService;
    }
    // TODO:This function can be made simpler by separating the logic for initial login authentication, applicant authentication and agent authentication into 3 separate guards
    AuthGuard.prototype.canActivate = function (route, state) {
        var targetUrl = state.url;
        var userInfo = this.authService.getUserInfo();
        if (userInfo) {
            if (userInfo.tfp === commonEnv.agentSignInPolicy &&
                Object.entries(route.queryParams).length === 0) {
                this.authService.setUserType('AGENT');
                this.msalService.authority = environment.msalAgentConfigs.authority;
                // console.log('AGENT 1 is being logged in');
                //   TODO: This is to prevent agents from landing on application pages on refresh (since we need to load agent data first)
                // Add this to application guard and check against agent policy + firstLogInDone in storage - loops on activation
                // if (targetUrl.match(`/${commonEnv.azureApplicantCallbackUrl}`) && ) {
                //   this.router.navigate(['/agent/application-list']);
                // }
            }
            else if (userInfo.tfp === commonEnv.applicantSignInPolicy) {
                this.authService.setUserType('APPLICANT');
                // console.log('APPLICANT 1 is being logged in');
                // TODO: this is to prevent regular applicants from landing on agent pages - loops on activation
                // if (targetUrl.match(`/${commonEnv.azureAgentCallbackUrl}`)) {
                //   this.router.navigate(['/application']);
                // }
                // this.router.navigate(['/application']);
                return false;
            }
            return true;
        }
        else {
            if (targetUrl.match("/" + commonEnv.azureAgentCallbackUrl)) {
                // console.log('AGENT 2 is being logged in');
                this.authService.setUserType('AGENT');
                this.msalService.authority = environment.msalAgentConfigs.authority;
                this.authService.login();
            }
            else {
                // console.log('APPLICANT 2 is being logged in');
                this.authService.setUserType('APPLICANT');
                this.authService.login();
            }
            return this.msalGuard.canActivate(route, state);
        }
    };
    AuthGuard.prototype.canLoad = function (route, segments) {
        if (this.authService.isAuthenticated()) {
            return true;
        }
        else {
            return false;
        }
    };
    /* TODO: use something like this func to cleanup the canActivate function (source: Angular official documents)
    checkLogin(url: string): boolean {
      if (this.authService.isAuthenticated()) { return this.msalGuard.canActivate(route, state); }
  
      // Store the attempted URL for redirecting
      this.authService.redirectUrl = url;
  
      // Create a dummy session id
      // const sessionId = 123456789;
  
      // Set our navigation extras object
      // that contains our global query params and fragment
      // const navigationExtras: NavigationExtras = {
      //   queryParams: { 'session_id': sessionId },
      //   fragment: 'anchor'
      // };
  
      // Navigate to the login page with extras
      // this.router.navigate(['/login'], navigationExtras);
      this.router.navigate(['/']);
      return false;
    }
    */
    AuthGuard.prototype.ngOnDestroy = function () { };
    AuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.inject(i1.AuthService), i0.inject(i2.MsalGuard), i0.inject(i3.MsalService)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
