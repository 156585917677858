import { Action } from '@ngrx/store';
import { IAppError, ICountry } from 'app/shared/models';

//  Enumeration defining the different actions
export enum ECountryListActions {
  LOAD_COUNTRY_LIST = '[CountryList] Load Country List',
  LOAD_COUNTRY_LIST_SUCCESS = '[CountryList] Load Country List Success',
  LOAD_COUNTRY_LIST_FAIL = '[CountryList] Load Country List Fail'
}

export class LoadCountryList implements Action {
  public readonly type = ECountryListActions.LOAD_COUNTRY_LIST;
  constructor() {}
}

export class LoadCountryListSuccess implements Action {
  public readonly type = ECountryListActions.LOAD_COUNTRY_LIST_SUCCESS;
  // Passing a payload of type ICountry[] which holds the Country information
  constructor(public payload: ICountry[]) {}
}

export class LoadCountryListFail implements Action {
  public readonly type = ECountryListActions.LOAD_COUNTRY_LIST_FAIL;
  // Passing a payload of type IAppError
  constructor(public payload: IAppError) {}
}

//  Defining a union for all the actions in this component
export type CountryListActionsUnion =
  | LoadCountryList
  | LoadCountryListSuccess
  | LoadCountryListFail;
