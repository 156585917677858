import { Action } from '@ngrx/store';
import { IAppError, IProgramChoice, IProgramChoiceApp } from 'shared/models';

export enum EProgramChoiceActions {
  LOAD_PROGRAM_CHOICES = '[ProgramChoices] LOAD',
  LOAD_PROGRAM_CHOICES_SUCCESS = '[ProgramChoices] LOAD SUCCESS',
  LOAD_PROGRAM_CHOICES_FAIL = '[ProgramChoices] LOAD FAIL',
  SUBMIT_PROGRAM_CHOICES = '[ProgramChoices] SUBMIT',
  SUBMIT_PROGRAM_CHOICES_SUCCESS = '[ProgramChoices] SUBMIT SUCCESS',
  SUBMIT_PROGRAM_CHOICES_FAIL = '[ProgramChoices] SUBMIT FAIL'
}

export class LoadProgramChoices implements Action {
  public readonly type = EProgramChoiceActions.LOAD_PROGRAM_CHOICES;

  constructor() {}
}

export class LoadProgramChoicesSuccess implements Action {
  public readonly type = EProgramChoiceActions.LOAD_PROGRAM_CHOICES_SUCCESS;

  constructor(public payload: IProgramChoiceApp) {}
}

export class LoadProgramChoicesFail implements Action {
  public readonly type = EProgramChoiceActions.LOAD_PROGRAM_CHOICES_FAIL;

  constructor(public payload: IAppError) {}
}

export class SubmitProgramChoices implements Action {
  public readonly type = EProgramChoiceActions.SUBMIT_PROGRAM_CHOICES;

  constructor(public payload: IProgramChoice[]) {}
}

export class SubmitProgramChoicesSuccess implements Action {
  public readonly type = EProgramChoiceActions.SUBMIT_PROGRAM_CHOICES_SUCCESS;

  constructor(public payload: IProgramChoiceApp) {}
}

export class SubmitProgramChoicesFail implements Action {
  public readonly type = EProgramChoiceActions.SUBMIT_PROGRAM_CHOICES_FAIL;

  constructor(public payload: IAppError) {}
}

export type ProgramChoiceActions =
  | LoadProgramChoices
  | LoadProgramChoicesSuccess
  | LoadProgramChoicesFail
  | SubmitProgramChoices
  | SubmitProgramChoicesSuccess
  | SubmitProgramChoicesFail;
