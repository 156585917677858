import { ICountry } from './countryList.models';
import { ILanguage } from './languageList.models';
import { IPhone } from '.';

export interface IPersonalInfo {
  appId: string;
  isPreviousStudent: boolean;
  previousStudentID: string;
  title: string;
  surName: string;
  firstName: string;
  otherNames: string;
  gender: string;
  dob: Date;
  email: string;
  countryOB: ICountry['code'];
  countryOC: ICountry['code'];
  countryOA: ICountry['code'];
  primaryLang: ILanguage['code'];
  permanentAddress: IAddress;
  currAddrSameAsPerm: boolean;
  mailingAddress: IAddress;
  status: string;
  uci_number: string;
  status_value: string;
  issue_date: Date;
  expiry_date: Date;
  status_pal: string;
}

export interface IAddress {
  country: ICountry['code'];
  stAddress: string;
  stAddress2: string;
  city: string;
  province: string;
  zipCode: string;
  phone?: IPhone;
  cell?: IPhone;
}

export interface IPersonalInfoForm {
  data: {
    appId: string;
    isPreviousStudent: boolean;
    previousStudentID: string;
    title: string;
    surName: string;
    firstName: string;
    otherNames: string;
    gender: string;
    dob: Date;
    countryOB: ICountry['code'];
    countryOC: ICountry['code'];
    countryOA: ICountry['code'];
    primaryLang: ILanguage['code'];
    permanentAddress;
    currAddrSameAsPerm: boolean;
    mailingAddress;
  };
  loading: boolean;
  error?: any;
  saving: boolean;
}

export class PersonalInfo implements IPersonalInfo {
  appId: string;
  isPreviousStudent = false;
  previousStudentID: string = null;
  title: string = null;
  surName: string = null;
  firstName: string = null;
  otherNames: string = null;
  gender: string = null;
  dob: Date = null;
  email: string = null;
  countryOB: string = null;
  countryOC: string = null;
  countryOA: string = null;
  primaryLang: string = null;
  permanentAddress: IAddress = {
    country: null,
    province: null,
    city: null,
    stAddress: null,
    stAddress2: null,
    zipCode: null,
    phone: {
      countryCode: null,
      number: null
    },
    cell: {
      countryCode: null,
      number: null
    }
  };
  currAddrSameAsPerm = false;
  mailingAddress: IAddress = {
    country: null,
    province: null,
    city: null,
    stAddress: null,
    stAddress2: null,
    zipCode: null
  };
  status: string;
  uci_number: string;
  status_value: string;
  issue_date: Date;
  expiry_date: Date;
  status_pal: string;
}

export enum StudyPermit {
  NOT_REQUIRED = '1',
  NOT_APPLIED = '2',
  APPLIED = '3',
  RECEIVED_LOI = '4',
  RECEIVED_PERMIT = '5'
}

/**
any new attributes must be added to  
  app/shared/models/personalInfo.models.ts
  app/selectors/personalInfo.selectors.ts
  app/application/components/personal-info/personal-info.component.html

1-NO - I have a valid Study Permit, or have applied for a study permit, or are applying for a study permit renewal.
2-NO - I have a work permit.
3-NO - I am a temporary resident and a visiting or exchange student studying at a designated learning institution (DLI).
4-NO - I am a temporary resident and have completed a course or program of study that is a prerequisite to enrol at Seneca.
5-NO - I have a temporary resident permit valid for at least 6 months.
6-NO - I am a protected person.
7-NO - I am eligible for permanent residence based on humanitarian and compassionate grounds, a current public policy, or through the spouse or common-law partner in Canada class.
8-NO - I am the family member of a foreign national who lives in Canada and meet one of the requirements outlined on the Government of Canada website.
9-NO - I have an unused valid Provincial Attestation Letter
10-YES
*/
export enum Pal {
  VALID_SP = '1',
  VALID_WP = '2',
  TEMP_RES_VISIT = '3',
  TEMP_RES_COMPLETED = '4',
  TEMP_RES_SIX_MONTHS = '5',
  PROTECTED_PERSON = '6',
  ELIG_PR = '7',
  FAMILY_FOREIGN_NATIONAL = '8',
  VALID_PAL = '9',
  YES = '10'
}