import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { IProgramChoiceApp } from 'shared/models';

@Injectable({
  providedIn: 'root'
})
export class ProgramChoiceService {
  constructor(private http: HttpClient) {}

  getProgramChoices = (): Observable<IProgramChoiceApp> => {
    return this.http.get(
      `${environment.apiUrl}/getProgramChoice`
    ) as Observable<IProgramChoiceApp>;
  };

  postProgramChoices = (
    programChoices: IProgramChoiceApp
  ): Observable<IProgramChoiceApp> => {
    return this.http.post(
      `${environment.apiUrl}/AddProgramChoice`,
      programChoices
    ) as Observable<IProgramChoiceApp>;
  };
}
