import { environment } from '../../environments/environment';
import { MsalConfig } from '@azure/msal-angular';
import { FormControl, FormGroup } from '@angular/forms';

/**
 * dynamically get Msal configs base on login request and application url
 */
export function getMsalConfigs(): MsalConfig {
  const loginReqUrl =
    localStorage.getItem('msal.login.request') ||
    sessionStorage.getItem('msal.login.request');
  const idToken =
    localStorage.getItem('msal.idtoken') ||
    sessionStorage.getItem('msal.idtoken');
  let isApplicant = true;
  if (loginReqUrl && idToken) {
    isApplicant = !loginReqUrl.includes('/agent');
  } else {
    isApplicant = !window.location.pathname.includes('/agent');
  }
  return isApplicant
    ? environment.msalApplicantConfigs
    : environment.msalAgentConfigs;
}

/**
 * @param campusCode
 * convert campus code to campus name
 * @return campusName
 */
export function campusNameConverter(campusCode: string) {
  switch (campusCode) {
    case 'NH':
      return 'Newnham';
    case 'SY':
      return 'Seneca@York';
    case 'MK':
      return 'Markham';
    case 'YG':
      return 'Yorkgate';
    case 'KG':
      return 'King';
    case 'YPQ':
      return 'Peterborough';
    case 'SIA':
      return 'Seneca International Academy';
    case 'OF':
      return 'Off-Campus';
    case 'ALL':
      return 'Any available campus';
  }
}

export function campusCodeConverter(campusName: string) {
  switch (campusName) {
    case 'Newnham':
      return 'NH';
    case 'Seneca@York':
      return 'SY';
    case 'Markham':
      return 'MK';
    case 'Yorkgate':
      return 'YG';
    case 'King':
      return 'KG';
    case 'Peterborough':
      return 'YPQ';
    case 'Seneca International Academy':
      return 'SIA';
    case 'Off-Campus':
      return 'OF';
    case 'Any available campus':
      return 'ALL';
  }
}

export function campusUrlConverter(campusName: string) {
  switch (campusName) {
    case 'Newnham':
      return 'https://www.senecapolytechnic.ca/campuses/newnhamlocation.html';
    case 'Seneca@York':
      return 'https://www.senecapolytechnic.ca/campuses/yorklocation.html';
    case 'Markham':
      return 'https://www.senecapolytechnic.ca/campuses/markhamlocation.html';
    case 'Yorkgate':
      return 'https://www.senecapolytechnic.ca/campuses/yorkgate.html';
    case 'King':
      return 'https://www.senecapolytechnic.ca/campuses/kinglocation.html';
    case 'Peterborough':
      return 'https://www.senecapolytechnic.ca/campuses/peterborough.html';
    case 'Seneca International Academy':
      return 'https://www.senecapolytechnic.ca/campuses/seneca-international-academy.html';
    case 'Off-Campus':
      return 'https://www.senecapolytechnic.ca/campuses/downtown.html';
    case 'Any available campus':
      return 'https://www.senecapolytechnic.ca/campuses.html';
  }
}

export function isAnyPropEmpty(object: Object): boolean {
  for (const key in object) {
    if (object[key] === null || object[key] === '') return true;
  }
  return false;
}

export function markFormGroupTouched(formGroup: FormGroup) {
  (<any>Object).values(formGroup.controls).forEach(control => {
    control.markAsTouched();

    if (control.controls) {
      markFormGroupTouched(control);
    }
  });
}

export function validateAllFormFields(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormGroup) {
      validateAllFormFields(control);
    }
  });
}

export function scrollToErrorOnSubmit() {
  const firstElementWithError = document.querySelector('.ng-invalid');

  if (firstElementWithError) {
    firstElementWithError.scrollIntoView({ behavior: 'smooth' });
  }
}
export function getSafe(fn, defaultVal = null) {
  try {
    return fn();
  } catch (e) {
    return defaultVal;
  }
}

export const flattenObject = obj => {
  const flattened = {};

  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(flattened, flattenObject(obj[key]));
    } else {
      flattened[key] = obj[key];
    }
  });

  return flattened;
};

export const removeEmptyProps = obj => {
  Object.keys(obj).forEach(
    key => (obj[key] === undefined || obj[key] === null) && delete obj[key]
  );
  return obj;
};

export const jsonEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);

export const getUnUploadedDocumentsFromStorage = () => {
  const data = localStorage.getItem('unUploadedForms');
  return data ? JSON.parse(data) : [];
};

export const setUnUploadedDocumentsToStorage = (documents: string[]) => {
  localStorage.setItem('unUploadedForms', JSON.stringify(documents));
};

export const removeUnUnloadedDocumentsFromStorage = () => {
  localStorage.removeItem('unUploadedForms');
};
