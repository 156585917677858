import { createEntityAdapter } from '@ngrx/entity';
import { IBaseEntityState, IProgramChoiceApp } from 'shared/models';

export interface ProgramChoiceStates
  extends IBaseEntityState<IProgramChoiceApp> {
  saving: boolean;
}

export const programChoiceAdapter = createEntityAdapter<IProgramChoiceApp>({
  selectId: (programChoice: IProgramChoiceApp) => programChoice.applicationId
});

export const programChoiceInitialState: ProgramChoiceStates = programChoiceAdapter.getInitialState(
  {
    selectedInfoId: null,
    loading: false,
    error: null,
    saving: false
  }
);
