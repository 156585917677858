import { Action } from '@ngrx/store';
import { IAppError, IEnglishProficiency } from 'app/shared/models';

export enum EEnglishProficiencyActions {
  LOAD_ENGLISH_PROFICIENCY = '[EnglishProficiency] LOAD',
  LOAD_ENGLISH_PROFICIENCY_SUCCESS = '[EnglishProficiency] LOAD SUCCESS',
  LOAD_ENGLISH_PROFICIENCY_FAIL = '[EnglishProficiency] LOAD FAIL',

  SUBMIT_ENGLISH_PROFICIENCY = '[EnglishProficiency] SUBMIT',
  SUBMIT_ENGLISH_PROFICIENCY_SUCCESS = '[EnglishProficiency] SUBMIT SUCCESS',
  SUBMIT_ENGLISH_PROFICIENCY_FAIL = '[EnglishProficiency] SUBMIT FAIL'
}

export class LoadEnglishProficiency implements Action {
  public readonly type = EEnglishProficiencyActions.LOAD_ENGLISH_PROFICIENCY;
}

export class LoadEnglishProficiencySuccess implements Action {
  public readonly type =
    EEnglishProficiencyActions.LOAD_ENGLISH_PROFICIENCY_SUCCESS;

  constructor(public payload: IEnglishProficiency) {}
}

export class LoadEnglishProficiencyFail implements Action {
  public readonly type =
    EEnglishProficiencyActions.LOAD_ENGLISH_PROFICIENCY_FAIL;

  constructor(public payload: IAppError) {}
}

export class SubmitEnglishProficiency implements Action {
  public readonly type = EEnglishProficiencyActions.SUBMIT_ENGLISH_PROFICIENCY;

  constructor(public payload: IEnglishProficiency) {}
}

export class SubmitEnglishProficiencySuccess implements Action {
  public readonly type =
    EEnglishProficiencyActions.SUBMIT_ENGLISH_PROFICIENCY_SUCCESS;

  constructor(public payload: IEnglishProficiency) {}
}

export class SubmitEnglishProficiencyFail implements Action {
  public readonly type =
    EEnglishProficiencyActions.SUBMIT_ENGLISH_PROFICIENCY_FAIL;

  constructor(public payload: IAppError) {}
}

export type EnglishProficiencyActions =
  | LoadEnglishProficiency
  | LoadEnglishProficiencySuccess
  | LoadEnglishProficiencyFail
  | SubmitEnglishProficiency
  | SubmitEnglishProficiencySuccess
  | SubmitEnglishProficiencyFail;
