import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  IApplicationFeeResponse,
  IPromoCodeSubmit,
  IPromoCodeSubmitResult,
  IPaymentAppCompleteResponse,
  IPreloadDataResponse
} from 'shared/models';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  paymentUrl = environment.monerisServiceUrl; 
  appName = environment.monerisAppName;
  
  constructor(private http: HttpClient) {}

  getApplicationFee = (): Observable<IApplicationFeeResponse> => {
    return this.http.get(
      `${environment.apiUrl}/getApplicationFee`
    ) as Observable<IApplicationFeeResponse>;
  };

  checkPromoCode = (
    data: IPromoCodeSubmit
  ): Observable<IPromoCodeSubmitResult> => {
    return this.http.post(
      `${environment.apiUrl}/validatePromoCode`,
      data
    ) as Observable<IPromoCodeSubmitResult>;
  };

  checkAppCompleteBeforePayment = () => {
    return this.http.get(
      `${environment.apiUrl}/checkAppCompleteBeforePayment`      
    ) ;
  };

  // Send client url to moneris proxy
  public sendClientInfo(clientURL: string){
    return this.http.post(this.paymentUrl + `/moneris-checkout-web/sendClientInfo`, clientURL);
  }

  // Moneris API created preload data to create payment request
  public getPreloadData(studentId, fee) {
    return this.http.get(this.paymentUrl +`/api/moneris_checkout_service/preloadCheckoutData/${this.appName}/${studentId}/${fee.toFixed(2)}`) as Observable<IPreloadDataResponse>;
  }

  // Gets the payment recipt from moneris API
  public getPaymentReceipt(transTicket: string) {
    return this.http.get(this.paymentUrl + `/api/moneris_checkout_service/getTransactionReceipt/${this.appName}/${transTicket}`);
  }

  public sendIwaPayment(data) {
    return this.http.post(`${environment.apiUrl}/iwaPaymentResult`, data);
  }


}
