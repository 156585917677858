import { createEntityAdapter } from '@ngrx/entity';
import { IBaseEntityState, IEnglishProficiency } from 'app/shared/models';

export interface EnglishProficiencyStates
  extends IBaseEntityState<IEnglishProficiency> {
  saving: boolean;
}

export const englishProficiencyAdapter = createEntityAdapter<
  IEnglishProficiency
>({
  selectId: (englishProficiency: IEnglishProficiency) => englishProficiency.id
});

export const englishProficiencyInitialState: EnglishProficiencyStates = englishProficiencyAdapter.getInitialState(
  {
    selectedId: null,
    loading: true,
    error: null,
    saving: false
  }
);
