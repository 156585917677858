import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UploadService = /** @class */ (function () {
    function UploadService(http) {
        var _this = this;
        this.http = http;
        this.getFiles = function () {
            return _this.http.get(environment.apiUrl + "/getUploadedFiles");
        };
        this.uploadFiles = function (formData) {
            return _this.http.post(environment.apiUrl + "/uploadIwaFiles", formData);
        };
    }
    UploadService.ngInjectableDef = i0.defineInjectable({ factory: function UploadService_Factory() { return new UploadService(i0.inject(i1.HttpClient)); }, token: UploadService, providedIn: "root" });
    return UploadService;
}());
export { UploadService };
