import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { UploadService } from 'shared/services/api/upload.service';
import {
  AppLoadError,
  AppSubmitError,
  IAPIResponseFile,
  IfilesInStore,
  IUploadResponse,
  EdocIds
} from 'app/shared/models';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  EUploadActions,
  GetFiles,
  GetFilesFail,
  GetFilesSuccess,
  SubmitFiles,
  SubmitFilesFail,
  SubmitFilesSuccess
} from '../actions';
import { AppState } from '../reducers';
import { applicantInfoSelectors } from '../selectors';

@Injectable()
export class UploadEffects {
  @Effect()
  getFiles$ = this.actions$.pipe(
    ofType(EUploadActions.GET_FILES),
    withLatestFrom(
      this.store.select(applicantInfoSelectors.selectApplicationID)
    ),
    switchMap(([action, appId]: [GetFiles, string]) =>
      this.uploadService.getFiles().pipe(
        map((filesResponse: IAPIResponseFile[]) => {
          const toStore: IfilesInStore = {
            id: appId,
            files: []
          };

          for (let i = 0; i < filesResponse.length; i++) {
            toStore.files.push({
              docId: filesResponse[i].Document_Id,
              fileName: filesResponse[i].Document_Name,
              type: filesResponse[i].Document_Type
            });
          }

          //Remove index for 'other' docId that makes it unique in DB
          for (let i = 0; i < toStore.files.length; i++) {
            if (toStore.files[i].docId.indexOf('other') === 0) {
              toStore.files[i].docId = 'other';
            }
          }

          return new GetFilesSuccess(toStore);
        }),
        catchError((err: HttpErrorResponse) => {
          const error = err instanceof HttpErrorResponse ? err.error : err;
          const displayError = error
            ? new AppLoadError(error.id, error.message)
            : new AppLoadError();

          return observableOf(new GetFilesFail(displayError));
        })
      )
    )
  );

  @Effect()
  uploadFiles$ = this.actions$.pipe(
    ofType(EUploadActions.SUBMIT_FILES),
    switchMap((action: SubmitFiles) => {
      if (action.payload) {
        return this.uploadService.uploadFiles(action.payload).pipe(
          map((uploadResponse: IUploadResponse) => {
            return new SubmitFilesSuccess(uploadResponse);
          }),
          catchError((err: HttpErrorResponse) => {
            const error = err instanceof HttpErrorResponse ? err.error : err;
            const displayError = error
              ? new AppSubmitError(error.id, error.message)
              : new AppSubmitError();

            return observableOf(new SubmitFilesFail(displayError));
          })
        );
      } else {
        return observableOf(
          new SubmitFilesFail(new AppSubmitError('0', 'Invalid Response'))
        );
      }
    })
  );

  constructor(
    private uploadService: UploadService,
    private actions$: Actions,
    private store: Store<AppState>
  ) {}
}
