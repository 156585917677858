import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  IProgramListContainerStates,
  programListContainerAdapter
} from '../states';
import {
  IProgram,
  IProgramListItem,
  IProgramListItemState
} from 'shared/models';
import { Dictionary } from '@ngrx/entity';

const selectSelectedId = (state: IProgramListContainerStates) =>
  state.selectedId;

const getError = (
  state: IProgramListContainerStates,
  entities: Dictionary<IProgramListItemState>
): any => {
  const selectedId = selectSelectedId(state);
  return entities[selectedId] ? entities[selectedId].error : null;
};

const getIsLoading = (
  state: IProgramListContainerStates,
  entities: Dictionary<IProgramListItemState>
): boolean => {
  const selectedId = selectSelectedId(state);
  return selectedId ? entities[selectedId].loading : true;
};

const selectProgramListContainer = createFeatureSelector<
  IProgramListContainerStates
>('programList');

const { selectEntities, selectAll } = programListContainerAdapter.getSelectors(
  selectProgramListContainer
);

export const selectSelectedProgramList = createSelector(
  selectEntities,
  selectProgramListContainer,
  (
    entities: Dictionary<IProgramListItemState>,
    state: IProgramListContainerStates
  ) => {
    const selectedId = selectSelectedId(state);
    return entities[selectedId];
  }
);

export const selectProgramItemById = (index: string) =>
  createSelector(
    selectEntities,
    (entities: Dictionary<IProgramListItem>) => {
      return entities[index];
    }
  );

export const selectProgramByCode = (code: string) =>
  createSelector(
    selectAll,
    (list: Array<IProgramListItem>) => {
      let foundProgram: IProgram = null;
      let isFound = false;
      for (let i = 0; i < list.length && !isFound; i++) {
        foundProgram = list[i].list.find(item => item.programCode === code);
        if (foundProgram) {
          isFound = true;
        }
      }
      return foundProgram ? foundProgram : null;
    }
  );

export const selectError = createSelector(
  selectProgramListContainer,
  selectEntities,
  (
    state: IProgramListContainerStates,
    entities: Dictionary<IProgramListItemState>
  ) => getError(state, entities)
);

export const selectLoading = createSelector(
  selectProgramListContainer,
  selectEntities,
  (
    state: IProgramListContainerStates,
    entities: Dictionary<IProgramListItemState>
  ) => getIsLoading(state, entities)
);
