import { Action } from '@ngrx/store';
import { IDialogError, IErrorSnackBar } from 'shared/models';

export enum EMessageActions {
  DISPLAY_ERROR_MESSAGE = '[ERROR MESSAGE] DISPLAY',
  CLOSE_ERROR_MESSAGE_ON_ROUTE_CHANGE = '[ERROR MESSAGE] CLOSE_ON_ROUTE_CHANGE',
  DISPLAY_ERROR_DIALOG = '[ERROR MESSAGE] DISPLAY DIALOG'
}

export class DisplayErrorMessage implements Action {
  public readonly type = EMessageActions.DISPLAY_ERROR_MESSAGE;

  constructor(public payload: IErrorSnackBar) {}
}

export class CloseErrorMessageOnRouteChange implements Action {
  public readonly type = EMessageActions.CLOSE_ERROR_MESSAGE_ON_ROUTE_CHANGE;

  constructor() {}
}

export class DisplayErrorDialog implements Action {
  public readonly type = EMessageActions.DISPLAY_ERROR_DIALOG;

  constructor(public payload: IDialogError) {}
}

export type MessageActions =
  | DisplayErrorMessage
  | CloseErrorMessageOnRouteChange
  | DisplayErrorDialog;
