import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  switchMap,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of as observableOf, Subject } from 'rxjs';

import {
  EProgramChoiceActions,
  LoadProgramChoicesFail,
  LoadProgramChoicesSuccess,
  SubmitProgramChoices,
  SubmitProgramChoicesFail,
  SubmitProgramChoicesSuccess
} from '../actions';
import { Action, Store } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { AppState } from '../reducers';
import {
  applicantInfoSelectors,
  englishProficiencySelectors,
  programChoiceSelectors
} from '../selectors';
import {
  AppLoadError,
  AppSubmitError,
  EEliOption,
  senecaLanguageCentre,
  englishLanguageInstitute,
  IProgramChoiceApp,
  ProgramChoiceApp,
  degreePrepProgram
} from 'shared/models';
import { ProgramChoiceService } from 'shared/services/api/program-choice.service';
import { campusNameConverter } from 'app/utils';

@Injectable()
export class ProgramChoiceEffects implements OnDestroy {
  constructor(
    private actions$: Actions,
    private service: ProgramChoiceService,
    private store: Store<AppState>
  ) {}

  destroyed$ = new Subject();

  @Effect()
  getProgramChoices$: Observable<Action> = this.actions$.pipe(
    ofType(EProgramChoiceActions.LOAD_PROGRAM_CHOICES),
    withLatestFrom(
      this.store.select(programChoiceSelectors.selectProgramChoice),
      this.store.select(applicantInfoSelectors.selectCurrentAppInfo),
      this.store.select(englishProficiencySelectors.selectEnglishProfData)
    ),
    switchMap(([action, stored, applicantInfo, englishProf]) => {
      const eliOption = englishProf
        ? englishProf.eliOption
        : applicantInfo.EliOption;
      if (stored) {
        stored = this.checkForEliAndDpp(stored, eliOption);
        return observableOf(new LoadProgramChoicesSuccess(stored));
      } else {
        return this.service.getProgramChoices().pipe(
          map(programChoice => {
            const { appId } = applicantInfo;
            // we only need to check for eliOption once the return result from server is empty
            // to force the first choice to be ELI
            if (!programChoice || Object.entries(programChoice).length === 0) {
              programChoice = new ProgramChoiceApp(appId);
            } else {
              programChoice.applicationId = appId;

              // the choices array contains at least one choice,
              // no need to check for ELI program, just need formatting and displaying
              // format the date from 'YYYY-MM' to Date format
              programChoice.choices.map(choice => {
                // cover the situation that the program is passed from the proficiency form
                // and the date is not selected yet
                if (choice) {
                  if (choice.startDate !== null) {
                    choice.startDate = this.formatDate(
                      choice.startDate.toString()
                    );
                  }
                  choice.campus = campusNameConverter(choice.campus);
                  if (choice.program === englishLanguageInstitute.programCode) {
                    choice.description = englishLanguageInstitute.programDesc;
                    choice.acadCareer = englishLanguageInstitute.acadCareer;
                    choice.acadPlan = englishLanguageInstitute.acadPlan;
                  } else if (
                    choice.program === senecaLanguageCentre.programCode
                  ) {
                    choice.description = senecaLanguageCentre.programDesc;
                    choice.acadCareer = senecaLanguageCentre.acadCareer;
                    choice.acadPlan = senecaLanguageCentre.acadPlan;
                  }

                  else if (choice.program === degreePrepProgram.programCode){
                    choice.description = degreePrepProgram.programDesc;
                    choice.acadCareer = degreePrepProgram.acadCareer;
                    choice.acadPlan = degreePrepProgram.acadPlan;
                  }
                }
              });
            }
            programChoice = this.checkForEliAndDpp(programChoice, eliOption);
            return new LoadProgramChoicesSuccess(programChoice);
          }),
          catchError((err: any) => {
            // the error could be either HttpErrorResponse, which comes from the server when the request failed
            // or switch map error, needed checking and set manually
            const error = err instanceof HttpErrorResponse ? err.error : err;
            const displayError = error
              ? new AppLoadError(error.id, error.message)
              : new AppLoadError();

            // either way, dispatch a LoadFail action
            return observableOf(new LoadProgramChoicesFail(displayError));
          })
        );
      }
    })
  );

  @Effect()
  postProgramChoices$: Observable<Action> = this.actions$.pipe(
    ofType(EProgramChoiceActions.SUBMIT_PROGRAM_CHOICES),
    withLatestFrom(
      this.store.select(applicantInfoSelectors.selectApplicationID)
    ),
    switchMap(([action, appID]: [SubmitProgramChoices, string]) => {
      const programChoices: IProgramChoiceApp = {
        applicationId: appID,
        choices: action.payload
      };
      return this.service.postProgramChoices(programChoices).pipe(
        map(() => {
          programChoices.choices.map(choice => {
            // cover the situation that the program is passed from the proficiency form
            // and the date is not selected yet
            if (choice.startDate !== null) {
              choice.startDate = this.formatDate(choice.startDate.toString());
            }
            choice.campus = campusNameConverter(choice.campus);
          });
          return new SubmitProgramChoicesSuccess(programChoices);
        }),
        catchError((err: HttpErrorResponse) => {
          const error = err instanceof HttpErrorResponse ? err.error : err;
          const displayError = error
            ? new AppSubmitError(error.id, error.message)
            : new AppSubmitError();

          return observableOf(new SubmitProgramChoicesFail(displayError));
        })
      );
    }),
    takeUntil(this.destroyed$)
  );

  formatDate = (stringDate: string): Date => {
    const separator = '-';
    const date = stringDate.split(separator);
    // make sure the date from the API is in the right format, which is YYYY-MM
    if (date.length === 2) {
      const year = Number(date[0]);
      const month = Number(date[1]) - 1;
      return new Date(year, month);
    }
    return null;
  };

  checkForEliAndDpp = (
    programChoice: ProgramChoiceApp,
    eliOption: string
  ): IProgramChoiceApp => {
    // manually add ELI as the first choice to the choices array if the English prof choice 2 (ELI) is selected
    // Locking the select program button for this choice is being handled in programChoice component.ts
    if (eliOption === EEliOption.ATTEND_ELI) {
      // fail save for the case that the user saves ELI option 2 twice. we don't want to remove their previous choice based on the same ELI option.
      // They can do this in the program choice page
      // TODO: This won't be necessary if we simply don't call the English Proficiency post API if the ELI option stays the same and the user tries to do a second save
      if (
        !programChoice.choices[0] ||
        (programChoice.choices[0] &&
          programChoice.choices[0].program !==
            englishLanguageInstitute.programCode)
      ) {
        programChoice.choices[0] = {
          startDate: null,
          campus: campusNameConverter(englishLanguageInstitute.campus),
          program: englishLanguageInstitute.programCode,
          description: englishLanguageInstitute.programDesc,
          acadCareer: englishLanguageInstitute.acadCareer,
          acadPlan: englishLanguageInstitute.acadPlan,
          bundleEligible: 'N',
          choiceNumber: '0'
        };
      }
    } else if (eliOption === EEliOption.ATTEND_SLC) {
      // fail save for the case that the user saves ELI option 2 twice. we don't want to remove their previous choice based on the same ELI option.
      // They can do this in the program choice page
      // TODO: This won't be necessary if we simply don't call the English Proficiency post API if the ELI option stays the same and the user tries to do a second save
      if (
        !programChoice.choices[0] ||
        (programChoice.choices[0] &&
          programChoice.choices[0].program !== senecaLanguageCentre.programCode)
      ) {
        programChoice.choices[0] = {
          startDate: null,
          campus: campusNameConverter(senecaLanguageCentre.campus),
          program: senecaLanguageCentre.programCode,
          description: senecaLanguageCentre.programDesc,
          acadCareer: senecaLanguageCentre.acadCareer,
          acadPlan: senecaLanguageCentre.acadPlan,
          bundleEligible: 'N',
          choiceNumber: '0'
        };
      }
    } 
    else if(eliOption === EEliOption.ATTEND_DPP){
      // fail save for the case that the user saves ELI option 2 twice. we don't want to remove their previous choice based on the same ELI option.
      // They can do this in the program choice page
      // TODO: This won't be necessary if we simply don't call the English Proficiency post API if the ELI option stays the same and the user tries to do a second save
      if (
        !programChoice.choices[0] ||
        (programChoice.choices[0] &&
          programChoice.choices[0].program !== degreePrepProgram.programCode)
      ) {
        programChoice.choices[0] = {
        startDate: null,
        campus: campusNameConverter(degreePrepProgram.campus),
        program: degreePrepProgram.programCode,
        description: degreePrepProgram.programDesc,
        acadCareer: degreePrepProgram.acadCareer,
        acadPlan: degreePrepProgram.acadPlan,
        bundleEligible: 'N',
        choiceNumber: '0'
        };
      }
    } else {
      if (
        programChoice.choices[0] &&
        (programChoice.choices[0].program === englishLanguageInstitute.programCode ||
          programChoice.choices[0].program === senecaLanguageCentre.programCode ||
          programChoice.choices[0].program === degreePrepProgram.programCode)
      ) {
        programChoice.choices = [];
      }
    }
    return programChoice;
  };

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }
}
