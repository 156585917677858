import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IInfo, IInfoRelease } from 'shared/models';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InfoReleaseService {
  constructor(private http: HttpClient) {}

  loadInfoRelease = (): Observable<IInfoRelease> => {
    return this.http.get(
      `${environment.apiUrl}/getCallReleaseInfo`
    ) as Observable<IInfoRelease>;
  };

  submitInfoRelease = (data: IInfo): Observable<any> => {
    return this.http.post(
      `${environment.apiUrl}/addCallReleaseInfo`,
      data
    ) as Observable<any>;
  };
}
