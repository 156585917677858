import { RouterModule, Routes } from '@angular/router';
import { CompleteComponent } from 'app/complete/complete.component';
import { PageNotFoundComponent } from 'app/page-not-found/page-not-found.component';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth/auth.guard';
import { routeList } from 'shared/models';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    component: AppComponent
  },
  {
    path: `${routeList.agent.path}`,
    loadChildren: './agent/agent.module#AgentModule'
  },
  {
    path: `${routeList.application.path}`,
    loadChildren: './application/application.module#ApplicationModule'
  },
  {
    path: `${routeList.complete}`,
    component: CompleteComponent
  },
  // TODO: enable again after fixing the routing
  { path: '**', component: PageNotFoundComponent }
];
@NgModule({
  imports: [
    RouterModule.forRoot(
      routes
      // { enableTracing: true } // <-- debugging purposes only
    )
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule {}
