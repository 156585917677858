import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AcademicInfoService = /** @class */ (function () {
    function AcademicInfoService(http) {
        var _this = this;
        this.http = http;
        this.getAcademicInfo = function () {
            return _this.http.get(environment.apiUrl + "/getAcademicInformation");
        };
        this.postAcademicInfo = function (academicInfo) {
            return _this.http.post(environment.apiUrl + "/AddAcademicInformation", academicInfo);
        };
    }
    AcademicInfoService.prototype.checkIfApplicantProgramConfig = function (appId) {
        return this.http.get(environment.apiUrl + "/checkIfApplicantProgramConfig/?appId=" + appId);
    };
    ;
    AcademicInfoService.prototype.checkIfAESubmitted = function (appId) {
        return this.http.get(environment.apiUrl + "/checkIfAESubmitted/?appId=" + appId);
    };
    AcademicInfoService.prototype.setAutoEval = function (appId) {
        return this.http.post(environment.apiUrl + "/setAutoEval", { "Application_Id": appId });
    };
    ;
    AcademicInfoService.prototype.getGuid = function (appId) {
        return this.http.get(environment.apiUrl + "/getGuid?appId=" + appId);
    };
    AcademicInfoService.ngInjectableDef = i0.defineInjectable({ factory: function AcademicInfoService_Factory() { return new AcademicInfoService(i0.inject(i1.HttpClient)); }, token: AcademicInfoService, providedIn: "root" });
    return AcademicInfoService;
}());
export { AcademicInfoService };
