import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { LanguageListService } from 'shared/services/api/language-list.service';
import {
  ELanguageListActions,
  LoadLanguageListFail,
  LoadLanguageListSuccess
} from 'app/store/actions';
import { Observable, of as observableOf, of, Subject } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';
import { AppLoadError } from 'shared/models';
import { languageListSelectors } from '../selectors';
import { LanguageListStates } from '../states';

@Injectable()
export class LanguageListEffects implements OnDestroy {
  constructor(
    private actions$: Actions,
    private service: LanguageListService,
    private stored: Store<LanguageListStates>
  ) {}

  destroyed$ = new Subject();

  @Effect()
  getLanguageList$: Observable<Action> = this.actions$.pipe(
    ofType(ELanguageListActions.LOAD_LANGUAGE_LIST),
    withLatestFrom(this.stored.select(languageListSelectors.selectAll)),
    switchMap(([action, stored]) =>
      stored.length !== 0
        ? of(new LoadLanguageListSuccess(stored))
        : this.service.getLanguageList().pipe(
            map(LanguageList => {
              return new LoadLanguageListSuccess(LanguageList);
            }),
            catchError(err => {
              const error = err instanceof HttpErrorResponse ? err.error : err;
              const displayError = error
                ? new AppLoadError(error.id, error.message)
                : new AppLoadError();
              return observableOf(new LoadLanguageListFail(displayError));
            })
          )
    ),
    takeUntil(this.destroyed$)
  );

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }
}
