import { ApplicationRef, DoBootstrap } from '@angular/core';
import { environment } from 'environments/environment';
import { AppComponent } from './app.component';
import { MsalComponent } from './msal/msal.component';
import { getMsalConfigs } from 'app/utils';
var ɵ0 = getMsalConfigs, ɵ1 = environment.googleTagManagerId;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    AppModule.prototype.ngDoBootstrap = function (ref) {
        if (window !== window.parent && !window.opener) {
            ref.bootstrap(MsalComponent);
        }
        else {
            //this.router.resetConfig(RouterModule);
            ref.bootstrap(AppComponent);
        }
    };
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
