import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { IDialogError } from 'shared/models';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogError
  ) {}

  header: string;
  actionName: string;

  ngOnInit() {
    if (this.data) {
      this.header = this.data.header
        ? this.data.header
        : 'Oops, something went wrong..';
      this.actionName = this.data.actionName ? this.data.actionName : 'Logout';
    }
  }

  onActionClick = () => {
    if (this.data.action) {
      this.data.action();
    }
    this.dialogRef.close();
  };
}
