import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { IApplicantInfo } from 'shared/models';

@Injectable({
  providedIn: 'root'
})
export class ApplicantInfoService {
  constructor(private http: HttpClient) { }

  // function 'get's the Applicant Info including appId and LastStep completed from the API
  getApplicantInfo = (appId: string): Observable<IApplicantInfo> => {
    if (appId) {
      return this.http.post(
        `${environment.apiUrl}/getApplicationInfoAgent`,
        appId !== '00000000000' ? { appId: appId } : { appId: null }
      ) as Observable<IApplicantInfo>;
    } else {
      return this.http.get(
        `${environment.apiUrl}/getApplicationInfo`
      ) as Observable<IApplicantInfo>;
    }
  };

  checkIfApplicantProgramConfig  (appId: string) {
    if (appId) {
      return this.http.get(
        `${environment.apiUrl}/checkIfApplicantProgramConfig/?appId=${appId}`
      ) ;
    }
  }

  checkIfAESubmitted (appId: string) {
    if (appId) {
      return this.http.get(
        `${environment.apiUrl}/checkIfAESubmitted/?appId=${appId}`
      ) ;
    }
  }

  setAutoEval(appId:string) {
    if (appId) {
      return this.http.post(
        `${environment.apiUrl}/setAutoEval`, {"Application_Id": appId}
      ) ;
    }
  }

  getGuid(appId:string) {
    if (appId) {
      return this.http.get(
        `${environment.apiUrl}/getGuid?appId=${appId}`
      ) ;
    }
  }
}
