import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { IProgramListItem, IProgramListItemState } from 'shared/models';

export interface IProgramListContainerStates
  extends EntityState<IProgramListItemState> {
  selectedId: string;
}

export const programListContainerAdapter = createEntityAdapter<
  IProgramListItemState
>({
  selectId: (programItem: IProgramListItem) => programItem.id
});

export const programListContainerInitialState: IProgramListContainerStates = programListContainerAdapter.getInitialState(
  {
    selectedId: null,
    ids: ['0', '1', '2', '3', '4', '5'],
    entities: [
      {
        id: '0',
        list: [],
        loading: true,
        error: null,
        month: null,
        visa: null,
        year: null
      },
      {
        id: '1',
        list: [],
        loading: true,
        error: null,
        month: null,
        visa: null,
        year: null
      },
      {
        id: '2',
        list: [],
        loading: true,
        error: null,
        month: null,
        visa: null,
        year: null
      },
      {
        id: '3',
        list: [],
        loading: true,
        error: null,
        month: null,
        visa: null,
        year: null
      },
      {
        id: '4',
        list: [],
        loading: true,
        error: null,
        month: null,
        visa: null,
        year: null
      },
      {
        id: '5',
        list: [],
        loading: true,
        error: null,
        month: null,
        visa: null,
        year: null
      }
    ]
  }
);
