import { Action } from '@ngrx/store';
import { IAppError, IProgramListItem } from 'shared/models';
import { IAgentApplication } from 'shared/models/agentApplication.models';

export enum EAgentApplicationActions {
  LOAD_AGENT_APPLICATIONS = '[AgentApplications] LOAD',
  LOAD_AGENT_APPLICATIONS_SUCCESS = '[AgentApplications] LOAD SUCCESS',
  LOAD_AGENT_APPLICATIONS_FAIL = '[AgentApplications] LOAD FAIL'
}

export class LoadAgentApplications implements Action {
  public readonly type = EAgentApplicationActions.LOAD_AGENT_APPLICATIONS;

  constructor() {}
}

export class LoadAgentApplicationsSuccess implements Action {
  public readonly type =
    EAgentApplicationActions.LOAD_AGENT_APPLICATIONS_SUCCESS;

  constructor(public payload: IAgentApplication[]) {}
}

export class LoadAgentApplicationsFail implements Action {
  public readonly type = EAgentApplicationActions.LOAD_AGENT_APPLICATIONS_FAIL;

  constructor(public payload: IAppError) {}
}

export type AgentApplicationActions =
  | LoadAgentApplications
  | LoadAgentApplicationsFail
  | LoadAgentApplicationsSuccess;
