import { ActionReducer } from '@ngrx/store';
import {
  EUploadActions,
  ResetUnUploadedForm,
  SetUnUploadedForm
} from 'app/store/actions';
import { AppState } from 'app/store/reducers';
import {
  removeUnUnloadedDocumentsFromStorage,
  setUnUploadedDocumentsToStorage
} from 'app/utils';

export function uploadDocumentTracker(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return function(
    state: AppState,
    action: SetUnUploadedForm | ResetUnUploadedForm
  ) {
    if (action.type === EUploadActions.SET_UNUPLOADED_FORM) {
      setUnUploadedDocumentsToStorage([
        ...state.upload.unUploadedForms,
        action.payload
      ]);
    } else if (action.type === EUploadActions.RESET_UNUPLOADED_FORM) {
      removeUnUnloadedDocumentsFromStorage();
    }
    return reducer(state, action);
  };
}
