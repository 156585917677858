import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ICountry } from 'shared/models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CountryListService {
  constructor(private http: HttpClient) {}

  // function 'get's a list of International Countries from the API
  getCountryList = (): Observable<ICountry[]> => {
    return this.http
      .get(`${environment.apiUrl}/getProvincesStates`)
      // Getting the array of countries from the CountriesList Container
      .pipe(map(response => response['countriesList'])) as Observable<
      ICountry[]
    >;
  };
}
