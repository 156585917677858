import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { IEnglishProficiencyAPI } from 'shared/models';
@Injectable({
  providedIn: 'root'
})
export class EnglishProficiencyService {
  constructor(private http: HttpClient) {}

  getEnglishProficiency = (): Observable<IEnglishProficiencyAPI> => {
    return this.http.get(
      `${environment.apiUrl}/getEnglishProficiency`
    ) as Observable<IEnglishProficiencyAPI>;
  };

  postEnglishProficiency = (
    englishProficiency: IEnglishProficiencyAPI
  ): Observable<IEnglishProficiencyAPI> => {
    return this.http.post(
      `${environment.apiUrl}/AddEnglishProficiency`,
      englishProficiency
    ) as Observable<IEnglishProficiencyAPI>;
  };
}
