import { Action } from '@ngrx/store';
import { IAgentVerificationResult, IAppError, IUser } from 'shared/models';

export enum EAuthActions {
  ADD_USER = '[Auth] ADD',
  ADD_USER_SUCCESS = '[Auth] ADD SUCCESS',
  ADD_USER_FAIL = '[Auth] ADD FAIL',
  VERIFY_AGENT = '[AUTH] VERIFY AGENT',
  VERIFY_AGENT_SUCCESS = '[AUTH] VERIFY AGENT SUCCESS',
  VERIFY_AGENT_FAIL = '[AUTH] VERIFY AGENT FAIL'
}

export class AddNewUser implements Action {
  public readonly type = EAuthActions.ADD_USER;

  constructor(public payload: string = null) {}
}

export class AddNewUserSuccess implements Action {
  public readonly type = EAuthActions.ADD_USER_SUCCESS;

  constructor(public payload: IUser) {}
}

export class AddNewUserFail implements Action {
  public readonly type = EAuthActions.ADD_USER_FAIL;

  constructor(public payload: IAppError) {}
}

export class VerifyAgent implements Action {
  public readonly type = EAuthActions.VERIFY_AGENT;

  constructor() {}
}

export class VerifyAgentSuccess implements Action {
  public readonly type = EAuthActions.VERIFY_AGENT_SUCCESS;

  constructor(public payload: IAgentVerificationResult) {}
}

export class VerifyAgentFail implements Action {
  public readonly type = EAuthActions.VERIFY_AGENT_FAIL;

  constructor(public payload: IAppError) {}
}

export type AuthActions =
  | AddNewUser
  | AddNewUserSuccess
  | AddNewUserFail
  | VerifyAgent
  | VerifyAgentSuccess
  | VerifyAgentFail;
