import {
  applicantInfoAdapter,
  applicantInfoInitialState,
  ApplicantInfoStates
} from '../states/applicantInfo.states';

import { ApplicantInfoActionsUnion, EApplicantInfoActions } from '../actions';

export function applicantInfoReducers(
  state: ApplicantInfoStates = applicantInfoInitialState,
  action: ApplicantInfoActionsUnion
): ApplicantInfoStates {
  switch (action.type) {
    case EApplicantInfoActions.LOAD_APPLICANT_INFO: {
      return {
        ...state,
        loading: true,
        error: null,
        selectedId: null
      };
    }

    case EApplicantInfoActions.LOAD_APPLICANT_INFO_SUCCESS: {
      return applicantInfoAdapter.upsertOne(action.payload, {
        ...state,
        loading: false,
        error: false,
        selectedId: action.payload.appId
          ? action.payload.appId
          : 'newApplication'
      });
    }

    case EApplicantInfoActions.LOAD_APPLICANT_INFO_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        selectedId: null
      };
    }

    case EApplicantInfoActions.RESET_APPLICANT_INFO:
      return applicantInfoAdapter.removeAll(state);

    default: {
      return state;
    }
  }
}
