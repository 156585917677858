<div fxLayout="row" fxLayoutAlign="center center" fxFlexFill>
  <mat-card
    fxFlex="1 1 85%"
    fxFlex.lt-md="95%"
    fxLayoutAlign="center"
    class="seneca-card"
  >
    <div class="seneca-content-padding">
      <div fxLayout="row wrap" fxLayoutAlign="center start" fxFlexFill>
        <div
          class="header-container"
          fxLayout="column"
          fxLayoutAlign="start center"
          fxFlex="70%"
        >
          <h2 class="center-align">
            <!-- TODO: Add it after logic implementation -->
            <!-- *ngIf="userType === userTypeEnum.Applicant; else showAgent" -->
            <span>
              Your Application has been submitted. Thank you for applying to
              Seneca Polytechnic.</span
            >
            <!-- <ng-template #showAgent>
              <span>
                The application for 'Firstname' 'Lastname' has been submitted.
                Thank you for the application to Seneca College.</span
              >
            </ng-template> -->
          </h2>
        </div>

        
        <div fxLayout="row" fxLayoutAlign="center" fxFlex="1 1 100%">
          <mat-card-content
            class="seneca-card-content"
            fxLayout="column"
            fxLayoutAlign="start center"
            fxFlexFill
            fxLayoutGap="2em"
          >
            <div
              fxLayout="column"
              fxLayoutAlign="center default"
              fxLayoutGap="2em"
              fxFlexFill
            >
            <div *ngIf="showAutoEvalButton">
              <b>Expedited Processing</b>
              <br/>Your application may be eligible for a faster processing by providing more information.<br/>
              <div >
                </div><input type="checkbox" [disabled]="isDisabled" (change)="userWantsAE()" />
                <label for="checkbox">Yes, I would like provide more information</label>
                <div *ngIf="userPressedAutoEval">
                  Within 24 hours after the payment, you can enter more information to help us better understand your background here: <a [href]="autoEvalUrl+'/'+guid" target="_blank">{{autoEvalUrl}}</a> 
                </div>
                <br/>
                Otherwise, you may leave this screen after reviewing the following notes.
            </div>
              <ul
                fxLayout="column"
                fxLayoutAlign="start center"
                fxLayoutGap="1em"
              >
                <li>
                  We prioritize review of applications based on several
                  criteria. These include completeness of the application as
                  well as availability of seats in the desired programs. If
                  there are more qualified applicants than seats, applications
                  are ranked based on the quality of verified grades and
                  language scores. Applications are not reviewed on a first
                  come, first served basis, which results in varied and
                  undefined processing times.
                </li>
                <li *ngIf="userType === userTypeEnum.Applicant">
                  You will receive your Seneca Student ID and information about
                  how to set up your Seneca online services about 24 hours after
                  you apply. It is important that you set up your account as
                  soon as possible, as critical information regarding the status
                  of your application will be communicated through your Seneca
                  online
                  <a href="https://senecapolytechnic.ca/student-centre">
                    Student Home
                  </a>
                </li>
                <li *ngIf="userType === userTypeEnum.Applicant; else showAgent">
                  Important communications will be sent to you by email to the
                  address provided in your application.
                </li>
                <ng-template #showAgent>
                  <li>
                    Important communications will be sent to the email address
                    that was provided in the application, and you will be
                    granted access to view the application updates through your
                    Seneca online Agent Portal.
                  </li>
                </ng-template>
              </ul>            
              <ul
                class="disable-list-styling"
                fxLayout="column"
                fxLayoutAlign="start start"
              >
                <li><b>International Admissions Office</b></li>
                <li>
                  <b>Phone: +1 (416) 764-9900</b>
                </li>
                <li><b>Email: theservicehub@senecapolytechnic.ca</b></li>
                <li>
                  <i
                    >Please provide application confirmation number when
                    contacting us.</i
                  >
                </li>
              </ul>
            </div>

            <div fxLayout="row" fxLayoutAlign="center center" fxFlexFill>
              <mat-card-actions
                fxLayout="row"
                fxLayoutAlign="space-around center"
                fxFlex="70%"
              >
                <button
                  (click)="onClose()"
                  mat-raised-button
                  color="secondary"
                  fxFlex="1 1 25%"
                >
                  Close
                </button>
                <button
                  (click)="onBack()"
                  mat-raised-button
                  color="primary"
                  fxFlex="1 1 25%"
                >
                  {{ isApplicant() ? 'My Application' : 'Return to Dashboard' }}
                </button>
              </mat-card-actions>
            </div>
          </mat-card-content>
        </div>
      </div>
    </div>
  </mat-card>
</div>
