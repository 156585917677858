import { Action } from '@ngrx/store';
import { IAppError, ILanguage } from 'app/shared/models';

//  Enumeration defining the different actions
export enum ELanguageListActions {
  LOAD_LANGUAGE_LIST = '[LanguageList] Load Language List',
  LOAD_LANGUAGE_LIST_SUCCESS = '[LanguageList] Load Language List Success',
  LOAD_LANGUAGE_LIST_FAIL = '[LanguageList] Load Language List Fail'
}

export class LoadLanguageList implements Action {
  public readonly type = ELanguageListActions.LOAD_LANGUAGE_LIST;
  constructor() {}
}

export class LoadLanguageListSuccess implements Action {
  public readonly type = ELanguageListActions.LOAD_LANGUAGE_LIST_SUCCESS;
  // Passing a payload of type ILanguageList which holds the Language information
  constructor(public payload: ILanguage[]) {}
}

export class LoadLanguageListFail implements Action {
  public readonly type = ELanguageListActions.LOAD_LANGUAGE_LIST_FAIL;
  // Passing a payload of type IAppError
  constructor(public payload: IAppError) {}
}

//  Defining a union for all the actions in this component
export type LanguageListActionsUnion =
  | LoadLanguageList
  | LoadLanguageListSuccess
  | LoadLanguageListFail;
