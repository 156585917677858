import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAppError, IInfoRelease } from 'app/shared/models';
import { infoReleaseAdapter, InfoReleaseStates } from '../states';
import { Dictionary } from '@ngrx/entity';

const getError = (state: InfoReleaseStates): IAppError => state.error;

const getIsLoading = (state: InfoReleaseStates): boolean => state.loading;

const getSelectedId = (state: InfoReleaseStates): string => state.selectedId;

const selectInfoRelease = createFeatureSelector<InfoReleaseStates>(
  'infoRelease'
);

const { selectEntities, selectAll } = infoReleaseAdapter.getSelectors(
  selectInfoRelease
);

export const selectInfoReleaseData = createSelector(
  selectEntities,
  selectInfoRelease,
  (entities: Dictionary<IInfoRelease>, state: InfoReleaseStates) => {
    const selectedId = getSelectedId(state);
    return selectedId ? entities[selectedId] : null;
  }
);

export const selectError = createSelector(
  selectInfoRelease,
  getError
);

export const selectLoading = createSelector(
  selectInfoRelease,
  getIsLoading
);
