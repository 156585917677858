import { EPaymentActions, PaymentActions } from '../actions';
import { paymentAdapter, paymentInitialState, PaymentStates } from '../states';

export function paymentReducers(
  state: PaymentStates = paymentInitialState,
  action: PaymentActions
): PaymentStates {
  switch (action.type) {
    case EPaymentActions.GET_APPLICATION_FEE: {
      return {
        ...state,
        loading: true,
        error: null,
        promoError: null,
        promoFormError: null
      };
    }
    case EPaymentActions.GET_APPLICATION_FEE_SUCCESS: {
      return paymentAdapter.addOne(action.payload, {
        ...state,
        loading: false,
        error: null,
        promoError: null,
        promoFormError: null
      });
    }
    case EPaymentActions.GET_APPLICATION_FEE_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        promoError: null,
        promoFormError: null
      };
    }
    case EPaymentActions.UPDATE_PAYMENT_RESPONSE: {
      return {
        ...state,
        paymentResponse: action.payload,
        error: null,
        promoError: null,
        promoFormError: null
      };
    }

    case EPaymentActions.CHECK_PROMO_CODE: {
      return {
        ...state,
        checkingPromoCode: true,
        error: null,
        promoError: null,
        promoFormError: null
      };
    }
    case EPaymentActions.CHECK_PROMO_CODE_SUCCESS: {
      return {
        ...state,
        checkingPromoCode: false,
        promoValid: true,
        promoFormError: null,
        error: null,
        promoError: null
      };
    }
    case EPaymentActions.CHECK_PROMO_CODE_FAIL: {
      return {
        ...state,
        checkingPromoCode: false,
        promoValid: false,
        promoError: action.payload,
        error: null,
        promoFormError: null
      };
    }
    case EPaymentActions.INCORRECT_PROMO_CODE: {
      return {
        ...state,
        checkingPromoCode: false,
        promoValid: false,
        promoError: null,
        error: null,
        promoFormError: action.payload
      };
    }
    case EPaymentActions.INITIALIZE_PAYMENT: {
      return {
        ...state,
        initializingPayment: true,
        transactionTicket: null,        
        loading: true,
        error: null
      }
    }
    case EPaymentActions.INITIALIZE_PAYMENT_SUCCESS: {
      return {
        ...state,
        initializingPayment: true,
        transactionTicket: action.payload.response.ticket,
        loading: false,
        error: null
      }
    }
    case EPaymentActions.PAYMENT_SUCCESS: {
      return {
        ...state,
        paid: true,
        checkingPromoCode: false,
        promoValid: false,
        promoError: null,
        error: null,
        promoFormError: null
      };
    }

    default:
      return state;
  }
}
