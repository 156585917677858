import { commonEnv } from './environment.common';

const clientId = 'c83d2b16-0a42-40ce-bdaf-41bb8e6aba02';
const tenant = 'iaptstb2c.onmicrosoft.com';
const scopes = ['https://iaptstb2c.onmicrosoft.com/api/read'];
const appUrl = 'https://iap-tst.senecapolytechnic.ca';
const azureInstance = `https://iaptstb2c.b2clogin.com/tfp`;

export const environment = {
  ssoEnabled: commonEnv.ssoEnabled ? commonEnv.ssoEnabled : false,
  production: true,
  appUrl: appUrl,
  scopes: scopes,
  clientId: clientId,
  autoEvalUrl: 'https://autoeval-appsvc-web01tst.azurewebsites.net/student-form',
  apiUrl: 'https://seneapi-apims-tst.azure-api.net/iwa',
  // TODO: remove in prod
  // apiUrl: 'http://localhost:3000/iwa-api',
  apiSubscriptionKey: '1939355f5c8244deafe449feadec925d',
  apiSubscriptionEnabled: true,
  monerisServiceUrl: 'https://seneapi-apims-dv.azure-api.net/MonerisCheckoutServices/public/controllers',
  monerisAppName: 'IWAFEES',
  agentSignInPolicy: commonEnv.agentSignInPolicy,
  applicantSignInPolicy: commonEnv.applicantSignInPolicy,
  azureInstance: azureInstance,
  googleTagManagerId: commonEnv.googleTagManagerId,
  roles_case_1: commonEnv.ssoEnabled ? 'IWA_Admin' : '',
  roles_case_2: commonEnv.ssoEnabled ? 'IWA_Admin_User' : '',
  /**
  *  Moneris payment config
  */
   monerisCheckoutUrl: 'https://gatewayt.moneris.com/chkt/js/chkt_v1.00.js',
   monerisCheckoutMode: 'qa',
  tenant: tenant,
  msalApplicantConfigs: {
    clientID: clientId,
    authority: `${azureInstance}/${tenant}/${commonEnv.applicantSignInPolicy}`,
    validateAuthority: false,
    consentScopes: scopes,
    redirectUri: `${appUrl}/${commonEnv.azureApplicantCallbackUrl}`,
    cacheLocation: 'localStorage',
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: `${appUrl}/${commonEnv.azureApplicantCallbackUrl}`,
    storeAuthStateInCookie: true,
    loadFrameTimeout: 30000
  },
  msalAgentConfigs: {
    clientID: clientId,
    authority: `${azureInstance}/${tenant}/${commonEnv.agentSignInPolicy}`,
    validateAuthority: false,
    consentScopes: scopes,
    redirectUri: `${appUrl}/${commonEnv.azureAgentCallbackUrl}`,
    cacheLocation: 'localStorage',
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: `https://IAPTSTB2C.b2clogin.com/IAPTSTB2C.onmicrosoft.com/${commonEnv.agentSignInPolicy}/oauth2/v2.0/logout?post_logout_redirect_uri=${appUrl}/${commonEnv.azureAgentCallbackUrl}`,
    storeAuthStateInCookie: true,
    loadFrameTimeout: 30000
  }
};
