import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var InfoReleaseService = /** @class */ (function () {
    function InfoReleaseService(http) {
        var _this = this;
        this.http = http;
        this.loadInfoRelease = function () {
            return _this.http.get(environment.apiUrl + "/getCallReleaseInfo");
        };
        this.submitInfoRelease = function (data) {
            return _this.http.post(environment.apiUrl + "/addCallReleaseInfo", data);
        };
    }
    InfoReleaseService.ngInjectableDef = i0.defineInjectable({ factory: function InfoReleaseService_Factory() { return new InfoReleaseService(i0.inject(i1.HttpClient)); }, token: InfoReleaseService, providedIn: "root" });
    return InfoReleaseService;
}());
export { InfoReleaseService };
