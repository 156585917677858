import { Action } from '@ngrx/store';
import { IAppError, IPersonalInfo } from 'app/shared/models';

//  Enumeration defining the different actions
export enum EPersonalInfoActions {
  LOAD_PERSONAL_INFO = '[PersonalInfo] Load Personal Info',
  LOAD_PERSONAL_INFO_SUCCESS = '[PersonalInfo] Load Personal Info Success',
  LOAD_PERSONAL_INFO_FAIL = '[PersonalInfo] Load Personal Info Fail',
  SUBMIT_PERSONAL_INFO = '[PersonalInfo] Submit Personal Info',
  SUBMIT_PERSONAL_INFO_SUCCESS = '[PersonalInfo] Submit PersonalInfo Success',
  SUBMIT_PERSONAL_INFO_FAIL = '[PersonalInfo] Submit Personal Info Fail'
}

export class LoadPersonalInfo implements Action {
  public readonly type = EPersonalInfoActions.LOAD_PERSONAL_INFO;
  constructor() {}
}

export class LoadPersonalInfoSuccess implements Action {
  public readonly type = EPersonalInfoActions.LOAD_PERSONAL_INFO_SUCCESS;
  // Passing a payload of type IPersonalInfo which holds the personal info information
  constructor(public payload: IPersonalInfo) {}
}

export class LoadPersonalInfoFail implements Action {
  public readonly type = EPersonalInfoActions.LOAD_PERSONAL_INFO_FAIL;
  // Passing a payload of type IAppError
  constructor(public payload: IAppError) {}
}

export class SubmitPersonalInfo implements Action {
  public readonly type = EPersonalInfoActions.SUBMIT_PERSONAL_INFO;

  constructor(public payload: IPersonalInfo) {}
}
export class SubmitPersonalInfoSuccess implements Action {
  public readonly type = EPersonalInfoActions.SUBMIT_PERSONAL_INFO_SUCCESS;

  constructor(public payload: IPersonalInfo) {}
}
export class SubmitPersonalInfoFail implements Action {
  public readonly type = EPersonalInfoActions.SUBMIT_PERSONAL_INFO_FAIL;

  constructor(public payload: IAppError) {}
}

//  Defining union for all the actions in this component
export type PersonalInfoActionsUnion =
  | LoadPersonalInfo
  | LoadPersonalInfoSuccess
  | LoadPersonalInfoFail
  | SubmitPersonalInfo
  | SubmitPersonalInfoSuccess
  | SubmitPersonalInfoFail;
